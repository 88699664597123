import * as React from "react";
import { RouteComponentProps, Link, navigate } from "@reach/router";
import { SmallCardFrame } from "../../components/layout/SmallCardFrame";
import { Routes } from "../../config/Routes";
import { TextField } from "../../components/commons/TextField";
import { usePatientSignUpAPI, IAuthInput } from "../../core/service/services";
import { Form, CardBody, CardTitle, FormGroup, Label, Input, FormFeedback, Col, Card } from "reactstrap";
import { toastError, navItem } from "../../App";
import { LoadingButton } from "../../components/commons/LoadingButton";
import { UserConfig } from "../../config/userConfig";
import { Helmet } from "react-helmet";
import 'react-datepicker/dist/react-datepicker.css';
import { isPhoneNumberValid } from "../../core/service/Validators";
import { ContactErrorMessages } from "../../core/service/ErrorMessages";


export interface IProps extends RouteComponentProps {
  email?: string;
}

const validateEmail = (email?: string| null) => {
  if (email == null || email.length  < 1){
    return false;
  }
  let re = new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')
  return re.test(email);
}

const validateFullName = (fullName?: string| null) => {
  if (fullName == null || fullName.length  < 1){
    return false;
  }
  var re = new RegExp("^[a-zA-Z '-\s]+$") ;
  return re.test(fullName);
}


export const PatientSignUp: React.FC<IProps> = (props: IProps) => {
  const [termAndConditions, setTermsAndConditions] = React.useState<boolean>(false);
  const [privacyPolicy, setPrivacyPolicy] = React.useState<boolean>(false);
  const signUpService = usePatientSignUpAPI();
  let email = (props.location?.state as any)?.email;
  const controls = {
    fullName: React.createRef<TextField>(),
    email: React.createRef<TextField>(),
    pass1: React.createRef<TextField>(),
    pass2: React.createRef<TextField>(),
    dob: React.createRef<TextField>(),
    phone: React.createRef<TextField>()
  };
  let data: IAuthInput = {
    fullname: "",
    email: "",
    password: "",
    repeatPassword: "",
    phone:""
  };

  React.useEffect(() => {
    if (signUpService.service.status == "validationError") {
      // alert(JSON.stringify(signUpService.service.items));
      toastError("Please use a valid email address and phone number");
    } else {
      if (signUpService.service.status == "loaded") {
        if(UserConfig.getUserType()==="agent"){
          UserConfig.setemailPatientRegisteredByAgent(controls.email.current?.getValue() || "");
          navigate(Routes.patientVerication);
        }
        else 
          navigate(Routes.successfulSignup);
      } else if (signUpService.service.status === "custormError") {
        if(signUpService.service.errorResponse.status === 409) {
          navigate(Routes.duplicateRegistryEmail, { state: { message: signUpService.service.errorResponse.message } })
        } else {
          toastError(signUpService.service.errorResponse.message)
        }
      }
    }
  });

  const callRegisterService = (e: React.FormEvent) => {
    // cleanup errors 
    e.preventDefault()

    controls.fullName.current?.setErrors([]);
    controls.email.current?.setErrors([]);
    controls.pass1.current?.setErrors([]);
    controls.pass2.current?.setErrors([]);
    controls.phone.current?.setErrors([]);
    let errors: string[] = []
    let pass1 = controls.pass1.current?.getValue();
    let pass2 = controls.pass2.current?.getValue();
    let phone = controls.phone.current?.getValue();
    let dob = controls.dob.current?.getValue()
    {
      data.fullname = controls.fullName.current?.getValue() || "";
      data.email = controls.email.current?.getValue() || "";
      data.password = pass1 || "";
      data.repeatPassword = pass2 || "";
      data.phone = phone || "";
      dob && (data.dob = dob)
    }
    //TODO: enhance error messages to use mpre than one error per field
    if (!validateFullName( data.fullname)) {
      let message = "A valid name is expected"
      errors.push(message);
      controls.fullName.current?.setErrors([message])
    }

    if (!validateEmail( data.email)) {
      let message = "A valid email is expected"
      errors.push(message);
      controls.email.current?.setErrors([message])
    }

    if (!isPhoneNumberValid(data.phone)) {
      let message = ContactErrorMessages.invalidPhoneNumber
      errors.push(message)
      controls.phone.current?.setErrors([message])
    }

    if ((pass1?.length || 0) < 8) {
      let message = "password must be more than 8 letters of length"
      errors.push(message);
      controls.pass1.current?.setErrors([message])
    }
    if (pass2 != pass1){
      let message = "password and confirmation must match"
      errors.push(message);
      controls.pass2.current?.setErrors([message])
    }
    if(!termAndConditions && !isAgent()){
      let message = 'You must read and agree on our Terms and Conditions';
      errors.push(message);
    }

    if(!privacyPolicy && !isAgent()){
      let message = 'You must read and agree on our Privacy Policy';
      errors.push(message);
    }

    if (errors.length > 0) {
      return;
    }

    if(UserConfig.getUserType() === "agent"){
      data={...data, agentAsPatient: true}
    }
    signUpService.callService(data).then((resData) => {
    }).catch((error) => {
      console.log("error", error)
    });
  };

  const isAgent=()=>{
    return UserConfig.getUserType() === "agent" ;
  }


  const renderAgentRegistrationCard=()=>{
    return(
      <Col lg="6" xl="6" className="mx-auto">
      <Card className="card-telecare my-5">
        <CardBody>
          <CardTitle className="mb-0">
            <h3>Agent</h3> 
            <p>Regstration for Patient...</p> 
          </CardTitle>
        </CardBody>
      </Card>
      </Col>
    )
  }


  return (
    <>
    <Helmet>
      <title>Patient Sign-up - TelMDCare</title>
      <meta name="description" content="Track your online doctor appointments and virtual doctor consultation records and prescriptions by signing up at TelMDCare."></meta>
    </Helmet>
    {UserConfig.getUserType() === "agent" && renderAgentRegistrationCard()}
      <SmallCardFrame bodyClassName="tc-bg" navItem={ isAgent() ? navItem : null}>
        <div className="card-img-left register d-none d-md-flex">
          {/* <!-- Background image for card set in CSS! --> */}
        </div>
        <CardBody>
          <CardTitle className="text-center">Patient Sign Up</CardTitle>
          <fieldset disabled={signUpService.service.status == "loading"}>
          <Form className="form-signin" onSubmit={(e) => callRegisterService(e)}>
            <TextField
              ref={controls.fullName}
              type="text"
              labelText="Full Name"
              placeholder="Full Name"
              required={true}
            />

            <TextField
              ref={controls.email}
              type="text"
              labelText="Email address"
              placeholder="Email address"
              required={true}
              defaultValue={email}
            />

            <TextField
              ref={controls.phone}
              type="phone"
              labelText="Phone Number"
              placeholder="Phone Number"
              required={true}
            />

            <TextField
              defaultValue="mm/dd/yyyy"
              ref={controls.dob}
              type="date"
              labelText="Date of Birth"
              required={false}
            />

            <hr />

            <TextField
              ref={controls.pass1}
              type="password"
              labelText="Password"
              placeholder="Password"
              required={true}
            />

            <TextField
              ref={controls.pass2}
              type="password"
              labelText="Confirm password"
              placeholder="Password"
              required={true}
            />
            {!isAgent() &&
              (<>
              <FormGroup check>
              <Label check>
                <Input type="checkbox" required onChange={(e)=>{setTermsAndConditions(e.target.checked)}}/>
                I agree to the {' '} <a href={Routes.termsOfUse}> Terms of Use</a>
              </Label>
            </FormGroup>
            <FormGroup check >
              <Label check>
                <Input type="checkbox" required onChange={(e)=>{setPrivacyPolicy(e.target.checked)}} />
                I agree to the{' '} 
                <a href={Routes.privacyPolicy}> Privacy Policy</a> , Informed Consent.
              </Label>
            </FormGroup>
            </>)}
            <LoadingButton isLoading={signUpService.service.status == "loading"} type="submit" size="lg" color="primary" 
              className="btn-block text-uppercase btn-login mt-4">Register</LoadingButton>

            {UserConfig.getUserType() !== "agent" &&<div className="form-text mt-2 text-center mb-2 mt-4">
              Already a member?{" "}
              <Link
                to={UserConfig.getUserType() !== "agent"? Routes.patientSignin: ""}
                className="btn btn-sm btn-block btn-outline-primary mt-2"
              >
                Sign In
              </Link>
            </div>}
          </Form>
          </fieldset>
        </CardBody>
      </SmallCardFrame>
    </>
  );
};
