import React, { useEffect } from "react";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import {
  listSelfReportedMedications,
  ISelfReportedItem,
  IFullSelfReportedItem,
} from "../../core/service/services";
import { SelfReportedMedicationsModal } from "./SelfReportedMedicationsModal";
import { ISelfReportedMedicationsAction } from "./MedicalHistory";
import { IDoseSpotPrescription } from "../../redux/types/interfaces/medicalProfileInterfaces";

export interface ISelfReportedMedication {
  name: string;
  strength: string[]
}

export interface IProps {
  setSelfReportedMedicationsActions: (items: ISelfReportedMedicationsAction[]) => void
  patientId?: number
  isLoading?:boolean
}

export const SelfReportedMedications: React.FC<IProps> = (props) => {
  const [selfReportedMedicationsModal, setSelfReportedMedicationsModal] =
    React.useState<boolean>(false);
  const [selfReportedMedications, setSelfReportedMedications] = React.useState<
  IFullSelfReportedItem[]
  >([]);
  const [selfReportedMedicationsActions, setSelfReportedMedicationsActions] =
    React.useState<ISelfReportedMedicationsAction[]>([]);

  useEffect(() => {
    listSelfReportedMedications(props.patientId).then((res) => {
      const arr: IFullSelfReportedItem[] = [];
      res.forEach((element) => {
        element.id = element.id;
        element.name = element.name;
        element.strength = element.strength;
        arr.push(element);
      });
      setSelfReportedMedications(arr)
    })
  }, [])

  const getMedItemFromPres = (
    item: IDoseSpotPrescription
  ): IFullSelfReportedItem => {
    return {
      selfReportedMedicationItem:{
        Quantity: 1,
        Status: 1,
        DispensableDrugId:item.DispensableDrugId!,
        SelfReportedMedicationId:0
      },
      name: item.DisplayName,
      strength: item.Strength
    }
  }

  const addSelfReportedMedication = (medication: IDoseSpotPrescription) => {
    const item: IFullSelfReportedItem = getMedItemFromPres(medication);
    console.log('item is ',item)
    const newArr = selfReportedMedications.concat(item);
    setSelfReportedMedications(newArr);
    const newActionArr = selfReportedMedicationsActions.concat({
      medication: item,
      action: "add",
    });
    setSelfReportedMedicationsActions(newActionArr);
    props.setSelfReportedMedicationsActions(newActionArr);
    toggleSelfReportedMedicationsModal();
  };

  const removeSelfReportedMedication = (medication: IFullSelfReportedItem) => {
    const newArr = [...selfReportedMedications];
    const idx = newArr.indexOf(medication);
    if (idx > -1) {
      newArr.splice(idx, 1)
      setSelfReportedMedications(newArr)
      let index = -1
      for (let i = 0; i < selfReportedMedicationsActions.length; i++) {
        const element = selfReportedMedicationsActions[i];
        if (element.action === "add" && element.medication.name === medication.name) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        const newActionArr = [...selfReportedMedicationsActions]
        newActionArr.splice(index, 1)
        setSelfReportedMedicationsActions(newActionArr)
        props.setSelfReportedMedicationsActions(newActionArr)
      } else {
        const newActionArr = selfReportedMedicationsActions.concat({medication: medication, action: "remove"})
        setSelfReportedMedicationsActions(newActionArr)
        props.setSelfReportedMedicationsActions(newActionArr)
      }
    }
  }

  const toggleSelfReportedMedicationsModal = () => {
    setSelfReportedMedicationsModal(!selfReportedMedicationsModal)
  }

  const renderSelfReportedMedications = () => {
    return (
      selfReportedMedications.length > 0 ?
      <ListGroup style={{ maxHeight: 300, overflow: "auto" }}>
      {selfReportedMedications.map((medication) => {
        return (
          <>
          <ListGroupItem>
            <span> {medication.name}</span>
            <Button color="link" className="text-muted ml-auto" onClick={() => removeSelfReportedMedication(medication)} style={{float: "right"}}>
              <span className="sr-only">Remove</span>{" "}
              <i className="fa fa-trash"></i>
            </Button>
          </ListGroupItem>
          </>
        );
      })}
    </ListGroup>
    :
    <span>None</span>
    )
  }

  return (
    <>
    <h6 className="mb-3">
      Self Reported Medications
      <Button disabled={props.isLoading === true} onClick={toggleSelfReportedMedicationsModal} color="link" size="sm">
        Add
      </Button>
    </h6>
    <div>
      <ul className="list-group list-group-flush">
        {renderSelfReportedMedications()}
      </ul>
    </div>
    <SelfReportedMedicationsModal modalIsOpen={selfReportedMedicationsModal} toggleModal={toggleSelfReportedMedicationsModal}
        addSelfReportedMedication={addSelfReportedMedication} />
    </>
  );
};
