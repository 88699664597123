import * as React from "react";
import { LandingPageHeader } from "../page/LandingPageHeader";
import { SmallCardFrame } from "../components/layout/SmallCardFrame";
import { RouteComponentProps } from "@reach/router";
import { unauthenticatedEmailUnSubscribe } from "../core/service/services";
interface IProps extends RouteComponentProps { }

export const Unsubscribe = (props: IProps) => {
    const [patient, setPatient] = React.useState<any>();
    React.useEffect(() => {
        const search = new URLSearchParams(props.location?.search);
        const id = search.get("id") || "";
        unauthenticatedEmailUnSubscribe(id).then((res) => {
            setPatient(res);
        }).catch((error) => {
            console.log(error);
        })

    }, [])
    return (
        <SmallCardFrame navItem={<LandingPageHeader />} bodyClassName="tc-bg">
            <div className="card-body">
                <div style={{ textAlign: 'center' }}>
                    {patient?.subscribed === false ?
                        <>
                            <h3>UNSUBSCRIBED SUCCESSFULLY</h3><br /><br />
                            <h1><i className="fas fa-check" style={{ color: 'green' }}></i></h1>
                            <br />
                            <p>
                                We just received your request to unsubscribe for TelMDCare emails
                                and You won't get any more.
                                <br />
                                Thank you!
                            </p>
                        </> : 'Wait a second Please!'
                    }
                </div>
            </div>
        </SmallCardFrame>
    );
};
