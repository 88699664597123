export enum ChoosePatientErrorMessages {
  selectPatient = 'Please Select the Patient Requesting Service',
  selectDependent = 'Please Select Dependent',
}

export enum ContactErrorMessages {
  invalidFirstName = 'Please Enter First Name',
  invalidLastName = 'Please Enter Last Name',
  enterEmail = 'Please Enter E-mail',
  enterPhone = 'Please Enter Phone Number',
  enterDateofBirth = 'Please Enter Date of Birth',
  enterRelation = 'Please Select Relation',
  invalidPhoneNumber = "Please Enter A Valid Phone Number"
}

export enum AddressErrorMessages {
  enterAddress = 'Please Enter Address',
  enterCity = 'Please Enter City',
  enterState = 'Please Select State',
  enterZipCode = 'Please Enter Zip Code',
  zipCodeLessThanFiveDigits = 'Zip Code has to be 5 digits',
  selectState = 'Please Select State'
}

export enum MeasurementsErrorMessages {
  enterHeightFt = 'Enter Height ft',
  enterHeightIn = 'Enter Height In',
  enterWeight = 'Enter Weight lbs',
  selectGender = 'Please Select Gender'
}

export enum BookingTimeContactErrorMessages {
  selectContactType = 'Please Select Phone or Video',
  choosePreferredTime = 'Please Choose the Prefered Time',
  selectDate = 'Please Select Date and Time ',
  enterValidTime = 'Please enter a valid time'
}

export enum PaymentErrorMessages {
  selectPaymentType = 'Please Select Payment Type',
  selectRelation = 'Please Select Relation',
  selectPaymentMethod = 'Please Select Payment Method'
}

export enum CreditCardErrorMessages {
  enterName = 'Please Enter Name on Card',
  enterCvv = 'Please Enter Card CVV',
  enterExpirationDate = 'Please Enter Card Expiration Date',
  enterNumber = 'Please Enter Card Number'
}