import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import BookingChoosePatientStep from './BookingChoosePatientStep';
import BookingPatientInformationStep from './BookingPatientInformationStep';
import BookingMedicalProfileStep from './BookingMedicalProfileStep';
import BookingConsultationStep from './BookingConsultationStep';
import BookingTimeContactStep from './BookingTimeContactStep';
import BookingPaymentStep from './BookingPaymentStep';
import { AppointmentSummary } from './AppointmentSummary';
import { CardBody } from 'reactstrap';
import { AppointmentDetails } from './AppointmentDetails';
import {
  getPatientInfo,
  getPatientCreditCards,
  getUSStatesLookup,
  getAppointmentPaymentInfo,
  getAllUSStateLookUpForPatient,
} from '../../core/service/services';
import BookingInsuranceStep from './BookingInsuranceStep';
import { useAppDispatch, useAppSelector } from './../../redux/hooks';
import { patientInfoUpdated, IPatientInfoState } from './../../redux/slices/general/patientInfoSlice'
import { creditCardsUpdated } from './../../redux/slices/general/creditCardsSlice'
import { availableStatesFetched, allStatesFetched } from './../../redux/slices/general/USAStatesSlice'
import {
  paymentAmountFetched,
  transactionFeeFetched,
  selectCurrentStep,
  bookingCleared,
} from '../../redux/slices/booking/bookingSlice'

interface IProps extends RouteComponentProps { }

export interface IError {
  key: string;
  value: string;
}

export const BookAppointment = (props: IProps) => {
  const step = useAppSelector(selectCurrentStep);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(bookingCleared())
    getData();
  }, []);

  const getData = () => {
    getPatientInfo()
      .then((res) => {
        dispatch(patientInfoUpdated(res as IPatientInfoState))
      })
      .catch((error) => console.error(error));

    getPatientCreditCards()
      .then((res) => {
        dispatch(creditCardsUpdated(res))
      });

    getUSStatesLookup()
      .then((res) => {
        dispatch(availableStatesFetched(res))
      });

    getAllUSStateLookUpForPatient()
      .then((res) => {
        dispatch(allStatesFetched(res))
      });

    getAppointmentPaymentInfo()
      .then((result) => {
        dispatch(paymentAmountFetched(result.paymentAmount))
        dispatch(transactionFeeFetched(result.transactionFee))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderWizardPage = () => {
    switch (step) {
      case 1:
        return (
          <BookingChoosePatientStep />
        );
      case 2:
        return (
          <BookingPatientInformationStep />
        );
      case 3:
        return (
          <BookingMedicalProfileStep />
        )
      case 4:
        return (
          <BookingConsultationStep
            smallCard={getSmallCard()}
          />
        );
      case 5:
        return (
          <BookingTimeContactStep
            smallCard={getSmallCard()}
          />
        );
      case 6:
        return (
          <BookingInsuranceStep
            smallCard={getSmallCard()}
          />
        );
      case 7:
        return (
          <BookingPaymentStep
            smallCard={getSmallCard()}
          />
        );
      case 8:
        return (
          <AppointmentSummary
            smallCard={getSmallCard()}
          />
        );
    }
  };

  const getSmallCard = () => {
    if (step >= 4 && step < 8) {
      return (
        <AppointmentDetails />
      )
    } else if (step === 8) {
      return (
        <CardBody>
          <h5 className='mb-0'>Next Steps:</h5>
          <hr className='mb-3'></hr>
          <ol className='ml-0 pl-3'>
            <li>
              The scheduled physician will reach out to you once they are
              available, as per the time selected.{' '}
            </li>
            <li>
              If you scheduled a video conference, the doctor will make their
              best effort to contact you via video. In some cases the doctor may
              need to alter the appointment to be via phone.{' '}
            </li>
            <li>
              If contacted via phone, our doctor may come up on your phone as
              "UNKOWN CALLER". You can change this setting on your phone. To change,{' '}
              <a href='#'>please read this article</a>{' '}
            </li>
          </ol>
        </CardBody>
      )
    }
  };

  return (
    <>
      {renderWizardPage()}
    </>
  );
};
