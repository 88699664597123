import React from 'react';
import {
  Col,
  FormGroup,
  Label,
  Form,
  Row,
  Input,
  FormFeedback,
} from 'reactstrap';
import Asterisk from '../../../components/commons/Asterisk';
import moment from 'moment';
import NewDatePicker from '../../../components/NewDatePicker';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  contactInfoUpdated,
  addressUpdated,
  selectContactInfo,
  selectAddress
} from '../../../redux/slices/general/patientInfoSlice';
import { selectAllStates } from '../../../redux/slices/general/USAStatesSlice';
import { IAddress, IContact } from '../../../redux/types/interfaces/patientInfoInterfaces';
import { I_USAState } from '../../../redux/types/interfaces/otherInterfaces';
import { selectShowErrors } from '../../../redux/slices/booking/errorSlice';
import { isInputDefined, isDefined, isNumber, isZipCodeValid, isPhoneNumberValid } from '../../../core/service/Validators';
import { ContactErrorMessages, AddressErrorMessages } from '../../../core/service/ErrorMessages';
interface IProps { }

const PersonalInformationRedux = (props: IProps) => {
  const contactInfo: IContact = useAppSelector(selectContactInfo)
  const address: IAddress | undefined = useAppSelector(selectAddress)
  const allStates: Array<I_USAState> = useAppSelector(selectAllStates)
  const showErrors = useAppSelector(selectShowErrors);

  const dispatch = useAppDispatch()

  const formGroupClass = 'form-label-group'

  const renderUsStatesList = () => {
    return allStates.map((state, idx) => {
      return (
        <option
          key={idx}
          value={state.id}
          selected={state.id === address?.stateId}
        >
          {state.name}
        </option>
      );
    });
  };

  const renderSelectedDateValue = () => { //move this inside component
    if (typeof contactInfo.dateOfBirth == 'string') {
      return contactInfo.dateOfBirth
    }
    return moment(contactInfo.dateOfBirth).format('YYYY-MM-DD') //check validity of this format
  }
  const renderPhoneNumbereErrorMessage = () => {
    if (!isInputDefined(contactInfo.phone)) {
      return ContactErrorMessages.enterPhone
    } else if (!isPhoneNumberValid(contactInfo.phone)) {
      return ContactErrorMessages.invalidPhoneNumber
    }
  }

  //TO-DO: Get back to this, we need a better way to handle multiple validations
  //problem with this that it validates at the component property 'Invalid' and again when displaying error message
  const renderZipCodeErrorMessage = () => {
    if (!isInputDefined(address?.zipCode)) {
      return AddressErrorMessages.enterZipCode
    } else if (!isZipCodeValid(address?.zipCode!)) {
      return AddressErrorMessages.zipCodeLessThanFiveDigits
    }
  }

  return (
    <div>
      <h6>
        <Label className='mb-3'>Personal Information</Label>
      </h6>
      <fieldset>
        <Form id='contact-form'>
          <Row form>
            <Col md='6' lg='4'>
              <FormGroup className={formGroupClass}>
                <Input
                  defaultValue={contactInfo.firstname}
                  type='text'
                  id='firstName'
                  name='firstName'
                  invalid={!isInputDefined(contactInfo.firstname) && showErrors}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(contactInfoUpdated({ ...contactInfo, firstname: e.target.value }))
                  }}
                ></Input>
                <FormFeedback hidden={isInputDefined(contactInfo.firstname)} className='error'>
                  {ContactErrorMessages.invalidFirstName}
                </FormFeedback>
                <Label for='firstName'>
                  First Name <Asterisk />
                </Label>
              </FormGroup>
            </Col>
            <Col md='6' lg='4'>
              <FormGroup className={formGroupClass}>
                <Input
                  defaultValue={contactInfo.middlename}
                  type='text'
                  id='middleName'
                  name='middleName'
                  invalid={!isInputDefined(contactInfo.firstname) && showErrors}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(contactInfoUpdated({ ...contactInfo, middlename: e.target.value }))
                  }}
                ></Input>
                <Label for='middleName'>
                  Middle Name 
                </Label>
              </FormGroup>
            </Col>
            <Col md='6' lg='4'>
              <FormGroup className={formGroupClass}>
                <Input
                  defaultValue={contactInfo.lastname}
                  type='text'
                  id='lastname'
                  name='lastname'
                  invalid={!isInputDefined(contactInfo.lastname) && showErrors}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(contactInfoUpdated({ ...contactInfo, lastname: e.target.value }))
                  }}
                ></Input>
                <FormFeedback hidden={isInputDefined(contactInfo.lastname)} className='error'>
                  {ContactErrorMessages.invalidLastName}
                </FormFeedback>
                <Label for='lastname'>
                  Last Name <Asterisk />
                </Label>
              </FormGroup>
            </Col>
            <Col md='6' lg='6'>
              <FormGroup className={formGroupClass}>
                <Input
                  type='text'
                  defaultValue={contactInfo.email}
                  id='email'
                  name='email'
                  invalid={!isInputDefined(contactInfo.email) && showErrors}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(contactInfoUpdated({ ...contactInfo, email: e.target.value }))
                  }}
                ></Input>
                <FormFeedback hidden={isInputDefined(contactInfo.email)} className='error'>
                  {ContactErrorMessages.enterEmail}
                </FormFeedback>
                <Label for='email'>
                  Email <Asterisk />
                </Label>
              </FormGroup>
            </Col>
            <Col md='6' lg='6'>
              <FormGroup className={formGroupClass}>
                <Input
                  defaultValue={contactInfo.phone}
                  type='tel'
                  id='phone'
                  name='phone'
                  invalid={(!isInputDefined(contactInfo.phone) || !isPhoneNumberValid(contactInfo.phone)) && showErrors}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(contactInfoUpdated({ ...contactInfo, phone: e.target.value }))
                  }}
                ></Input>
                <FormFeedback hidden={isInputDefined(contactInfo.phone) && isPhoneNumberValid(contactInfo.phone)} className='error'>
                  {renderPhoneNumbereErrorMessage()}
                </FormFeedback>
                <Label for='phone'>
                  Cell Phone <Asterisk />
                </Label>
              </FormGroup>
            </Col>
            <Col md='12' lg='12'>
              <FormGroup name='dob'>
              <FormFeedback hidden={isInputDefined(contactInfo.dateOfBirth)} className='error'>
+                  {ContactErrorMessages.enterDateofBirth}
+                </FormFeedback>
                <Label>
                  Date of Birth:
                  <Asterisk />
                </Label>
                <NewDatePicker
                  selectedDate={renderSelectedDateValue()}
                  onChange={(date: string) => {
                    dispatch(contactInfoUpdated({ ...contactInfo, dateOfBirth: date }))
                  }}
                  showValidation={showErrors}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md='6' lg='6'>
              <FormGroup className={formGroupClass}>
                <Input
                  type='text'
                  id='description'
                  name='description'
                  defaultValue={address?.description}
                  maxLength={35}
                  invalid={!isInputDefined(address?.description) && showErrors}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(addressUpdated({ ...address, description: e.target.value }))
                  }}
                ></Input>
                <FormFeedback hidden={isInputDefined(address?.description)} className='error'>
                  {AddressErrorMessages.enterAddress}
                </FormFeedback>
                <Label for='address'>
                  Address Line 1 <Asterisk />
                </Label>
              </FormGroup>
            </Col>
            <Col md='6' lg='6'>
              <FormGroup className={formGroupClass}>
                <Input
                  type='text'
                  id='addressLine2'
                  name='addressLine2'
                  defaultValue={address?.addressLine2}
                  maxLength={35}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(addressUpdated({ ...address, addressLine2: e.target.value }))
                  }}
                ></Input>
                <Label for='address'>Address Line 2 </Label>
              </FormGroup>
            </Col>
            <Col md='4' lg='5'>
              <FormGroup className={formGroupClass}>
                <Input
                  type='text'
                  id='city'
                  name='city'
                  defaultValue={address?.city}
                  maxLength={35}
                  invalid={!isInputDefined(address?.city) && showErrors}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(addressUpdated({ ...address, city: e.target.value }))
                  }}
                ></Input>
                <FormFeedback hidden={isInputDefined(address?.city)} className='error'>
                  {AddressErrorMessages.enterCity}
                </FormFeedback>
                <Label for='city'>
                  City <Asterisk />
                </Label>
              </FormGroup>
            </Col>
            <Col md='4' lg='3'>
              <FormGroup>
                <Label for='state'>
                  State <Asterisk />
                </Label>
                <Input
                  type='select'
                  id='state'
                  name='state'
                  defaultValue={address?.stateId}
                  invalid={(!isDefined(address?.stateId) || !isNumber(address?.stateId!)) && showErrors}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(addressUpdated({ ...address, stateId: parseInt(e.target.value) }))
                  }}
                >
                  <option value=''>Select state</option>
                  {renderUsStatesList()}
                </Input>
                <FormFeedback className='error' hidden={isDefined(address?.stateId) && isNumber(address?.stateId!)}>
                  {AddressErrorMessages.enterState}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md='4' lg='4'>
              <FormGroup className={formGroupClass}>
                <Input
                  type='text'
                  id='zipCode'
                  name='zipCode'
                  defaultValue={address?.zipCode}
                  invalid={(!isInputDefined(address?.zipCode) || !isZipCodeValid(address?.zipCode!)) && showErrors}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(addressUpdated({ ...address, zipCode: e.target.value }))
                  }}
                />
                <FormFeedback hidden={isInputDefined(address?.zipCode) && isZipCodeValid(address?.zipCode!)} className='error'>
                  {renderZipCodeErrorMessage()}
                </FormFeedback>
                <Label for='zipCode'>
                  Zip Code
                  <Asterisk />
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </fieldset>
    </div>
  );
};

export default PersonalInformationRedux;
