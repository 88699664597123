import React, { useEffect, useState } from 'react';
import { IProps } from '../../auth/PatientSignUp';
import TCNavbar from '../../../components/layout/TCNavBar';
import { navItem } from '../../../App';
import { Col, Alert, Container, Row, Card, CardBody, CardTitle } from 'reactstrap';
import { getAgentStatus } from '../../../core/service/services';
import Footer from '../../../components/layout/Footer';
import { UserConfig, IUserInfo } from '../../../config/userConfig';
import { PatientsOverview } from './PatientsOverview';
import { navigate } from '@reach/router';
import { Routes } from '../../../config/Routes';

export enum AgentStatus {
  Active = "Active",
  Disabled = "Disabled",
  New = "New"
}

interface IHomeAgentProps extends IProps {

}
export const HomeAgent: React.FunctionComponent<IHomeAgentProps> = (props: IHomeAgentProps) => {
  const [agentStatus, setAgentStatus] = useState<AgentStatus>();
  const [userInfo, setUserInfo] = useState<IUserInfo | null>();

  useEffect(() => {
    let user = UserConfig.getUserInfo();
    setUserInfo(user);
    getStatus();
  }, [props]);

  const getStatus = () => {
    getAgentStatus()
      .then((result: { status: AgentStatus }) => {
        setAgentStatus(result.status);
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const onClickRegisterNewPatient=()=>{
    navigate(Routes.patientSignup);
  }
  const renderRegisterPatientButton = () => (
    <Card className="card-telecare btn-responsive-5 my-5">
      <CardBody className="btn btn-primary" onClick={()=>onClickRegisterNewPatient()}>
        <span className="features-icons-icon d-inline-block "><i className="fa fa-plus-square mb-3"></i></span>
        <CardTitle className="mb-0">Register New Patient</CardTitle>
      </CardBody>
    </Card>
  )
  return (
    <>
      <TCNavbar bodyClassName="tc-bg">{navItem}</TCNavbar>
      <Container >
        <Row>
          {(agentStatus === AgentStatus.Active) &&

            <Col lg="8" xl="8">
              <Card className="card-telecare my-5">
                <CardBody>
                  <CardTitle className="mb-0">
                    {"Welcome " }
                    <span className="currentUserName">{userInfo?.firstname || " "}</span>
                  </CardTitle>
                </CardBody>
              </Card>
              {renderRegisterPatientButton()}
              <PatientsOverview />
            </Col>
          }

          <Col lg={agentStatus == AgentStatus.Disabled || agentStatus == AgentStatus.New ? "12" : "4"} xl={agentStatus === AgentStatus.Disabled || agentStatus === AgentStatus.New ? "12" : "4"}>
            {agentStatus === AgentStatus.New && <Alert className="mt-4" color="danger">Pending system administrator approval!</Alert>}
            {agentStatus === AgentStatus.Disabled && <Alert className="mt-4" color="danger">Agent account is disabled.</Alert>}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}