import { isEmpty } from "lodash"
import { IDependent } from "../../redux/types/interfaces/patientInfoInterfaces"
import { ApptForUser } from "../../redux/types/enums/bookingEnums"
import moment from "moment"

export const isInputDefined = (value: string | undefined) => {
    return isDefined(value) && !isEmptyString(value!)
}

export const isDefined = (value: any) => {
    return value !== undefined
}

export const isEmptyString = (value: string) => {
    return value === ''
}

export const isNumber = (value: number) => {
    return !isNaN(value)
}

//TO-DO: this needs proper reviewing if it's needed
export const isPatientChosen = (
    selectedDependent: IDependent | undefined, 
    dependents: IDependent[] | undefined, 
    apptForUser: ApptForUser | undefined
) => {
    if (apptForUser) {
        if (apptForUser === ApptForUser.Self) {
            return true
        } else if (dependents?.length === 0) {
            return true
        } else if (!isEmpty(selectedDependent)) {
            return true
        }
    }
    return false
}

export const isZipCodeValid = (value: string) => {
    return value.length === 5
}

export const isTimeValid = (chosenTime: Date | string, currentTime: Date | string) => {
    return moment(chosenTime).isSameOrAfter(currentTime, "hours")
}

export const isPhoneNumberValid = (value: string) => {

    const validAreaCodeRegex = /^(?!555|0|1)\d{3}$/;

    const extensionRegex = /^x\d+$/i;

    const formattedNumberRegex = /^\d{3}\d{7}$/;

    const repeatedDigitsRegex = /(\d)(?:.*\1){6}/;

    if (!formattedNumberRegex.test(value)) {
    return false;
    }

    if (repeatedDigitsRegex.test(value)) {
        return false;
    }

    const areaCode = value.substr(0, 3);

    if (!validAreaCodeRegex.test(areaCode)) {
    return false;
    }

    const extensionMatch = value.match(extensionRegex);
    if (extensionMatch) {
    value = value.replace(extensionMatch[0], '');
    }

    return true;
};