import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  Card,
  CardBody,
} from "reactstrap";
import { AppointmentsWaitingArea } from "./AppointmentsWaitingArea";
import { PastAppointments } from "../appointment/PastAppointments";
import { IMessage } from "../../chat-module/MessageModel";
import { RxChangeAppointments } from "./RxChangeAppointments";
import { RxReconcileAppointements } from "./RxReconcileAppointements";

interface IProps extends RouteComponentProps {
  available: boolean,
  onChatOpened: (appointmentId: string, receiverName: string) => void,
  addedMessage?: IMessage,
  onMessageReceived?: (msg: IMessage) => void,
  appointmentToUpdate?: { id: string, count: number }

}

interface IState {
  available: boolean,
  refetch: boolean
}

export class PhysicianAppointments extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      available: this.props.available,
      refetch: false

    };
  }

  componentWillReceiveProps(props: IProps) {
    this.setState({
      available: props.available
    })
  }
  refetchCallback() {

  }

  render() {
    return (
      <>
        <h5 className="text-white mx-3"><i className="fa fa-calendar"></i> Appointments</h5>
        <Card className="card-telecare mt-3 mb-5">
          <CardBody>
            <section className="section-content-block">
              <div id="apptBody" className="section-content-body">
                <AppointmentsWaitingArea availability={this.state.available} onChatOpened={this.props.onChatOpened} onMessageReceived={this.props.onMessageReceived}
                  addedMessage={this.props.addedMessage} updatedAppointment={this.props.appointmentToUpdate} ></AppointmentsWaitingArea>
                <RxChangeAppointments refetch={this.state.refetch} removeRefetch={() => {
                  this.setState({
                    refetch: false
                  })
                }} />
                <RxReconcileAppointements setRefetch={() => {
                  this.setState({
                    refetch: true

                  })
                }} />
                <PastAppointments onChatOpened={this.props.onChatOpened} onMessageReceived={this.props.onMessageReceived}
                  addedMessage={this.props.addedMessage}></PastAppointments>
              </div>
            </section>
          </CardBody>
        </Card>
      </>
    );
  }
}
