import React from 'react';
import { Button } from 'reactstrap';

interface IProps {
	toggle: any,
	expanded: boolean
}
export const AdminExpandingButton = (props: IProps) => {
	return (
		<Button outline color="success" size="sm" style={{ float: 'right' }} onClick={props.toggle}>

			{props.expanded ?
				<i color="success" className="fas fa-minus"></i>
				:
				<i color="success" className="fas fa-plus"></i>
			}
		</Button>
	)
}