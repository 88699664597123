import React, { useState, useEffect } from "react";
import { Link, navigate } from "@reach/router";
import { Badge, Row, Col } from "reactstrap";
import { localizeTimezone } from "../../../core/service/TimeZone";
import { agent_image } from "../../settings/PhysicianSettings";
import { IAgent } from "./AgentsOverview";
import { AgentItemActions } from "./AgentItemActions";
import { Routes } from "../../../config/Routes";
import { IBiller } from "./biller-overivew/BillerOverview";

interface IProps {
  data: IAgent | IBiller;
  updateItem: (item: IAgent | IBiller) => void,
  isAgent: boolean
}

export const AgentItemCard: React.FC<IProps> = props => {

  const [person, setPerson] = useState<IAgent | IBiller>(props.data)

  useEffect(() => {
    setPerson(props.data)
  }, [props.data])

  const generateImage = (): any => {
    return agent_image;
  };

  const onAgentClick = (e: React.MouseEvent) => {
    e.preventDefault()
    if (props.isAgent) {
      navigate(Routes.agentHistory + "?agentId=" + person.id + "&name=" + person.firstname)
    }
  }

  const renderTitle = () => {

    return (
      <Link onClick={onAgentClick} className="mr-auto" to="#">
        <img src={generateImage().default} className="avatar" />{"  "}
        {person.firstname + " " + person.lastname}
      </Link>
    )
  }

  const statusBadge = () => {
    let color = ""
    const status = person.status;
    switch (status) {
      case "Active":
        color = "primary"
        break;
      case "Disabled":
        color = "danger"
        break;
      case "New":
        color = "success"
        break;
    }
    return (
      <Badge color={color} className="p-1 mr-1 ">{status === "New" ? "Pending Approval" : status}</Badge>
    )
  }

  const renderStateAndDate = () => (
    <ul className="list-inline text-muted mb-1">
      <li className="list-inline-item mx-0">
        {statusBadge()}
      </li>
      <li className="list-inline-item mx-0">
        <small>
          <span className="day3">{" Joined On: " + localizeTimezone(person.joinedAt.toString(), 'ddd MMMM Do YYYY')}</span>
        </small>
      </li>

    </ul>
  )

  return (
    <div>
      <div style={{ width: "80%" }}>
        <Row>
          <Col lg="5" xl="5">
            {renderTitle()}
            {renderStateAndDate()}
          </Col>
        </Row>

      </div>
      <AgentItemActions data={person} updateItem={props.updateItem} isAgent={props.isAgent} />

    </div>
  );
};
