import React, { useState, useEffect } from 'react'
import { Link, RouteComponentProps, navigate } from '@reach/router';
import { Routes } from '../../../config/Routes';
import { Button, CardBody, Table, Badge, UncontrolledTooltip } from 'reactstrap';
import { BigCardFrame } from '../../../components/layout/BigCardFrame';
import { IAppointmentHistoryItem, getAppointmentHistory } from '../../../core/service/services';
import { localizeTimezone } from '../../../core/service/TimeZone';
import { AppointmentStatus } from '../../book-appointment/AppointmentModels';
import { navItem } from '../../../App';

interface IProps extends RouteComponentProps {}

export const AppointmentHistory: React.FC<IProps> = (props) => {

  const [historyItems, setHistoryItems] = useState<IAppointmentHistoryItem[]>([])
  const [appointmentId, setAppointmentId] = useState<string>("")
  const [patientName, setPatientName] = useState<string>("")

  useEffect(() => {
    const search = new URLSearchParams(props.location?.search);
    const appointmentId = search.get("appointmentId") || "";
    const patientName = search.get("patient") || "";
    setAppointmentId(appointmentId)
    setPatientName(patientName)
    //get appointment history
    fetchAppointmentHistory(appointmentId)
  }, [])
  
  const fetchAppointmentHistory= async (id:string)=>{
    getAppointmentHistory(id).then((items:IAppointmentHistoryItem[])=>{
      setHistoryItems(items);
    }).catch();
    
  }
  const flowNav = (
    <div className="flow-nav-tools">
      <Button color="primary" size="lg" onClick={() => navigate(Routes.adminConsole)} className="control-nav">
        Back
      </Button>
    </div>
  );


  const statusBadge = (newStatus: AppointmentStatus) => {
    let color = ""
    switch (newStatus) {
      case "Waiting":
        color = "primary"
        break;
      case "Rescheduled":
        color = "warning"
        break;
      case "Locked":
        color = "success"
        break;
      case "Completed":
        color = "success"
        break;
      case "Started":
        color = "dark"
        break;
      case "Canceled By Patient":
      case "Canceled By System":
      case "Canceled By Physician":
        color = "danger"
        break;
      case "Archived":
        color = "secondary"
        break;
    }
    return (
      <Badge color={color} className="p-1 mr-1 ">{newStatus}</Badge>
    )
  }

  return (
    <BigCardFrame
      flowNav={flowNav}
      navItem={navItem}
      bodyClassName="tc-bg"
      horizontal={true}
    > 
      <CardBody>
        <div className="d-flex px-2">
          <h5 className="mb-0">Appointment History: {appointmentId}</h5>
          <Link to={Routes.adminConsole} className="btn-sm ml-auto">
            Back
          </Link>
        </div>
        <hr className="mt-2 mb-4" />
        <h6>Patient: {patientName}</h6>
        <Table>
          <thead>
            <th>Timestamp</th>
            <th>Action</th>
            <th>User</th>
            <th>User Type</th>
            <th>Status</th>
          </thead>
          <tbody>
            {historyItems.map(item => (
              <tr>
                <td>{localizeTimezone(item.timestamp.toString(),'ddd YYYY-MM-DD HH:mm:ss')}</td>
                <td>{item.action}</td>
                <td>{(item.actorType === "patient" || "agent" ? "" : "Dr. ") + item.actorName}</td>
                <td>{item.actorType === "patient" &&
                  <>
                  <UncontrolledTooltip placement="bottom" target="pt">Patient</UncontrolledTooltip>
                  <img src="https://img.icons8.com/officel/30/000000/user.png" id="pt"/>
                  </>
                  }{item.actorType === "physician" &&
                  <>
                  <UncontrolledTooltip placement="bottom" target="ph">Physician</UncontrolledTooltip>
                  <img src="https://img.icons8.com/officel/30/000000/medical-doctor.png" id="ph"/>
                  </>
                  }{item.actorType === "agent" &&
                  <>
                  <UncontrolledTooltip placement="bottom" target="ag">Agent</UncontrolledTooltip>
                  <img src="https://img.icons8.com/cotton/30/000000/customer-support.png" id="ag"/>
                  </>
                  }
                </td>
                <td>{statusBadge(item.newStatus)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </BigCardFrame>
  )
}