import React, { useState, useEffect } from 'react';
import { IAgent } from './AgentsOverview';
import { enableAgentBiller } from '../../../core/service/services';
import { toastSuccess } from '../../../App';
import { Link } from "@reach/router";
import { UncontrolledTooltip } from 'reactstrap';
import { AlertMessage } from '../../../core/alert/Alerts';
import { AdminAction } from './PhysicianItemActions';
import { IBiller } from './biller-overivew/BillerOverview';
import { strings } from '../../../Constants';
interface IProps {
  data: IAgent | IBiller
  updateItem: (item: IAgent | IBiller) => void
  isAgent: boolean
}
export const AgentItemActions: React.FC<IProps> = (props: IProps) => {
  const [confirmationMessage, setConfirmationMessage] = useState<string>("")
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const [currentAction, setCurrentAction] = useState<AdminAction>("NONE")
  const [user, setUser] = useState<IAgent | IAgent | IBiller>(props.data)

  useEffect(() => {
    setUser(props.data)
  }, [props.data])

  const userName = `${props.data.firstname}  ${props.data.lastname}`

  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal)
  }

  const enableBtn = () => (
    <Link to="" onClick={() => onEnableClicked(userName)} className="text-primary">
      <UncontrolledTooltip placement="top" target="enableAgent">{props.isAgent ? strings.biller.enableAgent : strings.biller.enableBiller}</UncontrolledTooltip>
      <i className="fa fa-2x fa-check-circle" id="enableAgent" />
    </Link>
  )

  const disableBtn = () => (
    <Link to="" onClick={() => onDisableClicked(userName)} className="text-danger">
      <UncontrolledTooltip placement="top" target="disableAgent">{props.isAgent ? strings.biller.disableAgent : strings.biller.disableBiller}</UncontrolledTooltip>
      <i className="fa fa-2x fa-times-circle" id="disableAgent" />
    </Link>
  )

  const approveBtn = () => (
    <Link to="" onClick={() => onApproveClicked(userName)} className="text-success">
      <UncontrolledTooltip placement="top" target="approveAgent">{props.isAgent ? strings.biller.approveAgent : strings.biller.approveBiller}</UncontrolledTooltip>
      <i className="fa fa-2x fa-check-square" id="approveAgent" />
    </Link>
  )

  const enableAgentAction = (enabled: boolean) => {
    let action = enabled ? "enable" : "disable";
    enableAgentBiller({ action: action }, user.id, props.isAgent).then(res => {
      props.updateItem(res)
      toastSuccess(enabled ? (props.isAgent ? "Agent" : "Biller") + " is allowed to use the platform functions!" : (props.isAgent ? "Agent" : "Biller") + " Disabled!")
    }).catch(err => console.log(err))

  }
  const onAdminAction = (response: boolean) => {
    if (!response) {
      switch (currentAction) {
        case "APPROVE":
        case "ENABLE":
          enableAgentAction(true)
          break;
        case "DISABLE":
          enableAgentAction(false)
          break;
      }
    }
    toggleConfirmationModal()
  }
  const onApproveClicked = (agentName: string) => {
    setConfirmationMessage("Are you sure you want to approve " + agentName + "?")
    setCurrentAction("APPROVE")
    setConfirmationModal(true)
  }

  const onEnableClicked = (agentName: string) => {
    setConfirmationMessage("Are you sure you want to enable access for " + agentName + "?")
    setCurrentAction("ENABLE")
    setConfirmationModal(true)
  }

  const onDisableClicked = (agentName: string) => {
    setConfirmationMessage("Are you sure you want to disable access for " + agentName + "?")
    setCurrentAction("DISABLE")
    setConfirmationModal(true)
  }

  const status = user.status

  return (
    <div className="actionsStyle">
      <AlertMessage isOpen={confirmationModal} message={confirmationMessage} options={["No", "Yes"]}
        toggleOpen={toggleConfirmationModal} setResponse={onAdminAction} />
      {status === "Disabled" && enableBtn()}
      {status === "Active" && disableBtn()}
      {status === "New" && approveBtn()}
    </div>
  )
}