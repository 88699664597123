import React from "react";
import PatientSettingsButton from './PatientSettingsButton';
import { Routes } from "../../config/Routes";

interface IProps { title: string}
const PatientSettingsHeader = (props:IProps) => {
  return (
    <div className="d-flex px-2">
      <h5 className="mb-0">{props.title}</h5>
      <PatientSettingsButton to={Routes.homePatient} label="Close" className="btn btn-link btn-sm ml-auto"/>
    </div>
  );
};
export default PatientSettingsHeader;
