import React, { useState } from "react";
import { FormGroup, Label, CustomInput } from "reactstrap";
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
    previousConditionsUpdated,
    hasPreviousConditionsBooleanChanged,
    selectPreviousConditions,
    selectHasPreviousConditions
} from '../../../redux/slices/general/medicalProfileSlice';
import { IItem } from '../../../redux/types/interfaces/medicalProfileInterfaces';

interface IPreviousConditionsModalProps {
    closeModal: () => void;
}

export const PreviousConditionsModalRedux: React.FC<IPreviousConditionsModalProps> = ({
    closeModal
}: IPreviousConditionsModalProps) => {
    const previousConditions: Array<IItem> = useAppSelector(selectPreviousConditions)
    const hasPreviousConditions: boolean = useAppSelector(selectHasPreviousConditions)
    const [newHasPreviousConditions, setNewHasPreviousConditions] = useState<boolean>(hasPreviousConditions)
    const [newPreviousConditions, setNewPreviousConditions] = useState<Array<IItem>>(previousConditions)

    const dispatch = useAppDispatch();

    const handleSelectingYes = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.checked) {
            setNewHasPreviousConditions(true)
        }
    }

    const handleSelectingNo = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.checked) {
            setNewHasPreviousConditions(false)
        }
    }

    const cancelPressed = () => {
        closeModal()
    }

    const savePressed = () => {
        dispatch(hasPreviousConditionsBooleanChanged(newHasPreviousConditions))
        dispatch(previousConditionsUpdated(newPreviousConditions))
        closeModal()
    }

    const updateLocalPreviousConditions = (condition: IItem) => {
        const updatedConditions = newPreviousConditions.map((item: IItem) => {
            if (item.id === condition.id) {
                return condition
            }
            return item
        })
        setNewPreviousConditions(updatedConditions)
    }

    const renderConditions = () => {
        if (newHasPreviousConditions) {
            return (
                <>
                    <p>Please choose from the list below:</p>
                    <div className="row">
                        {
                            newPreviousConditions.map((condition) => {
                                let id = "InList-" + condition.id
                                return (
                                    <div className="col-sm-6 col-md-6">
                                        <div className="mb-2">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    id={id}
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    value={condition.id}
                                                    defaultChecked={condition.checked}
                                                    onChange={(e) => {
                                                        const updatedCondition = { ...condition, checked: e.target.checked }
                                                        updateLocalPreviousConditions(updatedCondition)
                                                    }}
                                                />
                                                <label htmlFor={id} className="custom-control-label">{condition.display}</label>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            )
        }
    }

    const rootStyle: React.CSSProperties = { display: "block", paddingRight: "15px" }

    return (
        <>
            <div id="updateMedHistoryModal" data-tabindex="-1" role="dialog" aria-labelledby="updateMedHistoryModalLabel" className="modal fade show" style={rootStyle} aria-modal="true">
                <div role="document" className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 id="updateMedHistoryModalLabel" className="modal-title">Medical Profile &gt; Previous Conditions</h5>
                            <button type="button" data-dismiss="modal" aria-label="Close" className="close" onClick={cancelPressed}><span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <FormGroup>
                                <div id="intro">
                                    <Label for="intro">
                                        Do they have any previous conditions:
                                    </Label>
                                    <CustomInput
                                        type="radio"
                                        id="yes"
                                        name="intro"
                                        label="Yes"
                                        defaultChecked={hasPreviousConditions}
                                        onChange={handleSelectingYes}
                                    />
                                    <CustomInput
                                        type="radio"
                                        id="no"
                                        name="intro"
                                        label="No"
                                        defaultChecked={!hasPreviousConditions}
                                        onChange={handleSelectingNo}
                                    />
                                </div>
                            </FormGroup>
                            {renderConditions()}
                        </div>
                        <div className="modal-footer">
                            <button onClick={cancelPressed} type="button" data-dismiss="modal" className="btn btn-link">Cancel</button>
                            <button type="button" onClick={savePressed} data-dismiss="modal" className="btn btn-success">Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    )
}