import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  CustomInput,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import MedicalAllergiesDoseSpotForm, { IAddAllergy, IMedication, IFullAllergy } from "./MedicationAllergiesDoseSpot";
import MedicationsAllergiesList from "./MedicationsAllergiesList";
import Asterisk from "../commons/Asterisk";
// import { ISelectedItems } from "../patientSettings/AddHouseholdMemberModal";
// import { IMedicationItemWithRemovedFlag } from "../../core/service/services";

export interface IMedicationItemWithRemovedFlag {
  id?: number;
  name: string;
  removed: boolean;
}
interface IProps {
  medications?: IMedicationItemWithRemovedFlag[];
  medicationsChanged: (items: IMedicationItemWithRemovedFlag[]) => void;
  newMedications?:any
  medicationsAllergies?:any
  deletedAllergies?:any
  editedAllergies:any
  medicationAllergic?: any;
  onChangeMedicationAllergyStatus:any;
  newAllergic?:any;
}
const MedicationAllergies = (props: IProps) => {
  const [modal, setModal] = useState(false);
  const [allegric, setAllegric] = useState( (props.medicationsAllergies?.length || 0 )> 0 );
  const [currentMedicationAllergyStatus, setCurrentMedicationAllergyStatus] = React.useState<boolean>();
  const toggleModal = () => {
    setModal(!modal)
  };
  const saveMedicationAllergies=(allergy:IAddAllergy)=>{
    newMedications? setNewMedications([allergy, ...newMedications]): setNewMedications([allergy]);
    setMedicationsAllergyList([allergy,...medicationsAllergyList]);
    if (modal) {
      props.medicationsChanged(medicationsList)
      props.newMedications([allergy, ...newMedications]);
      props.onChangeMedicationAllergyStatus(currentMedicationAllergyStatus);
    }
    toggleModal();
  }
  const setMedicationAllergiesQuestions = (aller: boolean) => setAllegric(aller);
  const [medicationsList, setMedicationsList] = useState(
    Array<IMedicationItemWithRemovedFlag>()
  );
  const [medicationsAllergyList, setMedicationsAllergyList] = useState(
    Array<IAddAllergy>()
  );
  const [newMedications, setNewMedications] = useState<IAddAllergy[]>([]);
  useEffect(() => {
    setMedicationsList(props.medicationsAllergies || [])
    setMedicationsAllergyList(props.medicationsAllergies);
    setCurrentMedicationAllergyStatus(props.medicationAllergic);
  }, [props]);

  let inputRef = useRef<HTMLInputElement>(null)
  const onAddMedications = () => {
    let value = inputRef.current?.value || "";
    inputRef.current && (inputRef.current.value = "");
    let newMed: IMedicationItemWithRemovedFlag = { name: value || "", removed: false }

    setMedicationsList([...medicationsList, newMed])
  }
  const renderForm = () => {
    return (
      <Form>
        <FormGroup>
          <Label for="select">Are you allergic to any medication?</Label>
          <InputGroup>
            <Input placeholder="Enter prescription that causes allergies" type="text"
              //onChange= {e =>  setNewMedication(e.target.value)  } 
              innerRef={inputRef}
            />
            <InputGroupAddon addonType="append">
              <Button color="success" onClick={onAddMedications}>Add Medication</Button>
            </InputGroupAddon>
          </InputGroup>
              {renderMedicationsListUponStatus()}
        </FormGroup>
      </Form>
    );
  };
  const onNoChoice=()=>{
    setCurrentMedicationAllergyStatus(false);
    props.onChangeMedicationAllergyStatus(false);
    toggleModal();
  }
  const renderModal = () => {
    return (
      <div>
        <Modal isOpen={modal}>
          <ModalHeader toggle={toggleModal}>Medical Profile {`>`} Medication Allergies</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <div id="intro">
                  <Label for="intro">Are you allergic to any medication?</Label>
                  <CustomInput
                    onChange={(e) => 
                      e.target.checked &&
                      setCurrentMedicationAllergyStatus(true)}
                    type="radio"
                    id="yes"
                    name="intro"
                    label="Yes"
                    defaultChecked={currentMedicationAllergyStatus}
                  />
                  <CustomInput
                    onChange={(e) => e.target.checked &&
                      onNoChoice()}
                    type="radio"
                    id="no"
                    name="intro"
                    label="No"
                    defaultChecked={currentMedicationAllergyStatus === undefined? false: !currentMedicationAllergyStatus}
                  />
                </div>
              </FormGroup>
            </Form>

            {currentMedicationAllergyStatus ? <MedicalAllergiesDoseSpotForm setNewMedication={saveMedicationAllergies} /> : null}
            <FormGroup>
            <ListGroup 
            style={{ maxHeight: 100, overflow: "auto" }}
            flush >
              {(currentMedicationAllergyStatus === undefined || currentMedicationAllergyStatus === true)? renderListGroupItems():'None'}
              </ListGroup>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  const onRemove = (index: number) => {
    medicationsList[index].removed = true;
    // this is done to trigger state chnge
    setMedicationsList([
      ...medicationsList
    ]);
  };
  const onRemoveMedications=(e:any)=>{
    setMedicationsAllergyList(
      medicationsAllergyList.filter((item)=>{
        return item.Code !== e.Code
      }))
      setNewMedications(
        newMedications?.filter((item)=>{
          return item.Code !== e.Code
        })
      )
      props.deletedAllergies(e);
  }
  const onEditMedications=(e:any)=>{
      props.editedAllergies(e);
  }
  const renderSpanNone=()=>{
    return <span>None</span>
  }
  const renderMedicationsListUponStatus=()=>{
    if(currentMedicationAllergyStatus === undefined && props.newAllergic === undefined)
      return renderListGroupItems();
    else if (currentMedicationAllergyStatus === true && props.newAllergic === undefined)
      return renderListGroupItems();
    else if(currentMedicationAllergyStatus === false && props.newAllergic === undefined)
      return renderSpanNone()
    else if (props.newAllergic === true)
      return renderListGroupItems();
    else if(props.newAllergic === false)
      return renderSpanNone()
  }
  const renderListGroupItems = () => {
    return (
      <MedicationsAllergiesList  medicationsAllergy={medicationsAllergyList} maxHeight={100} onRemove={(e:any)=>{
        onRemoveMedications(e);
      }}
      onEdit={(e:any)=>{
        onEditMedications(e)
      }}
      />
    )
  };
  const renderListGroupItemsX = () => {
    let medicationsNode = medicationsList?.map((m, index) => {
      return { ...m, index: index }
    }).filter(m => !m.removed).map(
      (m) => {
        return (

          <ListGroupItem key={"meds_" + m.index}><span> {m.name} </span>

            <Button
              type="button"
              color="link"
              data-toggle="tooltip"
              className="btn btn-link text-muted ml-auto"
              data-original-title="Delete Medication"
              onClick={() => {
                onRemove(m.index);
              }}
            >
              <span className="sr-only">Remove</span>{" "}
              <i className="fa fa-trash"></i>
            </Button>
          </ListGroupItem>

        );
      }
    );
    return medicationsNode;
  };

  const renderMedicationsStringUponStatus=()=>{
    if(currentMedicationAllergyStatus === undefined && props.newAllergic === undefined)
      return getMedicationAlergiesStr();
    else if (currentMedicationAllergyStatus === true && props.newAllergic === undefined)
      return getMedicationAlergiesStr();
    else if(currentMedicationAllergyStatus === false && props.newAllergic === undefined)
      return renderSpanNone()
    else if (props.newAllergic === true)
      return getMedicationAlergiesStr();
    else if(props.newAllergic === false)
      return renderSpanNone()
  }
  const getMedicationAlergiesStr =()=>{
    let allergies: string[] = [];
    medicationsAllergyList?.map((med: IAddAllergy) => {
      allergies.push(med.Name);
    });
    newMedications?.map((med: IAddAllergy) => {
      if (med.Name) allergies.push(med.Name);
    });
    return allergies.join(", ");
  }
  const getMedicationAlergiesStrX = () => {
    let allergies: string[] = [];
    if (props.newAllergic === undefined || props.newAllergic === true) {
      newMedications.map((med: IAddAllergy) => {
        if (med.Name) allergies.push(med.Name);
      });
      if (medicationsAllergyList && medicationsAllergyList.length > 0) {
        medicationsAllergyList?.map((med: IAddAllergy) => {
          allergies.push(med.Name);
        });
      }
      if(allergies.length>0)
        return allergies.join(", ");
      else return <span>None</span>;
    }
    else return <span>None</span>;
  };
  return (
    <h6 className="mb-3">
      {renderModal()}
      Medication Allergies{" "}<Asterisk/>
      <Button color="link" size="sm" className="pt-0" onClick={toggleModal}>
        <span>Add</span>
      </Button>
      <br/>
      {renderMedicationsStringUponStatus()}
    </h6>
  );
};
export default MedicationAllergies;