import React from 'react';
import {
  Input,
  Label,
  FormGroup,
  Form,
  CustomInput,
  Col,
  Row,
  FormFeedback,
} from 'reactstrap';
import Asterisk from '../../../components/commons/Asterisk';
import NewDatePicker from '../../../components/NewDatePicker';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  dependentUpdated,
  selectDependent
} from '../../../redux/slices/booking/choosePatientStepSlice';
import { IDependent } from '../../../redux/types/interfaces/patientInfoInterfaces';
import { RelationshipToDependable } from '../../../redux/types/enums/patientInfoEnums'
import { selectShowErrors } from '../../../redux/slices/booking/errorSlice';
import { isInputDefined, isDefined } from '../../../core/service/Validators';
import { ContactErrorMessages } from '../../../core/service/ErrorMessages';
import CustomErrorMessage from '../../../components/CustomErrorMessage';

interface IProps { }

const DependentInformation = (props: IProps) => {
  const dependent: IDependent | undefined = useAppSelector(selectDependent)
  const showErrors = useAppSelector(selectShowErrors);

  const dispatch = useAppDispatch()

  const formGroupClass = 'form-label-group'

  return (
    <>
      <h6>
        <Label className='mb-3'>Member Information</Label>
      </h6>
      <Form>
        <Row form>
          <Col md='6' lg='4'>
            <FormGroup className={formGroupClass}>
              <Label for='addMemberFirstName'>
                First Name <Asterisk />
              </Label>
              <Input
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(dependentUpdated({ ...dependent, firstname: e.target.value }))
                }}
                type='text'
                id='addMemberFirstName'
                defaultValue={dependent?.firstname}
                name='addMemberFirstName'
                invalid={!isInputDefined(dependent?.firstname) && showErrors}
                required={true}
              />
              <FormFeedback hidden={isInputDefined(dependent?.firstname)} className='error'>
                {ContactErrorMessages.invalidFirstName}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md='6' lg='4'>
            <FormGroup className={formGroupClass}>
              <Label for='addMemberMiddleName'>
                Middle Name 
              </Label>
              <Input
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(dependentUpdated({ ...dependent, middlename: e.target.value }))
                }}
                type='text'
                id='addMemberMiddleName'
                defaultValue={dependent?.middlename}
                name='addMemberMiddleName'
              />
            </FormGroup>
          </Col>
          <Col md='6' lg='4'>
            <FormGroup className={formGroupClass}>
              <Label for='addMemberLastName'>
                Last Name <Asterisk />
              </Label>
              <Input
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(dependentUpdated({ ...dependent, lastname: e.target.value }))
                }}
                type='text'
                id='addMemberLastName'
                defaultValue={dependent?.lastname}
                name='addMemberLastName'
                invalid={!isInputDefined(dependent?.lastname) && showErrors}
                required={true}
              />
              <FormFeedback hidden={isInputDefined(dependent?.lastname)} className='error'>
                {ContactErrorMessages.invalidLastName}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for='familyRelation'>
            Choose Relationship <Asterisk />{' '}
          </Label>
          <div id='familyRelation'>
          <CustomInput
              onChange={() => {
                dispatch(dependentUpdated({
                  ...dependent,
                  relationshipToDependable: RelationshipToDependable.Spouse
                }))
              }}
              required={true}
              type='radio'
              id='spouse'
              name='familyRelation'
              label='Spouse'
              defaultChecked={dependent?.relationshipToDependable === RelationshipToDependable.Spouse}
            />
          <CustomInput
              required={true}
              type='radio'
              id='familyMember'
              name='familyRelation'
              label='Family Member'
              onChange={() => {
                dispatch(dependentUpdated({
                  ...dependent,
                  relationshipToDependable: RelationshipToDependable.FamilyMember
                }))
              }}
              defaultChecked={dependent?.relationshipToDependable === RelationshipToDependable.FamilyMember }
            />
            <CustomInput
              onChange={() => {
                dispatch(dependentUpdated({
                  ...dependent,
                  relationshipToDependable: RelationshipToDependable.NonFamilyMember
                }))
              }}
              required={true}
              type='radio'
              id='nonFamilyRelation'
              name='familyRelation'
              label='Patient Referral'
              defaultChecked={dependent?.relationshipToDependable === RelationshipToDependable.NonFamilyMember}
            />
            <CustomErrorMessage showValidation={showErrors} invalid={!isDefined(dependent?.relationshipToDependable)} errorMessage={ContactErrorMessages.enterRelation} ></CustomErrorMessage>
          </div>
        </FormGroup>
        <FormGroup>
          <Label>
            Date of Birth:
            <Asterisk />
          </Label>
          <NewDatePicker
            selectedDate={dependent?.dateOfBirth}
            onChange={(date: string) => {
              dispatch(dependentUpdated({ ...dependent, dateOfBirth: date }))
            }}
            showValidation={showErrors}
          />
        </FormGroup>
      </Form>
    </>
  );
};
export default DependentInformation;
