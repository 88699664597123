import React, { useRef, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  Row,
  Col,
} from "reactstrap";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";
import Asterisk from "../commons/Asterisk";
import { searchMedicationAllergies } from "../../core/service/services";
import { toastError, toastSuccess } from "../../App";
import { GroupBase } from "react-select";

export const AllergyReactionType = [
  {
    name: "Allergy",
    code: 1,
  },
  {
    name: "AdverseReaction",
    code: 2,
  },
];
export const AllergyStatusType = [
  {
    name: "Active",
    code: 1,
  },
  {
    name: "Inactive",
    code: 2,
  },
];
export interface IFullAllergy {
  id: number;
  Name: string;
  Code: string;
  CodeType: number;
  ReactionType: string;
  StatusType: string;
  OnsetDate?: Date;
  Reaction?: string;
  allergyDoseSpotId: string;
  RxCUI?: string;
}
export interface IAddAllergy {
  Name: string;
  Code?: string;
  CodeType?: number;
  RxCUI?: string;
  AllergenId: number;
  Reaction?: string;
  ReactionType: string;
  StatusType: string;
  OnsetDate?: string | Date;
}
export interface IMedication {
  name: string;
  brandName: string;
  allergenId: number;
  allergenType: string;
  isActive?: true;
}

export interface ISearchResultDto {
  items: IMedication[];
  pageNum: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
}

interface ISelectProps {
  value: number;
  label: string;
}

interface IProps {
  setNewMedication: any;
}

const MedicalAllergiesDoseSpotForm = (props: IProps) => {
  const [selectedMedication, setSelectedMedication] = useState<IAddAllergy>({
    ReactionType: '',
    StatusType: '',
    Name: '',
    AllergenId: 1,
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const onAddClick = () => {
    inputRef.current && (inputRef.current.value = "");
    if (selectedMedication.Name) {
      toastSuccess("New Medication added");
      props.setNewMedication(selectedMedication);
      setSelectedMedication({ ReactionType: 'Allergy', StatusType: '', Name: '', AllergenId: 1 });
      $('#medsAllergicModal').modal('hide');
    } else {
      toastError("Enter the name of the medication");
    }
  };

  const [value, setValue] = useState<ISelectProps | null>(null);

  const loadOptions: LoadOptions<
    ISelectProps,
    GroupBase<ISelectProps>,
    { page: number }
  > = async (search, loadedOptions, additional) => {
    const page = additional?.page;
    let data;
    let options = loadedOptions;

    if (search.length >= 3) {
      try {
        const response: ISearchResultDto = await searchMedicationAllergies(
          search,
          page || 1
        );
        const newOptions = response.items.map((item) => ({
          value: item.allergenId,
          label: item.name,
        }));
        options = newOptions;
        data = { newOptions, totalPages: response.totalPages };
      } catch (error) {
        console.log(error);
      }
    }

    return {
      options,
      hasMore: Boolean(page && data && data.totalPages > page),
      additional: {
        page: page ? page + 1 : 1,
      },
    };
  };

  return (
    <Form id="doseSpotForm">
      <Row form>
        <Col md={12}>
          <FormGroup>
            <Label for="medicationName">Name</Label>
            <Asterisk />
            <AsyncPaginate
              inputId="medicationName"
              required
              placeholder="Search for your medication"
              value={value}
              loadOptions={loadOptions}
              onChange={(selectedOption: ISelectProps | null) => {
                setValue(selectedOption);
                setSelectedMedication({
                  ...selectedMedication,
                  Name: selectedOption?.label || '',
                  AllergenId: selectedOption?.value || 1,
                });
              }}
              additional={{
                page: 1,
              }}
            />
            <FormFeedback>Name needs to be filled out</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Reaction Type</Label>
            <Asterisk />
            <Input
              type="select"
              required
              value={selectedMedication.ReactionType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSelectedMedication({
                  ...selectedMedication,
                  ReactionType: e.target.value,
                });
              }}
            >
              <option value="" disabled>Select Reaction Type</option>
              {AllergyReactionType.map((item, key) => (
                <option key={key} value={item.name}>
                  {item.name}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Reaction</Label>
            <Input
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSelectedMedication({
                  ...selectedMedication,
                  Reaction: e.target.value,
                });
              }}
            ></Input>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Status</Label>
            <Asterisk />
            <Input
              type="select"
              required
              value={selectedMedication.StatusType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSelectedMedication({
                  ...selectedMedication,
                  StatusType: e.target.value,
                });
              }}
            >
              <option value="" disabled>Select Status</option>
              {AllergyStatusType.map((status, key) => (
                <option key={key} value={status.name}>
                  {status.name}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Button htmlfor="doseSpotForm" color="success" onClick={onAddClick}>
        Add
      </Button>
    </Form>
  );
};
export default MedicalAllergiesDoseSpotForm;