import React from "react";
import { Document, Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import { IFullAppointment } from "../AppointmentModels";
import { convertToTimezoneWithUsState } from "../../../core/service/TimeZone";
import {
  IPatientInfo,
  IPatientMeasurment,
  IPrescription,
} from "../../../core/service/services";
import moment from "moment";
import { IFullAllergy } from "../../../components/medicalHistory/MedicationAllergiesDoseSpot";
import { SignatureFooter } from "../../dashboard/physician/NotesReport";

import TimesBold from "../../../../css/fonts/Times Bold.ttf";

interface IProps {
  data?: IFullAppointment;
  patientInfo?: IPatientInfo;
  physicianNotes?: string;
  followupNotes?: string;
  measurement?: IPatientMeasurment;
  symptoms?: { id: number, name: string }[],
  medications?: IFullAllergy[];
  conditions: number[];
  prescriptions: IPrescription[];
}

/** 
 * Although Times Bold font is supported out of the box in react-pdf/renderer,
 * We import the font file for packing the font in the generated PDF file
 * for fixing Safari IOS 16 issue 
 */
// Font.register({ family: "Times Bold", src: TimesBold });

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 50,
  },
  details: {
    display: "flex",
    fontSize: 13,
    marginBottom: 5,
    color: "#0c5fb8",
    fontFamily:'Times-Roman',

  },
  detailsTitle: {
    display: "flex",
    fontSize: 13,
    marginBottom: 5,
    fontFamily:'Times-Roman',

  },
  title: {
    fontSize: 13,
    marginBottom: 5,
    fontFamily:'Times-Roman',
    marginRight:5,
    fontWeight:'bold'
  },
  view: {
    display: "flex",
    flexDirection: "row",
  },

  table: { 
    display: "flex",
    width: "auto", 
    borderStyle: "solid", 
    borderWidth: 0.5, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol: { 
    width: "16.6%", 
    borderWidth: 0.5, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCell: { 
    margin: "auto", 
    marginTop: 5, 
    marginBottom: 5, 
    fontSize: 10 
  }
});

export const AppointmentDocument = (props: IProps) => {
  let data = props.data;
  let patientInfo = props.patientInfo;
  let conds = getConditions();
  let meds = getMedications();

  function getConditions  () {
    let con : string[]=[];
    props.symptoms?.map(item => {
      if (props.conditions.includes(item.id)) {
        con.push(item.name)
      }
    })
    return con.join(' , ')
  }

  function getMedications() {
    let meds : string[]=[];
    props.medications?.map(item => {
        meds.push(item.Name)
    })
    return meds.join(' , ')
  }
  const getMedicationAllergies = () => {
    return props.data?.who.patient.medicationAllergies?.map(s => s.Name).join(", ") || "None"
  }
  const getSelfReportedMedication = () => {
    return props.data?.who.patient.selfReportedMedications?.map(s => s.name).join(", ") || "None"
  }

  return (
    <Document>
      <Page style={styles.page} wrap>
        <View style={styles.view}>
          <Text style={styles.detailsTitle}>
            {` Appointment ID: ${props.data?.appointmentId ?? ""}`}
          </Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.detailsTitle}>
            {`Patient Name : ${patientInfo?.contact?.firstname ?? ""} ${
              patientInfo?.contact?.lastname ?? ""
            }  `}
          </Text>
          <Text style={styles.detailsTitle}>
            {` DOB : ${moment(patientInfo?.contact?.dateOfBirth ?? "").format(
              "MM/DD/YYYY"
            )} `}
          </Text>
          <Text style={styles.detailsTitle}>
            {` Gender : ${props.measurement?.gender?.toUpperCase() ?? ""}`}
          </Text>
        </View>
        <View style={styles.table}></View>
        <View
          style={{
            alignItems: "center",
            marginBottom: "5px",
            marginTop: "30px",
          }}
        >
          <Text style={{ fontSize: 13, fontWeight: "bold" }}>
            JME Medical service
          </Text>
        </View>
        <View style={{ alignItems: "center", marginBottom: "5px" }}>
          <Text style={{ fontSize: 13 }}>TelMDCare.com</Text>
        </View>
        <View style={{ alignItems: "center", marginBottom: "30px" }}>
          <Text style={{ fontSize: 13 }}>
            P.O BOX 577 42 Stephen St Lemont, IL 60439
          </Text>
          <Text
            style={{ fontSize: 13 }}
          >{`Phone: 1-800-230-2050 | Fax: 630-480-4567 | Support@TelMDCare.com `}</Text>
        </View>
        <View
          style={{
            alignItems: "center",
            marginBottom: "20px",
            marginTop: "5px",
          }}
        >
          <Text style={{ fontFamily: "Times-Roman" , fontWeight:'bold' }}>
            TelMDCare ONLINE CLINIC VISIT
          </Text>
        </View>
        <View>
          <Text style={styles.title}>DATE</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.details}>
            {data?.when?.time
              ? convertToTimezoneWithUsState(
                  data?.when.time.toString() || "",
                  data?.who.requestPatient.info.address.stateName ?? "",
                  "ddd MMMM Do YYYY"
                ) +
                " at " +
                convertToTimezoneWithUsState(
                  data?.when.time.toString() || "",
                  data?.who.requestPatient.info.address.stateName ?? "",
                  "h:mm a"
                )
              : ""}
          </Text>
        </View>
        <View style={{ marginTop: "10px" }}>
          <Text style={styles.title}>
            DEMOGRAPHICS AND ENCOUNTER INFORMATION
          </Text>
        </View>
        <View>
          <View>
            <View style={styles.view}>
              <Text style={styles.detailsTitle}>Patient:</Text>
              <Text style={styles.details}>
                {`${props.patientInfo?.contact?.firstname ?? ""}, ${
                  props.patientInfo?.contact?.lastname ?? ""
                }`}
              </Text>
            </View>
            {props.patientInfo?.address?.country &&
            props.patientInfo?.address?.city ? (
              <View style={styles.view}>
                <Text style={styles.detailsTitle}>Patient Location:</Text>
                <Text style={styles.details}>
                  {props.patientInfo?.address?.country ?? ""},{" "}
                  {props.patientInfo?.address?.city}
                </Text>
              </View>
            ) : (
              <></>
            )}
          </View>

          <View style={styles.view}>
            <Text style={styles.detailsTitle}> DOB: </Text>
            <Text style={styles.details}>
              {moment(patientInfo?.contact?.dateOfBirth ?? "").format(
                "DD/MM/YYYY"
              )}
              {"   "}
            </Text>
            <Text style={styles.detailsTitle}> Age: </Text>
            <Text style={styles.details}>
              {Math.abs(
                moment(patientInfo?.contact?.dateOfBirth ?? "").diff(
                  moment(),
                  "years"
                )
              )}
              {"   "}
            </Text>
            <Text style={styles.detailsTitle}> Gender: </Text>
            <Text style={styles.details}>
              {props.measurement?.gender === "f" ? "Female" : ""}
              {props.measurement?.gender === "m" ? "Male" : ""}
            </Text>
          </View>

          <View style={styles.view}>
            <Text style={styles.detailsTitle}>Visit Type: </Text>
            <Text style={styles.details}>
              {props.data?.contact.type ?? ""}
              {"   "}
            </Text>

            <Text style={styles.detailsTitle}>Ht:</Text>
            {props.measurement?.heightFt && props.measurement?.heightIn && (
              <Text style={styles.details}>
                {" "}
                {props.measurement?.heightFt}
                {" ft "}
                {props.measurement?.heightIn}
                {" in "}
                {props.measurement?.heightFt * 30.48 +
                  props.measurement?.heightIn * 2.54}
                {" cm   "}
              </Text>
            )}

            <Text style={styles.detailsTitle}>Wt:</Text>
            <Text style={styles.details}>
              {" "}
              {props.measurement?.weight ?? ""}
              {"   "}
            </Text>

            <Text style={styles.detailsTitle}>BMI:</Text>
            {props.measurement?.weight &&
              props.measurement?.heightFt &&
              props.measurement?.heightIn && (
                <Text style={styles.details}>
                  {(
                    703 *
                    (props.measurement?.weight /
                      Math.pow(
                        props.measurement?.heightFt * 12 +
                          props.measurement?.heightIn,
                        2
                      ))
                  ).toFixed(1)}
                </Text>
              )}
          </View>
        </View>
        <View style={{ marginTop: "10px" }}>
          <Text style={styles.title}>REASON FOR VISIT</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.detailsTitle}>
            {" "}
            {props.data?.why.reason ?? ""}
          </Text>
        </View>
        <View style={{ marginTop: "10px" }}>
          <Text style={styles.title}>PHYSICIAN NOTE</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.details}>{props.physicianNotes || "None"}</Text>
        </View>
        <View style={{ marginTop: "10px" }}>
          <Text style={styles.title}>MEDICAL CONDITIONS</Text>
        </View>
        <View style={styles.view}>
          {conds ? (
            <Text style={styles.details}>{conds}</Text>
          ) : (
            <Text style={styles.details}>None</Text>
          )}
        </View>

        {/* <View style={{marginTop:'10px'}}>
          <Text style={styles.title}>HOME MEDICATIONS</Text>
        </View>
        <View style={styles.view}>
        {meds ? <Text style={styles.details}>{meds}</Text>
            : <Text style={styles.details}>None</Text>}
          </View> */}

        <View style={{ marginTop: "10px" }}>
          <Text style={styles.title}>MEDICATION ALLERGY</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.details}>{getMedicationAllergies()}</Text>
        </View>
        <View style={{ marginTop: "10px" }}>
          <Text style={styles.title}>SELF REPORTED MEDICATIONS</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.details}>{getSelfReportedMedication()}</Text>
        </View>

        <View style={{ marginTop: "10px" }}>
          <Text style={styles.title}>TREATMENT PLAN</Text>
          {props.prescriptions.length > 0 ? (
            <>
              <Text style={styles.detailsTitle}>Medication prescription:</Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>RXID</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Medication</Text>
                  </View>

                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Dispense</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Refills</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Direction</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Pharmacy Notes</Text>
                  </View>
                </View>
                {props.prescriptions?.map((prescription) => (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {prescription.medicationId}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {prescription.medicationName}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {prescription.dispense}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {prescription.refills}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {prescription.directionsForUse}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {prescription.pharmacyNotes}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </>
          ) : (
            <Text style={styles.details}>None</Text>
          )}
        </View>
        <View style={{ marginTop: "10px" }}>
          <Text style={styles.title}>Follow-up</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.details}>{props.followupNotes || "None"}</Text>
        </View>
        {props.data?.physician && (
          <View style={{ marginTop: "10px" }}>
            <Text style={styles.title}>Physician:</Text>
            <Text style={styles.details}>{props.data?.physician.name}</Text>
            {props.data?.physician.npi && (
              <>
                <Text style={styles.title}>NPI: </Text>
                <Text style={styles.details}>{props.data?.physician.npi}</Text>
              </>
            )}
          </View>
        )}
        <View
          style={{
            alignItems: "center",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <Text style={{ fontFamily: "Times-Roman" , fontWeight:'bold'}}>
            Online providers can only initiate your treatment but are unable to
            follow up on your treatment progress. Print a copy of your visit
            today and send it to your primary doctor within 48-72 hours.
          </Text>
        </View>
        {/* {signatureFooter(props.data, props.data?.who.requestPatient.info)} */}
        <SignatureFooter
          data={data}
          patient={props.data?.who.requestPatient.info}
        />
        <View style={styles.view}>
          <Text style={styles.title}>
            Evaluation provided via real-time conference on interactive audio
            and/or video telecommunications systems.
          </Text>
        </View>
      </Page>
    </Document>
  );
};
