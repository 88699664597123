import * as React from 'react';
import { Routes } from '../../config/Routes';
import { Link, navigate } from '@reach/router';
import { UserConfig } from '../../config/userConfig';
import { IBookingProps, IFullAppointment } from './AppointmentModels';
import {
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { convertToTimezoneWithUsState } from '../../core/service/TimeZone';
import { SideCardFrame } from '../../components/layout/SideCardFrame';
import { navItem } from '../../App';
import { useAppDispatch, useAppSelector } from './../../redux/hooks';
import {
  selectCreatedAppointment,
  selectPaymentAmount,
  selectTransactionFee
} from './../../redux/slices/booking/bookingSlice';
var NoScript = require('react-noscript');

export const AppointmentSummary = ({
  smallCard
}: IBookingProps) => {
  const paymentAmount: number | undefined = useAppSelector(selectPaymentAmount)
  const transactionFee: number | undefined = useAppSelector(selectTransactionFee)
  const createdAppointment: IFullAppointment | undefined = useAppSelector(selectCreatedAppointment)

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderFlowNav = () => {
    return (
      <div className="flow-nav-tools">
        <Button 
        color="primary" 
        size="lg" 
        className="control-nav mr-auto"
        onClick={() => {
          navigate(Routes.homePatient)
        }}
        >
          Go Back Home
        </Button>
      </div>
    );
  };

  const renderVisitType = () => {
    if (createdAppointment?.why.consultationType) {
      return (
        <Col sm="6" className="mb-3">
          <dl>
            <dt>Visit Type:</dt>
            <dd>
              {createdAppointment?.why.consultationType}
            </dd>
          </dl>
        </Col>
      )
    }
  }

  const renderAppointmentReason = () => {
    if (createdAppointment?.why.reason) {
      return (
        <Col sm="6" className="mb-3">
          <dl>
            <dt>Appointment Reason:</dt>
            <dd>{createdAppointment?.why.reason}</dd>
          </dl>
        </Col>
      )
    }
  }

  const renderSymptoms = () => {
    if (createdAppointment?.why.symptoms) {
      return (
        <Col sm="6" className="mb-3">
          <dl>
            <dt>Symptoms:</dt>
            <dd>{createdAppointment?.why.symptoms}</dd>
          </dl>
        </Col>
      )
    }
  }

  const appointment = createdAppointment ? `${convertToTimezoneWithUsState(
    createdAppointment.when.time,
    createdAppointment.who.requestPatient.info.address.stateName,
    "ddd MMMM Do YYYY h:mm a"
  )} ${createdAppointment.who.requestPatient.info.address.stateName} time` : '';

  return (
    <div>
      <NoScript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-5ZNWKKH"
          height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}>
        </iframe>
      </NoScript>

      <SideCardFrame flowNav={renderFlowNav()} navItem={navItem} bodyClassName="tc-bg" smallCard={smallCard}>
        <CardBody>
          <div className="d-flex px-2">
            <h5 className="mb-0">You have successfully booked an Appointment!</h5>
            <Link
              to={Routes.homePatient}
              className="btn btn-link btn-sm ml-auto"
            >
              Go to Home Page
            </Link>
          </div>
          <hr className="mt-2 mb-4"></hr>
          <CardTitle>
            Hey {UserConfig.getUserInfo()?.firstname}! You have successfully scheduled an
            appointment!{' '}
            <span className="d-block mt-4">Here are the details:</span>
          </CardTitle>
          <h6 className="mb-3">Appointment Number: {createdAppointment?.appointmentId}</h6>
          <Row className="ml-2">
            {renderVisitType()}
            <Col sm="6" className="mb-3">
              <dl>
                <dt>Contact Type:</dt>
                <dd>{createdAppointment?.contact.type}</dd>
              </dl>
            </Col>
            <Col sm="6" className="mb-3">
              <dl>
                <dt>Patient Name:</dt>
                <dd>{createdAppointment?.who.patientName}</dd>
              </dl>
            </Col>
            <Col sm="6" className="mb-3">
              <dl>
                <dt>Phone Number:</dt>
                <dd>{createdAppointment?.contact.phone}</dd>
              </dl>
            </Col>
            {renderAppointmentReason()}
            {renderSymptoms()}
            <Col sm="6" className="mb-3">
              <dl>
                <dt>Date and Time:</dt>
                <dd>
                  <span className="day3"></span>{' '}
                  {appointment}
                </dd>
              </dl>
            </Col>
            {paymentAmount && transactionFee &&
              <Col sm="6" className="mb-3">
                <dl>
                  <dt>Total Payment:</dt>
                  <dd>{"$"}{paymentAmount + transactionFee}</dd>
                </dl>
              </Col>
            }
          </Row>
          <hr />
        </CardBody>
      </SideCardFrame>
    </div>
  );
}

