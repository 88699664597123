import { useState } from "react";
import { Service } from "../types/Service";

export const useGenericServiceCall = <OutPut, Input = unknown, ErrorType = unknown>(
  url: string,
  method: "POST" | "GET",
  tokenProvider?: () => string | null,
  tokenUpdater?: (token: string) => {},
  allowedHttpCustomErrorCodes?: number[] | null
) => {
  const [service, setService] = useState<Service<OutPut, ErrorType>>({
    status: "init",
  });

  const callService = (data: Input) => {
    setService({ status: "loading" });

    const headers = new Headers();
    const token = tokenProvider && tokenProvider();
    headers.append("Content-Type", "application/json; charset=utf-8");
    if (token) {
      headers.append("authorization", "Bearer " + token);
    }
    // debugger;
    return new Promise<void>((resolve, reject) => {
      const body = data && JSON.stringify(data);
      fetch(url, {
        method: method,
        body: body,
        headers,
      })
        .then(async (response) => {

          const newToken = response.headers.get("patientAccessToken");
          newToken && tokenUpdater && tokenUpdater(newToken);

          if ((allowedHttpCustomErrorCodes || []).includes(response.status)) {
            setService({
              status: "custormError",
              errorResponse: await response.json()
            });

            reject();
            return;
          }

          if (response.status == 400) {
            setService({
              status: "validationError",
              items: await response.json(),
            });
            return reject();
          } else if (response.status > 400) {
            const error = new Error(
              `status code: ${response.status} returned from server`
            );
            setService({ status: "error", error });
            return reject(error);
          }

          setService({ status: "loaded", payload: await response.json() });
          return resolve();
        })
        .catch((error) => {
          if (error) {
            console.log(error);
            setService({ status: "error", error });
            reject(error);
          }
        });
    });
  };

  return {
    service,
    callService,
  };
};

// export useGenericServiceCall;
