import React from 'react';
import { RouteComponentProps, navigate} from "@reach/router";
import Footer from "../../../components/layout/Footer";
import TCNavBar from "../../../components/layout/TCNavBar";
import { IUserInfo, UserConfig } from "../../../config/userConfig";
import { Container, Row, Col, Card, CardBody, CardTitle, Alert } from "reactstrap";
import { PhysicianAppointments } from './PhysicianAppointments';
import { PhysicianAvailability } from './PhysicianAvailability';
import { Routes } from '../../../config/Routes';
import { getPhysicianAvailability, IAppointmentUpdatedMessage } from '../../../core/service/services';
import { IMessage } from '../../chat-module/MessageModel';
import { initSocket } from '../../../core/socket/SocketInitializer';
import { ChatModule } from '../../chat-module/ChatModule';
import { navItem } from '../../../App';

interface IProps extends RouteComponentProps {
  // children?: React.ReactNode
}

interface IState {
  userInfo?: IUserInfo | null,
  available: boolean,
  isChatOpened: boolean;
  appointmentChat?: {appointmentId?: string, receiverName: string},
  addedMessage?: IMessage,
  appointmentToUpdate? : {id: string, count: number},
  accountDisabled: "Enabled" | "Disabled" | "Pending"
}

export class HomePhysician extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = { 
      userInfo: UserConfig.getUserInfo(),
      available: false,
      isChatOpened: false,
      accountDisabled: "Enabled"
    };
    this.setAvailability = this.setAvailability.bind(this)

  }

  componentWillMount() {
    getPhysicianAvailability().then(res => {
      this.setState({
        available: res.available
      })
    }).catch(err => {
      console.log(err)
      if (err.json?.message == "Physician account is disabled."){
        this.setState({accountDisabled: "Disabled"})
      } else{
        this.setState({accountDisabled: "Pending"})
      }
      
    })
    initSocket(this.onMessageReceived, this.onUpdateAppointmentReceived)
  }

  onUpdateAppointmentReceived = (msg: IAppointmentUpdatedMessage) => {
    if (this.state.appointmentToUpdate && this.state.appointmentToUpdate.id === msg.appointmentId) {
      this.setState({
        appointmentToUpdate: {id: msg.appointmentId, count: this.state.appointmentToUpdate.count + 1}
      })
    } else {
      this.setState({
        appointmentToUpdate: {id: msg.appointmentId, count: 0}
      })
    }
  }
  

  setAvailability(available: boolean) {
    this.setState({
      available: available
    })
  }

  onChatOpened = (appointementId: string, receiverName: string) => {
    this.setState({ 
      isChatOpened: true,
      appointmentChat: {appointmentId: appointementId, receiverName: receiverName},
      addedMessage:undefined
    })
  }

  toggleChat = () => {
    this.setState({ isChatOpened: !this.state.isChatOpened })
  }

  onMessageReceived = (message:IMessage) => {
    if(this.state.isChatOpened){
      message.appointmentId=undefined
    }
    this.setState({addedMessage: message})
  }

  render() {

    const renderUpdateTemplatesButton = () => (
      <Card className="card-telecare btn-responsive-4 my-5">
        <CardBody className="btn btn-primary" onClick={e => navigate(Routes.physicianTemplates)}>
          <span className="features-icons-icon d-inline-block "><i className="fa fa-copy  mb-3"></i></span>
          <CardTitle className="mb-0">Update Templates</CardTitle>
        </CardBody>
      </Card>
    )

    const renderUpdateSettingsButton = () => (
      <Card className="card-telecare btn-responsive-5 my-5">
        <CardBody className="btn btn-primary" onClick={e => navigate(Routes.physicianSettings)}>
          <span className="features-icons-icon d-inline-block "><i className="fa fa-cogs  mb-3"></i></span>
          <CardTitle className="mb-0">Update Settings</CardTitle>
        </CardBody>
      </Card>
    )

    const renderInfoCard = () => (
      <Card className="card-telecare mb-5">
        <CardBody>
          <section className="section-content-block">
            <div className="section-content-header">
              <h5 className="text-muted"><i className="fa fa-info-circle"></i>{" "}<span>How it Works</span></h5>
            </div>
            <div className="section-content-body">
              <ol className="ml-0 pl-3 text-muted">
                <li className="mb-3">
                  <strong className="d-block">Fill Out Physician Info:</strong>
                  Enter your availability and settings so patients can schedule a visit.
                </li>
                <li className="mb-3">
                  <strong className="d-block">Updates and Notifications:</strong>
                  Once patients sign up you will be notified and updated. Contact patients before or after your visit.
                </li>
                <li className="mb-3"><strong className="d-block">Video Conferencing w/ Patient:</strong>
                  Meet directly with patients via video conferencing software or schedule a phone conversation.
                </li>
              </ol>
            </div>
          </section>
        </CardBody>
      </Card>
    )

    return (
      <>
        <TCNavBar bodyClassName="tc-bg">{navItem}</TCNavBar>
        <ChatModule appointmentId={this.state.appointmentChat?.appointmentId} modalOpen={this.state.isChatOpened} addedMessage={this.state.addedMessage} 
            onMessageReceived={this.onMessageReceived} toggleChatModal={this.toggleChat} receiverName={this.state.appointmentChat?.receiverName || ""}/>

        <Container className="patient-list">
          <Row>
            {(this.state.accountDisabled == "Enabled") &&
              <Col lg="8" xl="8">
                <Card className="card-telecare my-5">
                  <CardBody>
                      <CardTitle className="mb-0">
                        {"Welcome " + ((this.state.userInfo?.visitCount || 0 <= 1) && "back, ") }<span className="currentUserName">{this.state.userInfo?.firstname || " "}</span>
                        ! Update your availability or review your upcoming and past appointments based on your list below.
                      </CardTitle>
                  </CardBody>
                </Card>
                <PhysicianAvailability available={this.state.available} setAvailability={this.setAvailability}/>
                <PhysicianAppointments available={this.state.available}  onChatOpened={this.onChatOpened} onMessageReceived={this.onMessageReceived} 
                  addedMessage={this.state.addedMessage} appointmentToUpdate={this.state.appointmentToUpdate}/>
              </Col>
            }
            <Col lg={ this.state.accountDisabled == "Disabled" || this.state.accountDisabled == "Pending"  ? "12" : "4"} xl={this.state.accountDisabled == "Disabled" || this.state.accountDisabled == "Pending"? "12" : "4"}>
              {this.state.accountDisabled == "Pending" &&<Alert className="mt-4" color="danger">Pending system administrator approval!</Alert>}

              {this.state.accountDisabled == "Disabled" &&<Alert className="mt-4" color="danger">Physician account is disabled.</Alert>}
              <Row>
                <Col sm="12" md="6">
                  {renderUpdateTemplatesButton()}
                </Col>
                <Col sm="12" md="6">
                  {renderUpdateSettingsButton()}
                </Col>
                <Col md="12">
                  {renderInfoCard()}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}
