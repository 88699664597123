import React from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown
} from "reactstrap";
interface IProps {
  gender?:string;
  returnedValue?:any;
}
const CustomedDropdown = (props: IProps) => {
  return (<UncontrolledButtonDropdown>
  <DropdownToggle>{props.gender || "Set Gender"}</DropdownToggle>
  <DropdownMenu>
    <DropdownItem onClick={(e) => {
      e.preventDefault();
      props.returnedValue('m')
    }}>Male</DropdownItem>
    <DropdownItem onClick={(e) => {
      e.preventDefault();
      props.returnedValue('f')
    }} >Female</DropdownItem>

  </DropdownMenu>
</UncontrolledButtonDropdown>)
};
export default CustomedDropdown;
