import * as React from 'react';
import { CardBody } from 'reactstrap';
import { convertToTimezoneWithUsState } from '../../core/service/TimeZone';
import { useAppSelector } from './../../redux/hooks';
import { selectConsultationType } from './../../redux/slices/booking/consultationStepSlice';
import { ConsultationType, ContactType, ScheduledTimeType } from '../../redux/types/enums/bookingEnums';
import { selectContactType, selectScheduledTimeType, selectDate } from './../../redux/slices/booking/timeAndContactTypeStepSlice';
import { selectAddress } from './../../redux/slices/general/patientInfoSlice';
import { IAddress } from './../../redux/types/interfaces/patientInfoInterfaces'

interface IProps {}

export const AppointmentDetails = (props: IProps) => {
  const consultationType: ConsultationType | undefined = useAppSelector(selectConsultationType)
  const contactType: ContactType | undefined = useAppSelector(selectContactType)
  const scheduledTimeType: ScheduledTimeType | undefined = useAppSelector(selectScheduledTimeType)
  const date: Date | undefined = useAppSelector(selectDate)
  const address: IAddress | undefined = useAppSelector(selectAddress)

  const renderConsultationType = () => {
    if (consultationType) {
      return (
        <h6 className="card-title">
          <small className="d-block">
            Visit Type:
            
          </small>{' '}
          <div>{consultationType}</div>
        </h6>
      );
    }
  };

  const renderContactType = () => {
    if (contactType) {
      return (
        <h6 className="card-title">
          <small className="d-block">
            Contact Type: 
          </small>{' '}
          <div>{contactType}</div>
        </h6>
      );
    }
  };

  const renderApptTime = () => {
    if (scheduledTimeType) {
      let value = "";
      if (scheduledTimeType === ScheduledTimeType.Now) {
        value = ScheduledTimeType.Now
      } else if(date) {
        value = address?.stateName ? `${convertToTimezoneWithUsState(date, address.stateName, "ddd MMMM Do YYYY h:mm a")} ${address.stateName} time` : '';
      }
      else {
        return
      }
      return (
        <h6 className="card-title">
          <small className="d-block">
            Date and Time Range: 
          </small>{' '}
          <span className="day3"></span>
          <div>{value}</div>
        </h6>
      );
    }
  };

  return (
    <CardBody className="card-body">
      <h5 className="mb-0">Appointment Details:</h5>
      <hr className="mb-3"></hr>
      <div>{renderConsultationType()}</div>
      <div>{renderContactType()}</div>
      <div>{renderApptTime()}</div>
    </CardBody>
  );
};
