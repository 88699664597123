import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { SmallCardFrame } from '../../components/layout/SmallCardFrame';
export const DuplicateRegistryEmail: React.FC<RouteComponentProps> = (props) => {
	const [message, setMessage] = useState('');
	useEffect(()=>{
		let stateMessage = (props?.location?.state as any).message || '';
		setMessage(stateMessage);
	},[])
	return (
		<SmallCardFrame navItem={null} bodyClassName="tc-bg" >
			<div className="card-body">
				<div className="pre-password-reset">
					<h5 className="card-title text-muted mb-1">Registeration is not done <i className="fa fa-exclamation-circle fa-1x"></i></h5>
					<p>{message}</p>
					<hr />
				</div>

			</div>
		</SmallCardFrame>
	)
}