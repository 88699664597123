import * as React from 'react'
import MedicalAllergiesDoseSpotForm, { IAddAllergy } from '../../../components/medicalHistory/MedicationAllergiesDoseSpot'
import { CustomInput } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  medicationAllergyAdded,
  hasMedicationAllergiesBooleanChanged,
  selectHasMedicationAllergies
} from '../../../redux/slices/general/medicalProfileSlice';

interface IMedicationsModalProps {
  closeModal: () => void
}

export const MedicationsModalRedux: React.FC<IMedicationsModalProps> = ({
  closeModal
}: IMedicationsModalProps) => {
  const hasMedicationAllergies = useAppSelector(selectHasMedicationAllergies)
  const [newHasMedicationAllergies, setNewHasMedicationAllergies] = React.useState<boolean>(hasMedicationAllergies)

  const dispatch = useAppDispatch()

  const handleSelectingYes = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setNewHasMedicationAllergies(true)
    }
  }

  const handleSelectingNo = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setNewHasMedicationAllergies(false)
      dispatch(hasMedicationAllergiesBooleanChanged(false))
      closeModal()
    }
  }

  const savePressed = (allergy: IAddAllergy) => {
    dispatch(hasMedicationAllergiesBooleanChanged(newHasMedicationAllergies))
    dispatch(medicationAllergyAdded(allergy))
    closeModal()
  }

  const cancelPressed = () => {
    closeModal()
  }

  const renderForm = () => {
    if (newHasMedicationAllergies) {
      return (
        <>
          <MedicalAllergiesDoseSpotForm setNewMedication={savePressed} />
        </>
      )
    }
  }

  return <>
    <div id="medsAllergicModal" data-tabindex="-1" role="dialog" aria-labelledby="medsAllergicModalLabel"
      className="modal fade show" aria-modal="true" style={{ paddingRight: "15px", display: "block" }}>
      <div role="document" className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 id="medsAllergicModalLabel" className="modal-title">Medication Allergies</h5> <button type="button"
              data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true"
                onClick={cancelPressed}
              >×</span></button>
          </div>
          <div className="modal-body">
            <p>Are you allergic to any medication?</p>
            <CustomInput
              onChange={handleSelectingYes}
              type="radio"
              id="yes"
              name="intro"
              label="Yes"
              defaultChecked={hasMedicationAllergies}
            />
            <CustomInput
              onChange={handleSelectingNo}
              type="radio"
              id="no"
              name="intro"
              label="No"
              defaultChecked={!hasMedicationAllergies}
            />
            {renderForm()}
          </div>
        </div>
      </div>
    </div>
    <div className="modal-backdrop fade show"></div>
  </>
}