import React from "react";
import { Row, Col, Container, Card, Form, Button } from "reactstrap";
import PatientSettingsBody from "./PatientSettingsBody";
import TCNavbar from "../layout/TCNavBar";
import { RouteComponentProps, Link, navigate } from "@reach/router";
import { IUserInfo } from "../../config/userConfig";
import { Routes } from "../../config/Routes";
import { navItem } from "../../App";

interface IProps extends RouteComponentProps { }
interface IState {
  userInfo?: IUserInfo | null;
}

export interface IDPatient {
  id?: number;
  gender?: "m" | "f";
  firstname?: string;
  middlename?:string;
  lastname?: string;
  relationshipToDependable?: string;
  dateOfBirth?: string;
  pregnant?: boolean | false;
  breastfeeding?: boolean | false;
}

export interface IPatientContactSettings {
  firstname?: string;
  lastname?: string;
  phone?: string;
  email?: string;
}
export interface IPatientInfoSettings {
  contact?: {
    firstname?: string;
    lastname?: string;
    middlename?:string;
    phone?: string;
    email?: string;
    dateOfBirth?: Date | string;
    subscribed?: boolean
  };
  address?: {
    city?: string;
    description?: string;
    addressLine2?: string;
    stateId?: number;
    zipCode?: string;
    country?: string;
  };
  pharmacy?: {
    id?: string;
    address?: string;
    name?: string;
    phone?: string;
  };
  dependents?: {
    // just making the id mandatory
    current?: ({ id: number } & IDPatient)[];
    add?: IDPatient[];
    modify?: IDPatient[];
    remove?: number[];
  };
  insurance?: {
    id: string;
    payerCode: string;
    payerName: string;
  };
}
export interface ISearchPharmacyParams {
  pharmacyId?: string;
  name?: string;
  city?: string;
  state?: string;
  zip?: string;
  address?: string;
  phoneOrFax?: number;
  specialty?: string;
  ncpdpID?: string;
  pageNumber?:number;
  // National Council for Prescription Drug Programs
}
export interface IPharmacySearch {
  id: string;
  name: string;
  address: string;
  phone: string;
  city: string;
  zip: string;
  state: string;
  specialty: string;
  pharmacyId: string;
}
export const PatientSettings: React.FC<IProps> = (props) => {
  return (
    <div>
      <TCNavbar bodyClassName="tc-bg">{navItem}</TCNavbar>
      <Container id="patientSettings">
        <Form>
          <Row form>
            <Col lg="8" xl="8" className="offset-lg-2 offset-xl-2">
              <Card className="card-telecare main my-lg-5 my-xs-2 my-sm-2 my-md-2">
                <PatientSettingsBody />
              </Card>
              <div className="flow-nav-tools">
                <Button size="lg" color="primary" onClick={() => navigate(Routes.homePatient)} className="control-nav ml-auto">Back</Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};
