// import * as conf from './conf.json'

declare global {
    class ApplicationConfig {
        static baseUrl: string;
        static socketBaseUrl: string;
        static socketPath: string;
    }
}

export const appConfig = {
    baseUrl: ApplicationConfig.baseUrl,
    socketBaseUrl: ApplicationConfig.socketBaseUrl,
    socketPath: ApplicationConfig.socketPath 
}


