import * as React from "react";
import { Link } from "@reach/router";

interface EmptyButtonProps extends React.Props<{}> {
  to: string;
  label: string;
}

interface EmptyButtonState {}

export class EmptyButton extends React.Component<
  EmptyButtonProps,
  EmptyButtonState
> {
  constructor(props: EmptyButtonProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Link
        to={this.props.to}
        className="btn btn-sm btn-block btn-outline-primary mt-2"
        >
       {this.props.label}
      </Link>
    );
  }
}
function newFunction() {
  return '';
}

