import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { SmallCardFrame } from '../../components/layout/SmallCardFrame';
import _ from "lodash"
import { Email, resendActivationEmail } from '../../core/service/services';


export const SendActivationEmail: React.FC<RouteComponentProps> = (props) => {

    React.useEffect(()=>{
        sendActivationEMail()
    },[])
    const sendActivationEMail =()=>{
        let email: Email = { email: localStorage.getItem('email') };
        resendActivationEmail(email)
    }
    
    return (
        <SmallCardFrame navItem={null} bodyClassName="tc-bg" >
            <div className="card-body">
                <div className="pre-password-reset">
                    <p className="card-title  mb-1" style={{ textAlign: "center" }}>
                        <strong>
                            {!(props.location?.state as any)?.signUp &&
                                <>
                                    Your account is currently inactive. <br /> We have just sent you an activation link on your email.
                                </>
                            }
                        </strong>
                    </p>
                </div>
            </div>
        </SmallCardFrame>

    )
}