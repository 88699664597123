import React, { useEffect, useState } from "react";
import { ListGroup, CardTitle, ListGroupItem } from "reactstrap";
import { AppointmentListingItemCard } from "../appointment/AppointmentListingItemCard";
import { getWaitingAppointments, getAppointmentInfoForPhysician, getPhysicianTimeBeforeLock } from "../../../core/service/services";
import { IAppointmentInfo } from "../../book-appointment/AppointmentModels";
import { IMessage } from "../../chat-module/MessageModel";
import { toastInfo } from "../../../App";
import moment from "moment";

interface IProps {
  availability: boolean
  onChatOpened: (appointmentId: string, receiverName: string) => void;
  addedMessage?: IMessage,
  onMessageReceived?: (msg: IMessage) => void,
    updatedAppointment?: {id: string, count: number}

}

export const AppointmentsWaitingArea: React.FC<IProps> = props => {

  const [waitingAppointments, setWaitingAppointments] = useState<IAppointmentInfo[]>([])
  const [lockedAppointment, setLockedAppointment] = useState<IAppointmentInfo>()
  const [available, setAvailable] = useState<boolean>(false)
  const [timeBeforeLock, setTimeBeforeLock] = useState<number>(15)

  const getData = () => {
    getWaitingAppointments().then(res => {
      setWaitingAppointments([...res.locked, ...res.items])
      setLockedAppointment([...res.locked, ...res.items].filter(appointment => (appointment.status === "Locked" || appointment.status === "Started"))[0])
    }).catch(err => console.log(err))
  }

  const renderHeader = () => (
    <div className="section-content-header d-flex flex-nowrap">
      <div className="order-1 mr-auto">
        <h5 className="text-muted">
          <span>Waiting Area</span>
        </h5>
      </div>
    </div>
  )

  useEffect(() => {
    setAvailable(props.availability)
  }, [props.availability])

  useEffect(() => {
    getData()
    setInterval(getData, 300000)
    getPhysicianTimeBeforeLock().then(res => {
      setTimeBeforeLock(res.lockAllowedBeforeScheduleTimeMinuets)
    }).catch(err => console.log(err))
  }, [])

  useEffect(() => {
    if (props.updatedAppointment) {
      getAppointmentInfoForPhysician(props.updatedAppointment.id).then(res => {
        updateItem(res)
      }).catch(err => {
        //appointment should be removed
        removeItem(props.updatedAppointment?.id || "")
      })
    }
  }, [props.updatedAppointment])

  const removeItem = (itemId: string) => {
    let itemsList = waitingAppointments
    const index = getAppointmentIdx(itemId);
    if (index === -1) {
      return
    }
    itemsList.splice(index, 1)
    //bad implementation .. to be enhanced
    setWaitingAppointments([])
    setWaitingAppointments(itemsList)
  }

  const updateItem = (newItem: IAppointmentInfo) => {
    let itemsList = waitingAppointments
    const index = getAppointmentIdx(newItem.appointmentId);
    if (index === -1) { //new appointment
      itemsList.push(newItem)
      toastInfo("New Appointment requested in waiting area!")
    } else {
      itemsList[index] = newItem
    }
    //bad implementation .. to be enhanced
    setWaitingAppointments([])
    setWaitingAppointments(itemsList)
  }

  const getAppointmentIdx = (appointmentId: string) => {
    for (let i = 0; i < waitingAppointments.length; i++) {
      const currentItem = waitingAppointments[i]
      if (currentItem.appointmentId === appointmentId) {
        return i
      }
    }
    return -1
  }

  const appItemStyle = (status: string) => {
    let style = {borderTopWidth: "thin", borderColor: "#007bff"}
    if (status === "Locked" || status === "Started") {
      style.borderColor = "green"
    } else if (!available) {
      style.borderColor = "gray"
    }
    return style
  }

  const nowAppts: IAppointmentInfo[] = []
  const scheduledAppts: IAppointmentInfo[] = []
  const now = new Date()
  for (let appt of waitingAppointments) {
    if (appt.isNow || moment(appt.time).isBefore(now)) {
      nowAppts.push(appt)
    } else {
      scheduledAppts.push(appt)
    }
  }

  return (
    <>
      {renderHeader()}
      <div>
        {waitingAppointments.length > 0 ?
        <>
        {nowAppts.length > 0 &&
        <>
        <h6 className="text-muted ml-3">
          <span>Immediately Available</span>
        </h6>
        <ListGroup className="timeline upcoming pre-appt-scheduled">
          {nowAppts.map(item => (
            <ListGroupItem style={appItemStyle(item.status)} key={item.appointmentId}>
              <AppointmentListingItemCard appointment={item} hasLockedAppointment={lockedAppointment != undefined} 
                disabled={!available && item.status != "Locked" && item.status != "Started"} timeBeforeLock={timeBeforeLock}
                onChatOpened={props.onChatOpened} onMessageReceived={props.onMessageReceived} addedMessage={props.addedMessage} refresh={getData}/>
            </ListGroupItem>
          ))}
        </ListGroup>
        <hr />
        </>
        }
        {scheduledAppts.length > 0 &&
        <>
        <h6 className="text-muted ml-3">
          <span>Upcoming Soon</span>
        </h6>
        <ListGroup className="timeline upcoming pre-appt-scheduled">
          {scheduledAppts.map(item => (
            <ListGroupItem style={appItemStyle(item.status)} key={item.appointmentId}>
              <AppointmentListingItemCard  appointment={item} hasLockedAppointment={lockedAppointment != undefined} 
                disabled={!available && item.status != "Locked" && item.status != "Started"} timeBeforeLock={timeBeforeLock}
                onChatOpened={props.onChatOpened} onMessageReceived={props.onMessageReceived} addedMessage={props.addedMessage} refresh={getData}/>
            </ListGroupItem>
          ))}
        </ListGroup>
        </>
        }
        </>
        :
        <CardTitle className="mb-3 pl-3">There are no current appointments in your eligible states.</CardTitle>
        }
      </div>
    </>
  );
};
