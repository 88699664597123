import React, { useEffect } from "react";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import { IFullSelfReportedItem, listSelfReportedMedications } from "../../../core/service/services";
import { SelfReportedMedicationsModalRedux } from "./SelfReportedMedicationsModalRedux";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selfReportedMedicationsUpdated,
  selfReportedMedicationRemoved,
  selectSelfReportedMedications
} from '../../../redux/slices/general/medicalProfileSlice';
import { selectDependent } from '../../../redux/slices/booking/choosePatientStepSlice'
import { ISelfReportedItem } from "../../../redux/types/interfaces/medicalProfileInterfaces";
import { IDependent } from "../../../redux/types/interfaces/patientInfoInterfaces";

export interface IProps {
  isLoading?: boolean
}

export const SelfReportedMedicationsRedux: React.FC<IProps> = (props) => {
  const selfReportedMedications: Array<IFullSelfReportedItem> = useAppSelector(
    selectSelfReportedMedications
  );
  const dependent: IDependent | undefined = useAppSelector(selectDependent);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const dispatch = useAppDispatch()

  useEffect(() => {
    listSelfReportedMedications(dependent?.id).then((res) => {
      const arr: IFullSelfReportedItem[] = [];
      res.forEach((element) => {
        element.id = element.id;
        element.name = element.name;
        element.strength = element.strength;
        arr.push(element);
      });
      dispatch(selfReportedMedicationsUpdated(arr))
    })
  }, [])

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleRemove = (medication: IFullSelfReportedItem) => {
    dispatch(selfReportedMedicationRemoved(medication));
  };

  const renderSelfReportedMedications = () => {
    if (selfReportedMedications.length > 0) {
      return (
        <ListGroup style={{ maxHeight: 300, overflow: "auto" }}>
          {selfReportedMedications.map((medication) => {
            return (
              <>
                <ListGroupItem>
                  <span> {medication.name}</span>
                  <Button color="link" className="text-muted ml-auto" onClick={() => { handleRemove(medication) }} style={{ float: "right" }}>
                    <span className="sr-only">Remove</span>{" "}
                    <i className="fa fa-trash"></i>
                  </Button>
                </ListGroupItem>
              </>
            );
          })}
        </ListGroup>
      )
    }
    return (
      <span>None</span>
    )
  }

  return (
    <>
      <h6 className="mb-3">
        Self Reported Medications
        <Button disabled={props.isLoading === true} onClick={toggleModal} color="link" size="sm">
          Add
        </Button>
      </h6>
      <div>
        <ul className="list-group list-group-flush">
          {renderSelfReportedMedications()}
        </ul>
      </div>
      <SelfReportedMedicationsModalRedux
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
      />
    </>
  );
};
