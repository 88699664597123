import React from 'react';
import { Link, navigate } from "@reach/router";
import { Button, UncontrolledTooltip } from 'reactstrap';
import { logInAsPatient } from '../../../context/UserContext';
import { Routes } from '../../../config/Routes';
import { logInAsPaient } from '../../../core/service/services';
import { toastError } from '../../../App';
import moment from 'moment';
import { appendPrefix } from '../../../core/service/PatientIdPrefixGenerator';

interface IProps {
  patient: any,
  setAgentSession: any
}
export const PatientItemCard: React.FC<IProps> = (props: IProps) => {

  const female_patient = require('../../../../img/female-patient.webp');
  const generateImage = () => {
    return female_patient;
  }

  const logInAgentAsPatient = async () => {
    let user = await logInAsPaient({ id: props.patient.id, firstName: props.patient.firstName, lastName: props.patient.lastName }, props.patient.isUser)
      .catch((error) => {
        toastError(error);
      })
    props.setAgentSession()
    logInAsPatient(user.patient);
    if (user.visitCount && user.visitCount <= 1)
      navigate(Routes.quick);
    else
      navigate(Routes.homePatient);
  }
  const renderTitle = () => {
    return (
      <Link className="mr-auto" to="" style={{
        display: 'inline-block',
        pointerEvents: 'none'
      }}>
        <img src={generateImage().default} className="avatar" />{"  "}
        {props.patient.firstName + " " + props.patient.lastName}
      </Link>
    )
  }

  const call = (e: React.MouseEvent) => {
    navigate("tel:67" + props.patient.phone)
  }
  const renderInfo = () => (
    <ul className="list-inline text-muted mb-1">
      <li className="list-inline-item mx-0">
        <small>
          <i className="fas fa-id-card" style={{ marginLeft: '7px', marginRight: '7px' }}></i>
          {appendPrefix(props.patient.id)}
        </small><br />
        <small>
          <i className="fas fa-birthday-cake" style={{ marginLeft: '7px', marginRight: '7px' }}></i>
          {(props.patient.DOB && props.patient.DOB !== null) ? moment(props.patient.DOB.toString()).format('ddd MMMM Do YYYY') : '-'}
        </small>
        {props.patient.phone ?
          <Link to="" className="text-primary" onClick={call}>
            <small>
              <i className="fa fa-phone" style={{ marginLeft: '15px', marginRight: '7px' }} id="callPatient"></i>
              {props.patient.phone}
            </small>
          </Link> : null}
      </li>
      <br />
      <li className="list-inline-item mx-2">
        {props.patient.gender !== null && (props.patient.gender === 'f' ? <i className="fa fa-female"></i> : <i className="fa fa-male"></i>)}
      </li>
      {props.patient.age !== null && <li className="list-inline-item mx-1">
        Age: {props.patient.age}
      </li>}
      {props.patient.isUser === false && <li className="list-inline-item mx-1">
        <small>
          <i className="fas fa-users" style={{ marginLeft: '7px', marginRight: '7px' }}></i>
          <span>{
            props.patient.dependsOnRelation
              ? (
                  props.patient.dependsOnRelation === 'NonFamilyMember' 
                    ? 'Patient Referral' 
                    : (
                        props.patient.dependsOnRelation === 'Spouse' 
                          ? 'Spouse' 
                          : 'Family Member'
                      )
                )
            : undefined
}</span>        </small>
      </li>}
    </ul>
  )

  const renderSignInAction = () => {
    return (
      <Button
        type="button"
        color="link"
        data-toggle="tooltip"
        className="btn btn-link text-muted ml-auto"
        style={{ float: "right" }}
        disabled={props.patient.active === false}
      >
        <UncontrolledTooltip placement="top" target="loginAsPatient">Login as Pateint</UncontrolledTooltip>
        <i className="fas fa-sign-in-alt" id="loginAsPatient" onClick={logInAgentAsPatient}></i>
        <span className="sr-only">Login as Patient</span>
      </Button>
    )
  }

  return (
    <>
      {renderTitle()}
      <div>
        {renderInfo()}
        {renderSignInAction()}
      </div>

    </>
  );
}