import { IAuthData } from "../../page/chat-module/AuthDataModule";
import { UserConfig } from "../../config/userConfig";
import { appConfig } from "../../config/appConfig";
import { IMessage } from "../../page/chat-module/MessageModel";
import io from "socket.io-client";
import { IAppointmentUpdatedMessage } from '../service/services'


export const initSocket = (addMessage: (msg: IMessage) => void, handleAppointmentUpdate: (updateMsg: IAppointmentUpdatedMessage) => void) => {
  var socket = io(appConfig.socketBaseUrl, {path: ApplicationConfig.socketPath});
  socket.on("connect", (data: string) => {
    console.log("socket connected")
  });
  socket.on("authentication", (authData: IAuthData) => {
    
    console.log("authentication response", authData);

    if (authData.challenge == "Bearer token") {
      socket.emit("authentication", { token: UserConfig.getToken(),  });
    }
    if (authData.status == "approved") {
      
      socket.on("echo", function (msg: any) {
        console.log("echo", msg)
      });
      socket.on("chat", (msg: IMessage) => {
        console.log("chat", msg)
        addMessage(msg)
      });
      socket.on('notification', (msg: IAppointmentUpdatedMessage) => { 
        console.log("notification", msg)
        handleAppointmentUpdate(msg)
      } )
    }

    (window as any).sendSocketEcho = (msg: string) => {
      socket.emit("echo", msg);
    } 
    (window as any).debugSocket = socket

  });

};


