import React, { useState } from "react";
import { RouteComponentProps, Link, navigate } from "@reach/router";
import { Routes } from "../../config/Routes";
import { BigCardFrame } from "../../components/layout/BigCardFrame";
import { CardBody, Container, CardTitle, ListGroup, ListGroupItem, Button, Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Form } from "reactstrap";
import { getPhysitianTemplates, setPhysicianTemplatesService } from "../../core/service/services";
import { TextField } from "../../components/commons/TextField";
import { toastSuccess, navItem } from "../../App";

interface IProps extends RouteComponentProps {}

export interface ITemplate {
  id?: string,
  text: string,
  type: "followup" | "symptoms",
  label: string
}

export const PhysicianTemplates: React.FC<IProps> = props => {
  const [symptomsTemplates, setSymptoms] = useState<ITemplate []>([])
  const [followupTemplates, setFollowups] = useState<ITemplate []>([])
  const [templatesModal, setTemplatesModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<ITemplate>()
  const [templateType, setTemplateType] = useState<"followup" | "symptoms">("followup")

  const toggleTemplatesModal = () => setTemplatesModal(!templatesModal);

  const toggleEditModal = (template: ITemplate) => {
    setEditMode(true)
    setCurrentTemplate(template)
    setTemplateType(template.type)
    toggleTemplatesModal()
  }

  const getData = () => {
    let symptoms: ITemplate [] = [];
    let followups: ITemplate [] = [];
    getPhysitianTemplates().then(res => {
      res.forEach(template => {
        if (template.type === "followup") {
          followups.push(template)
        } else {
          symptoms.push(template)
        }
      });
      setSymptoms(symptoms)
      setFollowups(followups)
    }).catch(error => console.error(error));
  }

  const saveAll = () => {
    setPhysicianTemplatesService(symptomsTemplates.concat(followupTemplates)).then((resData) => {
      toastSuccess("Templates Saved!")
      navigate(Routes.homePhysician)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const flowNav = (
    <div className="flow-nav-tools">
      <Link to={Routes.homePhysician} className="btn btn-lg btn-link control-nav">
        Cancel
      </Link>
      <Button color="success" size="lg" className="control-nav" style={{ marginRight: "auto" }} onClick={saveAll}>
        Save Settings
      </Button>
    </div>
  );

  const saveTemplate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data: FormData = new FormData(e.currentTarget);
    let template: ITemplate = {
      label: "",
      text: "",
      type: templateType
    }
    for(let pair of Array.from(data.entries())) {
      if (pair[0] === "templateName") {
        template.label = pair[1].toString();
      } else {
        template.text = pair[1].toString();
      }
    }
    let templates: ITemplate [] = templateType === "symptoms" ? symptomsTemplates : followupTemplates;
    if (editMode) {
      templates.forEach(t => {
        if (t.id === currentTemplate?.id) {
          t.label = template.label;
          t.text = template.text;
        }
      });
    } else {
      templates.push(template);
    }
    templateType === "symptoms" ? setSymptoms(templates) : setFollowups(templates);
    toggleTemplatesModal();
  }

  React.useEffect(() => getData(), [])

  const getModal = (): React.ReactNode => {
    return (
      <Modal isOpen={templatesModal} toggle={toggleTemplatesModal}>
        <ModalHeader toggle={toggleTemplatesModal}>
          <h5 id="templatesModal" className="modal-title">{"Physician Templates > " + (templateType === "symptoms" ? "Common Symptoms" : "Follow Up Custom")}</h5>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              {templateType === "symptoms" ? <p>Enter your custom symptom note</p> : <p>Enter your custom follow up message</p>}
              <Form onSubmit={saveTemplate}>
                <FormGroup className="mt-2">
                  <TextField name="templateName" type="text" labelText={templateType === "symptoms" ? "Common Symptom Title" : "FollowUp Title"} placeholder="Enter medication name"
                    defaultValue={editMode? currentTemplate?.label : ""}></TextField>
                </FormGroup>
                <FormGroup className="mt-2">
                  <TextField name="templateNote" type="textarea" labelText={templateType === "symptoms" ? "Symptom Note" : "Follow Up Note"} placeholder="Enter prescription that causes allergies"
                    defaultValue={editMode? currentTemplate?.text : ""}></TextField>
                </FormGroup>
                <Button type="submit" color="primary" size="sm" block={true} className="w-50 m-auto">{editMode? "Update" : "Add Custom Message"}</Button>
              </Form>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    )
  }

  const removeTemplate = (template: ITemplate) => {
    if (template.type === "symptoms") {
      setSymptoms(symptomsTemplates.filter((t) => {
        return template.id !== t.id
      }))
    } else {
      setFollowups(followupTemplates.filter((t) => {
        return template.id !== t.id
      }))
    }
  }

  const getItem = (template: ITemplate) => {
    return(
      <ListGroupItem>
        <div className="d-flex">
        {template.label}
        <Button
            data-toggle="tooltip"
            size="sm"
            className="text-muted ml-auto"
            color="link"
            alt="Edit template"
            onClick={() => toggleEditModal(template)}
          >
            <span className="sr-only">Edit</span>{" "}
            <i className="fa fa-edit"></i>
        </Button>
        <Button
            data-toggle="tooltip"
            size="sm"
            className="text-muted"
            color="link"
            data-original-title="Delete template"
            onClick={() => removeTemplate(template)}
          >
            <span className="sr-only">Remove</span>{" "}
            <i className="fa fa-trash"></i>
        </Button>
        </div>
      </ListGroupItem>
    )
  }

  return (
    <BigCardFrame
      flowNav={flowNav}
      navItem={navItem}
      bodyClassName="tc-bg"
      modal={getModal()}
    >
      
      <CardBody>
        <div className="d-flex px-2">
          <h5 className="mb-0">Physician Templates</h5>
          <Link to={Routes.homePhysician} className="btn-sm ml-auto">
            Cancel
          </Link>
        </div>
        <hr className="mt-2 mb-4" />
        <Container>
          <CardTitle>Customize your templates below</CardTitle>
          <div className="mt-3">
            <h6 className="mb-3">
              Common Symptoms Templates{" "}
              <Button color="link" size="sm" onClick={() => {setTemplateType("symptoms"); setEditMode(false); toggleTemplatesModal()}}>Add</Button>
            </h6>
          </div>
          {symptomsTemplates.length > 0 ? 
            <ListGroup flush className="w-50">
              {symptomsTemplates.map((template) => (
                getItem(template)
              ))}
            </ListGroup>
            : <span>None</span>
          }
          <hr />
          <div>
            <h6 className="mb-3">
              Follow Up Custom Templates{" "}
              <Button color="link" size="sm" onClick={() => {setTemplateType("followup"); setEditMode(false); toggleTemplatesModal()}}>Add</Button>
            </h6>
          </div>
          {followupTemplates.length > 0 ? 
            <ListGroup flush className="w-50">
              {followupTemplates.map(template => (
                getItem(template)
              ))}
            </ListGroup>
            : <span>None</span>
          }
          
        </Container>
      </CardBody>
    </BigCardFrame>
  );
};
