import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, FormGroup, Form, CustomInput, CardTitle, Col, Row } from 'reactstrap';
import { IDPatient } from './PatientSettings';
import PreviousConditions from '../medicalHistory/PreviousConditions';
import MedicationAllergies, { IMedicationItemWithRemovedFlag } from '../medicalHistory/MedicationAllergies';
import YourMeasurements from '../medicalHistory/YourMeasurements';
import {
  getAllConditionsLookup,
  IPatientMeasurment,
  getPatientDependentMeasurements,
  getPatientDependentMedications,
  getPatientDependentSelectedConditions,
  postPatientDependentMeasurements,
  IModifyMedicationsRequest,
  getMedicationAllergies,
  addMedicationAllergies,
  deleteMedicationAllergies,
  updateMedicationAllergies,
  getMedicationsAllergyStatus,
  editMedicationsAllergyStatus,
  getPreviousConditionsStatus,
  addSelfReportedMedicationService,
  removeSelfReportedMedicationService,
  logAgentUpdatingPatientMedicalHistory,
  updatePatientPreviousConditions,
  IPatientPreviousConditions,
} from '../../core/service/services';
import CustomedDropdown from '../medicalHistory/CustomedDropdown';
import _ from 'lodash';
import { toastError, toastSuccess } from '../../App';
import { IAddAllergy, IFullAllergy } from '../medicalHistory/MedicationAllergiesDoseSpot';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { SelfReportedMedications } from '../../page/medical-history/SelfReportedMedications';
import { ISelfReportedMedicationsAction } from '../../page/medical-history/MedicalHistory';
import Asterisk from '../commons/Asterisk';
import { UserConfig } from '../../config/userConfig';
import { LoadingButton } from '../commons/LoadingButton';
import InsuranceSection from './insurance/InsuranceSection';
import NewDatePicker from '../NewDatePicker';

interface IProps {
  templateModal: boolean;
  toggleTemplateModal: () => any;
  onSubmitingForm: (modal: IDPatient, indexInArrar: number | null) => Promise<IDPatient | void>;
  editMode?: boolean | false;
  editedModalData?: IDPatient;
  memberId?: number;
  indexInArray: number | null;
  setGenderOnDependent: (index: number | null, gender?: 'm' | 'f') => void;
}
export interface ISelectedItems {
  name: string;
  id: number;
}

const AddHouseholdMemberModal = (props: IProps) => {
  const [step, setStep] = useState(1);
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);
  const [measurementIsEditable, setMeasurmentIsEditable] = useState(false);
  const isEditable = () => setMeasurmentIsEditable(!measurementIsEditable);
  const [placeHolder, setPlaceHolder] = useState('Enter Non Family Member - Dependent age');
  const [formData, setFormData] = useState<IDPatient>({});
  const [conditions, setConditions] = useState(Array<ISelectedItems>());
  const [medications, setMedications] = useState(Array<IMedicationItemWithRemovedFlag>());
  const [measuremenets, setMeasurements] = useState<IPatientMeasurment>({});
  const [medicationsAllergy, setMedicationsAllergy] = useState<IFullAllergy[]>();
  const [addedMedicationsAllergy, setAddedMedicationsAllergy] = useState<IAddAllergy[]>();
  const [selectedConditions, setSelectedConditions] = useState(Array<number>());
  const [deletedMedicationsAllergy, setDeletedMedicationsAllergy] = React.useState<IFullAllergy[]>();
  const [editedMedicationsAllergy, setEditedMedicationsAllergy] = React.useState<IFullAllergy[]>();
  const [allergic, setAllergic] = React.useState<boolean>();
  const [newAllergic, setNewAllergic] = React.useState<boolean>(true);
  const [previousConditionsStatus, setPreviousConditionsStatus] = React.useState(undefined);
  const [newPreviousConditionsStatus, setNewPreviousConditionsStatus] = React.useState<boolean>();
  const [dob, setDob] = React.useState(props.editedModalData?.dateOfBirth);
  const [selfReportedMedicationsActions, setSelfReportedMedicationsActions] = React.useState<ISelfReportedMedicationsAction[]>([]);
  const [genderEditMode, setGenderEditMode] = useState<boolean>(false);
  const toggleGenderEditMode = () => {
    setGenderEditMode(!genderEditMode);
  };
  const [isLoading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setFormData({});
    // reset 2nd form
    setSelectedConditions([]);
    setMeasurements({});
    setMedications([]);
    setMedicationsAllergy([]);
    setEditedMedicationsAllergy([]);
    setAddedMedicationsAllergy([]);
    setDeletedMedicationsAllergy([]);
    setGenderEditMode(false);
    setPreviousConditionsStatus(undefined);
    getData();
  }, [props]);
  useEffect(() => {
    !_.isEmpty(measuremenets) && props.setGenderOnDependent(props.indexInArray, measuremenets.gender);
  }, [measuremenets]);
  const getData = () => {
    setDob(props.editedModalData?.dateOfBirth);
    getAllConditionsLookup()
      .then((res) => {
        setConditions(res);
      })
      .catch((error) => console.error(error));

    const patientId = props.editedModalData?.id || props.memberId;

    getPatientDependentSelectedConditions(patientId)
      .then((res) => {
        setSelectedConditions(res.symptoms);
      })
      .catch((error) => console.error(error));

    getPatientDependentMeasurements(patientId)
      .then((res: IPatientMeasurment) => {
        setMeasurements(res);
      })
      .catch((error) => console.error(error));

    getPatientDependentMedications(patientId)
      .then((res) => {
        setMedications(
          res.map((m) => {
            return { ...m, removed: false };
          })
        );
      })
      .catch((error) => console.error(error));

    getMedicationAllergies(patientId)
      .then((res) => {
        setMedicationsAllergy(res);
      })
      .catch((error) => console.log(error));
    getMedicationsAllergyStatus(patientId)
      .then((result: any) => {
        if (result.exist === true) setAllergic(result.hasMedicationsAllergy);
        else setAllergic(undefined);
      })
      .catch((error) => {
        console.log(error);
      });
    getPreviousConditionsStatus(patientId)
      .then((result: any) => {
        if (result.exist) {
          setPreviousConditionsStatus(result.hasPreviousConditions);
        } else {
          setPreviousConditionsStatus(undefined);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const medicationsChanged = (m: IMedicationItemWithRemovedFlag[]) => {
    setMedications(m);
  };
  ///TODO: UNSAFE
  const onInputChange = (key: string, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  const saveAndContinueModal = (indexInArray: number | null) => {
    if (!_.isEmpty(formData)) {
      setLoading(true);
      formData.gender = formData.gender || props.editedModalData?.gender || undefined;
      props
        .onSubmitingForm(
          {
            ...props.editedModalData,
            ...formData,
            id: props.editedModalData?.id,
          },
          indexInArray
        )
        .then(() => {
          nextStep();
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          let err = returnErrorMessage(error);

          console.log(err);
          toastError(err);
          setLoading(false);
        });
    } else if (props.indexInArray != null) {
      nextStep();
    }
  };

  const returnErrorMessage = (error: any) => {
    console.log(error);
    let errorObj = JSON.parse(JSON.stringify(error));
    if (errorObj && errorObj.json) {
      let errorMessageStr = errorObj.json.message;
      let errorMessage = '';
      if (errorMessageStr.split(',').length > 1) errorMessage = errorMessageStr.split(',')[1];
      else errorMessage = errorMessageStr;
      return errorMessage;
    } else {
      return error;
    }
  };
  const manageDeletedMedications = (e: any) => {
    setMedicationsAllergy(
      medicationsAllergy?.filter((item) => {
        return item.Code !== e.Code;
      })
    );
    setDeletedMedicationsAllergy(deletedMedicationsAllergy ? [...deletedMedicationsAllergy, e] : [e]);
  };
  const manageEditedMedications = (e: any) => {
    if (
      medicationsAllergy &&
      medicationsAllergy?.filter((item) => {
        return item.Code === e.Code;
      }).length > 0
    )
      setEditedMedicationsAllergy(editedMedicationsAllergy ? [...editedMedicationsAllergy, e] : [e]);
    else if (
      addedMedicationsAllergy &&
      addedMedicationsAllergy?.filter((item) => {
        return item.Code === e.Code;
      })
    )
      setAddedMedicationsAllergy([...addedMedicationsAllergy, e]);
  };
  const onSavingForm = async (e: any) => {
    setLoading(true);
    const patientId = props.editedModalData?.id || props.memberId;
    let successArr: boolean[] = [];
    let errorArr: any[] = [];

    let success = () => {
      successArr.push(true);

      // THE NUMBER OF REQUESTS
      // TODO: CHANGE ACCORDINGLY
      if (successArr.length > 6) {
        toastSuccess('Saved with success');
        prevStep();
        setLoading(false);

        props.toggleTemplateModal();
      }
    };
    let logError = (e: any, message?: string) => {
      errorArr.push(e);
      if (errorArr.length > 0) {
        console.log(e);
        let errorObj = JSON.parse(JSON.stringify(e));
        if (errorObj && errorObj.json) {
          let errorMessageStr = errorObj.json.message;
          let errorMessage = errorMessageStr.split(',')[1];
          errorArr.push(errorMessage);
          toastError(errorMessage);
        } else {
          toastError(message ? message : 'Errors encountered');
          console.log(e);
        }
      }
    };
    // await postPatientDependentSelectedSymptoms(
    //   selectedConditions
    //   ,patientId
    // )
    //   .catch((e) => {
    //     logError(e);
    //   });
    await postPatientDependentMeasurements(patientId, measuremenets).catch((e) => {
      logError(e);
    });
    let medItems = medications.reduce(
      (coll, val) => {
        if (val.id && val.removed) {
          coll.remove.push(val.id);
        } else if (val.id == null && !val.removed) {
          coll.add.push(val.name);
        } else if (val.id && !val.removed) {
          coll.modify.push({ name: val.name, id: val.id });
        }
        return coll;
      },
      { remove: [], add: [], modify: [] } as IModifyMedicationsRequest
    );

    // postPatientDependentMedications(
    //   patientId,
    //   medItems
    // )
    //   .then(() => {
    //     success();
    //   })
    //   .catch((e) => {
    //     error(e);
    //   });

    if (addedMedicationsAllergy)
      await addMedicationAllergies(addedMedicationsAllergy, patientId).catch((error: any) => {
        let obj: any = Object.values(error)[2];
        logError(error, obj.message);
      });
    setMeasurmentIsEditable(false);

    if (deletedMedicationsAllergy)
      await deleteMedicationAllergies(deletedMedicationsAllergy, patientId).catch((error) => {
        logError(error, 'Medication cannot be deleted');
      });

    if (editedMedicationsAllergy)
      await updateMedicationAllergies(editedMedicationsAllergy, patientId).catch((error) => {
        logError(error, 'Medication cannot be deleted');
      });

    let body = {
      hasMedicationsAllergy: newAllergic,
    };
    await editMedicationsAllergyStatus(body.hasMedicationsAllergy, patientId).catch(() => {
      toastError('Medications Allergies status cannot be changed!');
    });
    if (newPreviousConditionsStatus !== undefined) {
      let body: IPatientPreviousConditions = {
        hasPreviousCondition: newPreviousConditionsStatus,
        conditions: selectedConditions,
      };
      await updatePatientPreviousConditions(body, patientId).catch((error) => {
        logError(error, 'Previous Conditions cannot be updated!');
      });
    }
    selfReportedMedicationsActions.forEach(async (item) => {
      if (item.action === 'add') {
        await addSelfReportedMedicationService(item.medication, patientId).catch((err) => console.log(err));
      } else if (item.medication.id) {
        await removeSelfReportedMedicationService(item.medication.id, patientId).catch((err) => console.log(err));
      }
    });
    setSelfReportedMedicationsActions([]);
    if (UserConfig.getUserType() === 'agent') await logAgentUpdatingPatientMedicalHistory(patientId);
    if (errorArr.length === 0) {
      toastSuccess('Saved with success');
      if(props.editedModalData?.relationshipToDependable === 'NonFamilyMember'){
        props.toggleTemplateModal(); //No insurance step for NonFamilyMember
        setStep(1);
      }
      else{
        nextStep();
      }
      setLoading(false);

      //  props.toggleTemplateModal();
    }
  };

  const getGenderValues = (s: string) => {
    if (s === 'f') return 'Female';
    if (s === 'm') return 'Male';
  };

  const renderFemaleOption = () => {
    return (
      <div>
        <FormGroup check inline>
          <div className='custom-checkbox custom-control'>
            <Input
              onChange={(e) => {
                setMeasurements({
                  ...measuremenets,
                  pregnant: e.target.checked,
                });
              }}
              id='pregnant'
              type='checkbox'
              defaultChecked={measuremenets?.pregnant}
              // disabled={!enabled}
              disableUnderline={true}
              // className="custom-control-input"
            />
            <Label>Pregnant</Label>
          </div>
        </FormGroup>
        <FormGroup check inline>
          <div className='custom-checkbox custom-control'>
            <Input
              onChange={(e) => {
                setMeasurements({
                  ...measuremenets,
                  breastfeeding: e.target.checked,
                });
              }}
              id='breastFeeding'
              type='checkbox'
              defaultChecked={measuremenets?.breastfeeding}
              // disabled={!enabled}
              // className="custom-control-input"
              disableUnderline={true}
            />
            <Label for='breastFeeding'>Breast Feeding</Label>
          </div>
        </FormGroup>
      </div>
    );
  };
  const renderForms = () => {
    switch (step) {
      case 1:
        return (
          <fieldset disabled={isLoading}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                saveAndContinueModal(props.indexInArray);
              }}
            >
              <FormGroup>
                <Label for='addMemberFirstName'>First Name:</Label>
                <Input
                  onChange={(e: any) => onInputChange('firstname', e.target.value)}
                  type='text'
                  id='addMemberFirstName'
                  defaultValue={props.editedModalData?.firstname}
                  placeholder="Enter account member's first name"
                  name='addMemberFirstName'
                  required={true}
                />
              </FormGroup>
              <FormGroup>
                <Label for='addMemberMiddleName'>Middle Name:</Label>
                <Input
                  onChange={(e: any) => onInputChange('middlename', e.target.value)}
                  type='text'
                  id='addMemberMiddleName'
                  defaultValue={props.editedModalData?.middlename}
                  placeholder="Enter account member's middle name"
                  name='addMemberMiddleName'
                />
              </FormGroup>
              <FormGroup>
                <Label for='addMemberFirstName'>Last Name:</Label>
                <Input
                  onChange={(e: any) => onInputChange('lastname', e.target.value)}
                  type='text'
                  id='addMemberFirstName'
                  defaultValue={props.editedModalData?.lastname}
                  placeholder="Enter account member's last name"
                  name='addMemberFirstName'
                  required={true}
                />
              </FormGroup>
              {/* <FormGroup>
              <Label for="gender">Choose Gender</Label>
              <div id="gender">
                <CustomInput
                  onChange={() => onInputChange("gender", "m")}
                  defaultValue={props.editedModalData?.gender}
                  type="radio"
                  id="m"
                  name="gender"
                  label="Male"
                  required={true}
                  defaultChecked={props.editedModalData?.gender === "m"}
                />
                <CustomInput
                  onChange={() => onInputChange("gender", "f")}
                  defaultValue={props.editedModalData?.gender}
                  type="radio"
                  id="f"
                  name="gender"
                  label="Female"
                  required={true}
                  defaultChecked={props.editedModalData?.gender === "f"}
                />
              </div>
            </FormGroup> */}
              <FormGroup>
                <Label for='familyRelation'>Choose Relationship</Label>
                <div id='familyRelation'>
                <CustomInput
                    onChange={() => onInputChange('relationshipToDependable', 'Spouse')}
                    required={true}
                    type='radio'
                    id='spouse'
                    name='familyRelation'
                    label='Spouse'
                    // disabled
                    defaultChecked={props.editedModalData?.relationshipToDependable === 'Spouse'}
                  />
                  <CustomInput
                    required={true}
                    type='radio'
                    id='familyMember'
                    name='familyRelation'
                    label='Family Member'
                    onChange={() => {
                      setPlaceHolder('Enter Family Member age');
                      onInputChange('relationshipToDependable', 'FamilyMember');
                    }}
                    defaultChecked={props.editedModalData?.relationshipToDependable === 'FamilyMember'}
                  />
                  <CustomInput
                  required={true}
                  type="radio"
                  id="nonFamilyMember"
                  name="familyRelation"
                  label="Patient Referal"
                  defaultChecked={
                    props.editedModalData?.relationshipToDependable ===
                    'NonFamilyMember'
                  }
                  onChange={() => {
                    setPlaceHolder('Enter account member age');
                    onInputChange(
                      'relationshipToDependable',
                      'NonFamilyMember'
                    );
                  }}
                />
                </div>
              </FormGroup>
              <FormGroup>
                <Label htmlFor='addMemberAge'>Date of Birth:</Label>
                <NewDatePicker selectedDate={dob} onChange={(date: string) => onInputChange('dateOfBirth', date)} />
              </FormGroup>

              <ModalFooter>
                <Button color='secondary' onClick={props.toggleTemplateModal}>
                  Close
                </Button>
                <LoadingButton
                  type='submit'
                  // onClick={saveAndContinueModal}
                  color='primary'
                  isLoading={isLoading}
                >
                  Save And Continue
                </LoadingButton>{' '}
              </ModalFooter>
            </Form>
          </fieldset>
        );
      case 2:
        const patientId = props.editedModalData?.id || props.memberId;
        return (
          <div>
            <fieldset disabled={isLoading}>
              <Form onSubmit={onSavingForm}>
                <CardTitle>Please review your medical profile below and edit any inconsistencies</CardTitle>
                <PreviousConditions
                  conditions={conditions}
                  selectedConditions={selectedConditions}
                  setSelectedConditions={(c) => {
                    setSelectedConditions(c);
                  }}
                  previousConditionsStatus={previousConditionsStatus}
                  newPreviousConditionsStatus={newPreviousConditionsStatus}
                  onPreviousConditionsChanges={(e: boolean) => {
                    setNewPreviousConditionsStatus(e);
                  }}
                />
                <hr />
                <MedicationAllergies
                  medicationsChanged={medicationsChanged}
                  medications={medications}
                  medicationsAllergies={medicationsAllergy}
                  newMedications={(e: IAddAllergy[]) => {
                    setAddedMedicationsAllergy(e);
                  }}
                  deletedAllergies={(e: any) => {
                    manageDeletedMedications(e);
                  }}
                  editedAllergies={(e: any) => {
                    manageEditedMedications(e);
                  }}
                  medicationAllergic={allergic}
                  onChangeMedicationAllergyStatus={(e: boolean) => {
                    setNewAllergic(e);
                  }}
                  newAllergic={newAllergic}
                />
                <hr />
                <SelfReportedMedications setSelfReportedMedicationsActions={setSelfReportedMedicationsActions} patientId={patientId} />
                <hr />
                <YourMeasurements isEditable={isEditable} />
                <hr />
                <Row form>
                  <Col md='4' lg='4'>
                    <h6>Height (Ft):</h6>
                    <FormGroup className='form-label-group'>
                      <Input
                        id='height-ft'
                        type='number'
                        // disabled={!measurementIsEditable}
                        defaultValue={measuremenets.heightFt}
                        onChange={(e) =>
                          setMeasurements({
                            ...measuremenets,
                            heightFt: parseInt(e.target.value),
                          })
                        }
                        className='form-control'
                      ></Input>
                    </FormGroup>
                  </Col>

                  <Col md='4' lg='4'>
                    <h6>Height (In):</h6>
                    <FormGroup className='form-label-group'>
                      <Input
                        id='height-in'
                        type='number'
                        // disabled={!measurementIsEditable}
                        className='form-control'
                        defaultValue={measuremenets.heightIn}
                        onChange={(e) =>
                          setMeasurements({
                            ...measuremenets,
                            heightIn: parseInt(e.target.value),
                          })
                        }
                      ></Input>
                    </FormGroup>
                  </Col>

                  <Col md='4' lg='4'>
                    <h6>Weight (Lbs):</h6>
                    <FormGroup className='form-label-group'>
                      <Input
                        id='weight'
                        type='number'
                        // disabled={!measurementIsEditable}
                        defaultValue={measuremenets.weight}
                        onChange={(e) =>
                          setMeasurements({
                            ...measuremenets,
                            weight: parseInt(e.target.value),
                          })
                        }
                        className='form-control'
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <h6 className='mb-3'>
                  Gender <Asterisk />
                  {/* <Button
                color="link"
                size="sm"
                className="pt-0"
                onClick={() => {
                  toggleGenderEditMode();
                }}
              >
                <span>{!genderEditMode ? 'Edit' : 'Done'}</span>
              </Button> */}
                </h6>
                <FormGroup>
                  {/* {genderEditMode ? ( */}
                  <CustomedDropdown
                    returnedValue={(g: 'f' | 'm') => {
                      setMeasurements({ ...measuremenets, gender: g });
                    }}
                    gender={getGenderValues(measuremenets?.gender || '')}
                  />
                  {/* ) : (
                  getGenderValues(measuremenets?.gender || '') || 'None'
                )} */}
                </FormGroup>

                {measuremenets?.gender == 'f' ? renderFemaleOption() : null}
                <ModalFooter>
                  <Button
                    color='secondary'
                    onClick={() => {
                      props.toggleTemplateModal();
                      prevStep();
                    }}
                  >
                    Close
                  </Button>
                  <LoadingButton type='submit' onClick={onSavingForm} color='primary' isLoading={isLoading}>
                    Save
                  </LoadingButton>{' '}
                </ModalFooter>
              </Form>
            </fieldset>
          </div>
        );
      case 3: {
        const patientId = props.editedModalData?.id || props.memberId;

        return (
          <>
            <InsuranceSection dependentId={patientId}></InsuranceSection>
            <ModalFooter>
              <Button
                color='secondary'
                onClick={() => {
                  props.toggleTemplateModal();
                  setStep(1);
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </>
        );
      }
    }
  };
  const renderTitle = () => {
    switch (step) {
      case 1:
        return 'Add An Account Member';
      case 2:
        return 'Medical Profile';

      case 3:
        return 'Insurance Section (Not Mandatory)';
    }
  };
  return (
    <div>
      <Modal isOpen={props.templateModal}>
        <ModalHeader>{renderTitle()}</ModalHeader>
        <ModalBody>{renderForms()}</ModalBody>
      </Modal>
    </div>
  );
};
export default AddHouseholdMemberModal;
