import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { SmallCardFrame } from '../../components/layout/SmallCardFrame';



export const SuccessfullSignupPage: React.FC<RouteComponentProps> = (props) => {


    return (
        <SmallCardFrame navItem={null} bodyClassName="tc-bg" >
        <div className="card-body">
            <div className="pre-password-reset">
                <h5 className="card-title text-muted mb-1">Registered Successfully <i className="fas fa-check-circle fa-1x"></i></h5>
                <p >We have sent a verification mail to your mailbox with an activation link. Please click the link to activate your account. 
                    If you didn't receive the message in your mailbox, please check your <strong>spam folder</strong></p>
                <hr />
            </div>

        </div>
        </SmallCardFrame>

    )
}