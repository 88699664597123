import React, { useEffect, useState } from "react";
import { Button, CardBody, CardTitle, Row, Col, Form, FormGroup, Label, Input, ModalFooter, CustomInput, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody } from "reactstrap";
import { IFullAppointment, IAppointmentInfo } from "../../book-appointment/AppointmentModels";
import {
  getPhysicianAppointmentDetails, getPhysitianTemplates, IPhysicianTemplate, getAttachment, getAllConditionsLookup, completeAppointment, postAbsenceNote,
  IAbsenceNotes, getAbsenceNoteForAppointment, getPrescriptionsForPhysician, IPrescription, checkForNewPrescriptionInCompletedAppointment, submitPrescriptionAfterCompletion, updateAbsenceNote, getAppointmentInfoForPhysician
} from "../../../core/service/services";
import moment from "moment";
import { RouteComponentProps, navigate } from "@reach/router";
import { GalleryModal } from "../../../components/commons/GalleryModal";
import { Routes } from "../../../config/Routes";
import { PhysicianNotes } from "./PhysicianNotes";
import { FollowupNotes } from "./FollowupNotes";
import { AppointmentPrescriptions } from "./AppointmentPrescriptions";
import { PhysicianAddendums } from "./PhysicianAddendums";
import { toastError, navItem, toastSuccess } from "../../../App";
import { localizeTimezone } from "../../../core/service/TimeZone";
import { IMessage } from "../../chat-module/MessageModel";
import _ from "lodash";
import { initSocket } from "../../../core/socket/SocketInitializer";
import PreferredPharmacy from "../../../components/patientSettings/PreferredPharmacy";
import { PrescriptionApprovalAlert } from "./PrescriptionApprovalAlert";
import { AppointmentActions } from "../appointment/AppointmentActions";
import { IPatientInfoSettings } from "../../../components/patientSettings/PatientSettings";
import { BigCardFrame } from "../../../components/layout/BigCardFrame";
import { ChatModule } from "../../chat-module/ChatModule";
import { PhysicianDiagnosis } from "./PhysicianDiagnosis";
import { PhysicianCPT } from "./PhysicianCPT";
import { LoadingButton } from "../../../components/commons/LoadingButton";
const rfb = require("react-floating-action-button");

export interface IItemAllergyInteraction {
  Message: string,
  Reaction: string,
  ReactionType: number
}

interface IProps extends RouteComponentProps {
  addedMessage?: IMessage;
  onMessageReceived?: (msg: IMessage) => void;
}

export const PhysicianAppointmentDetails: React.FC<IProps> = (props) => {
  const [appointmentId, setAppointmentId] = useState<string>();
  const [appointment, setAppointment] = useState<IFullAppointment>();
  const [allSymptoms, setAllSymptoms] = useState<{ id: number; name: string }[]>([]);
  const [attachments, setAttachments] = useState<string[]>([]);
  const [galleryModal, toggleGalleryModal] = useState<boolean>(false);
  const [imgIdx, setImgIdx] = useState<number>(0);
  const [symptomsTemplates, setSymptomsTemplates] = useState<IPhysicianTemplate[]>([]);
  const [followupTemplates, setFollowupTemplates] = useState<IPhysicianTemplate[]>([]);
  const [toggleAbsenceNotesModal, setToggleAbsenceNotesModal] = useState<boolean>(false);
  const [addAbsenceNote, setAddAbsenceNote] = useState<IAbsenceNotes>();
  const [absenceNote, setAbsenceNote] = useState<string>();
  const [message, setMessage] = useState<IMessage>();
  const [prescriptionsApprovalModal, setPrescriptionsApprovalModal] = useState<boolean>(false)
  const [unorderedPrescriptions, setUnorderedPrescriptions] = useState<IPrescription[]>([]);
  const [isNewPrescriptionAfterCompletion, setIsNewPrescriptionAfterCompletion] = useState<boolean>()
  const [editAbsenceNote, setEditAbsenceNote] = useState<boolean>(false);
  const [absenceNoteObject, setAbsenceNoteObject] = useState<IAbsenceNotes>();
  const [appointmentInfo, setAppointmentInfo] = useState<IAppointmentInfo>();
  const [chatModalOpen, setChatModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const NOT_AVAILABLE = 'N/A'

  const toggleGallery = () => {
    toggleGalleryModal(!galleryModal);
  };

  const toggleChatModal = () => {
    setChatModalOpen(!chatModalOpen)
  }

  const refetchAppointmentDetails = () => {
    if (appointmentId) {
      getPhysicianAppointmentDetails(appointmentId).then(res => {
        setAppointment(res)
      }).catch(err => console.log(err))
    }
  }

  const getData = () => {
    const search = new URLSearchParams(props.location?.search);
    const appointmentId = search.get("appointmentId") || "";
    setAppointmentId(appointmentId);

    getAppointmentInfoForPhysician(appointmentId).then(res => {
      setAppointmentInfo(res)
    }).catch(err => console.log(err))

    async function fetchData() {
      const app = await getPhysicianAppointmentDetails(appointmentId);
      setAppointment(app);
      const allConds = await getAllConditionsLookup();
      setAllSymptoms(allConds);
      let st = [];
      let ft = [];
      const phTemplates = await getPhysitianTemplates();
      for (let item of phTemplates) {
        if (item.type === "symptoms") {
          st.push(item);
        } else {
          ft.push(item);
        }
      }
      setSymptomsTemplates(st);
      setFollowupTemplates(ft);

      let atts: string[] = [];
      if (app.why.attachments) {
        for (const item of app.why.attachments) {
          const res = await getAttachment(appointmentId, item);
          if (res && res.imageBase64) atts.push(res.imageBase64);
        }
        setAttachments(atts);
      }
    }
    fetchData().catch(err => {
      if (err.response.status === 401) {
        toastError(err.json.message)
        navigate(Routes.homePhysician)
      }
    });
    getAbsenceNoteForAppointment(appointmentId).then((res) => {
      let from = moment(res.from.toString().substring(0, 10)).format("MM-DD-YYYY")
      let to = moment(res.to.toString().substring(0, 10)).format("MM-DD-YYYY")
      setAbsenceNote("Absence Note : " + from + " - " + to);
      setAbsenceNoteObject(res);
    }).catch(() => setAbsenceNote(""));
    checkForNewPrescriptions(appointmentId);
  };

  const checkForNewPrescriptions = (appId?: string) => {
    checkForNewPrescriptionInCompletedAppointment(appId || appointmentId || '')
      .then((res: any) => {
        setIsNewPrescriptionAfterCompletion(res.result);
      }).catch(() => setIsNewPrescriptionAfterCompletion(false));
  }

  const getMedicationAllergies = () => {
    return appointment?.who.patient.medicationAllergies.map(s => (s.Name + (s.Reaction ? (" - Reaction: " + s.Reaction) : ""))).join(", ") || "None"
  }
  const getSelfReportedMedication = () => {
    return appointment?.who.patient.selfReportedMedications?.map(s => s.name).join(", ") || "None"
  }
  useEffect(() => {
    getData();
    initSocket(onMessageReceived, () => { });
  }, []);

  const requestPrescriptionsApproval = () => {
    setLoading(true);
    appointment && getPrescriptionsForPhysician(appointment.appointmentId).then((res) => {
      const unorderedPrescriptions = res.filter(item => {
        return !item.ordered
      })
      if (unorderedPrescriptions.length > 0) {
        setUnorderedPrescriptions(unorderedPrescriptions)
        togglePrescriptionsApprovalModal()
      } else {
        completeAppt()
      }
    }).catch(err => console.log(err))
    .finally(() => {
      setLoading(false)
    })
  }

  const togglePrescriptionsApprovalModal = () => {
    setPrescriptionsApprovalModal(!prescriptionsApprovalModal)
  }

  const onPrescriptioApprovalResponse = (approved: boolean) => {
    if (approved) {
      completeAppt()
    }
    togglePrescriptionsApprovalModal()
  }

  const completeAppt = () => {
    if (appointment) {
      if (appointment.status === "Locked" || appointment.status === "Started") {
        completeAppointment(appointment.appointmentId)
          .then((res) => {
            toastSuccess("Appointment Completed Successfully")
            getData();
            // navigate(Routes.homePhysician);
          })
          .catch((err) => {
            console.log("error message: ", err);
            toastError(err.json.message);
          });
      } else if (appointment.status === "Completed") {
        submitPrescriptionAfterCompletion(appointment.appointmentId)
          .then(() => {
            getData();
            setIsNewPrescriptionAfterCompletion(false);
            toastSuccess("Prescriptions submitted successfully")
          }).catch(() => {
            toastError("Submitting prescriptions failed")
          })
      }
    }
  };

  const renderSubmitPrescriptionButton = () => {
    return (
      <Button color="success" size="lg" className="control-nav mr-auto" onClick={requestPrescriptionsApproval}>
        Submit Prescriptions
      </Button>
    )
  }

  const renderFlowNav = () => {
    return (
      <div className="flow-nav-tools">
        <Button color="primary" size="lg" className="control-nav ml-auto mr-auto" onClick={() => navigate(Routes.homePhysician)}>
          Back
        </Button>
        {(appointment?.status === "Started") && (
          <LoadingButton isLoading={loading}  color="success" size="lg" className="control-nav mr-auto" onClick={requestPrescriptionsApproval}>
            Complete Appointment
          </LoadingButton>
        )}
        {appointment?.status === "Completed" && isNewPrescriptionAfterCompletion && (renderSubmitPrescriptionButton())}
      </div>
    );
  };

  const onMessageReceived = (message: IMessage) => {
    setMessage(message);
  };

  const renderChatModal = () => {
    if (appointment?.status === "Completed" || appointment?.status === "Locked" || appointment?.status === "Started") {
      return (
        <ChatModule appointmentId={appointmentId} addedMessage={message} onMessageReceived={onMessageReceived}
          receiverName={appointment?.who.patientName || ""} modalOpen={chatModalOpen} toggleChatModal={toggleChatModal} />
      );
    }
  };

  const getAppointmentTime = () => {
    return appointment?.when?.time ? localizeTimezone(appointment?.when.time.toString(),
      "ddd MMMM Do YYYY") + " at " + localizeTimezone(appointment?.when.time.toString(), "h:mm a") : "";
  };

  const renderHeaderInfo = () => {
    return (
      <CardTitle>
        {"Below are the appointment details and treatment plan as per your consultation with " +
          appointment?.who.patientName + " on " + getAppointmentTime()}
        {appointmentInfo &&
          <AppointmentActions appointment={appointmentInfo} hasLockedAppointment={false} fromAppointmentDetails={true} refresh={refetchAppointmentDetails} />
        }
      </CardTitle>
    );
  };

  const infoItem = (label: string, value?: string, long?: boolean) => (
    <Col sm={long ? "12" : "6"} className="mb-3">
      <dl>
        <dt>{label}:</dt>
        <dd>{value}</dd>
      </dl>
    </Col>
  );

  const getMedications = () => {
    const meds = appointment?.who.patient.medications
      ? appointment?.who.patient.medications.map((item) => {
        return item.name;
      })
      : [];
    return meds.join(", ");
  };

  const getConditions = () => {
    const conds: string[] = [];
    allSymptoms.forEach((item) => {
      if (
        appointment?.who.patient.surgeriesAndSymptoms.symptoms.includes(item.id)
      ) {
        conds.push(item.name);
      }
    });
    return conds.join(", ");
  };

  const renderCurrentPharmacy = () => {
    let info: IPatientInfoSettings = {
      pharmacy: appointment?.pharmacy,
    };
    return (
      <Col sm="12" className="mb-3">
        <PreferredPharmacy
          info={info}
          fetch={getData}
          physician={true}
          appointmentId={appointment?.appointmentId}
          pharmacy={appointment?.pharmacy}
          onRegister={false}
          readOnly={appointment?.status === "Archived" || appointment?.status === "Locked"}
          appointmentStatus={appointment?.status}
        />
      </Col>
    );
  };
  async function callPatient() {
    navigate("tel:67" + appointment?.contact.phone);
  }

  const renderPatientDetails = () => {
    return (
      <>
        <h5 className="mb-3">Patient Details</h5>
        <Row className="ml-2">
          {infoItem("Patient Full Name", appointment?.who.patientName)}
          {infoItem("Patient ID", "" + appointment?.who.patientCode)}
          {infoItem("Requestor Full Name", appointment?.who.requestPatient.info.contact.firstname +
            " " + appointment?.who.requestPatient.info.contact.lastname
          )}
          {infoItem("Date of Birth", moment(appointment?.who.patient.info.contact.dateOfBirth).format('MM-DD-YYYY')
          )}
          {infoItem("Gender", appointment?.who.patient.measurements.gender === "m" ? "Male" : "Female (Pregnant: " +
            (appointment?.who.patient.measurements.pregnant ? "Yes" : "No") + ", BreastFeeding: " +
            (appointment?.who.patient.measurements.breastfeeding ? "Yes" : "No") + ")")
          }
          {infoItem("Email", appointment?.who.requestPatient.info.contact.email)}
          {infoItem("Phone", appointment?.contact.phone)}
          {infoItem("Address Line 1", appointment?.who.requestPatient.info.address.description)}
          {infoItem("Address Line 2", appointment?.who.requestPatient.info.address.addressLine2)}
          {infoItem("City", appointment?.who.requestPatient.info.address.city)}
          {infoItem("State", appointment?.who.requestPatient.info.address.stateName)}
          {infoItem("Zip Code", appointment?.who.requestPatient.info.address.zipCode)}
          {infoItem("Height", concatinateHeight(appointment?.who.patient.measurements))}
          {infoItem("Weight", appointment?.who.patient.measurements.weight + " lbs")}
          {/* {infoItem("Home Medications", appointment && appointment?.who.patient.medications.length > 0? getMedications() : "None")} */}
          {infoItem("Medication Allergies", getMedicationAllergies())}
          {infoItem("Medical Conditions", appointment && appointment?.who.patient.surgeriesAndSymptoms.symptoms.length > 0 ? getConditions() : "None")}
          {infoItem("Self-Reported Medications", getSelfReportedMedication())}
        </Row>
      </>
    );
  };

  const generateImages = (): string[] => {
    let result = [];
    for (const att of attachments) {
      result.push("data:image/jpeg;base64, " + att);
    }
    return result;
  };

  const concatinateHeight = (measurement?: any) => {
    if (measurement?.heightFt && measurement?.heightIn)
      return `${measurement?.heightFt}  Ft. / ${measurement?.heightIn}  In.`
    else if (measurement?.heightFt && !measurement?.heightIn)
      return `${measurement?.heightFt}  Ft.`
    else if (!measurement?.heightFt && measurement?.heightIn)
      return `${measurement?.heightIn}  In.`
    else
      return ``
  }

  const openImg = (idx: number) => {
    setImgIdx(idx);
    toggleGallery();
  };

  const images = generateImages();

  const renderPatientPhotos = () => {
    return (
      <>
        <Col className="sm-12">
          <dl>
            <dt>Patient Photos:</dt>
            <dd>{renderImagesOrNotAvailable()}</dd>
          </dl>
        </Col>
      </>
    );
  };

  const renderImagesOrNotAvailable = () => {
    if (images.length) {
      return (
        <div className="d-flex flex-wrap mb-3">
          {images.map((att, idx) => (
            <div className="p-2">
              <img src={att} style={{ maxWidth: 120, cursor: "pointer" }} onClick={() => openImg(idx)} />
            </div>
          ))}
        </div>
      )
    }
    return NOT_AVAILABLE
  }

  const renderAppointmentDetails = () => {
    return (
      <>
        <h5 className="mb-3">Appointment Details</h5>
        <Row className="ml-2">
          {infoItem("Consultation Type", appointment?.why.consultationType || NOT_AVAILABLE)}
          {infoItem("Date and Time", moment(appointment?.when.time).format("ddd MMMM Do YYYY h:mm a"))}
          {infoItem("Contact Type", appointment?.contact.type)}
          {infoItem("Prescriber Name", appointment?.physician.name)}
          {infoItem("Reason for Visit", appointment?.why.reason || NOT_AVAILABLE)}
          {infoItem("Patient Notes", appointment?.why.symptoms || NOT_AVAILABLE, true)}
          {renderPatientPhotos()}
        </Row>
      </>
    );
  };

  const getModals = () => {
    return (
      <>
        <PrescriptionApprovalAlert isOpen={prescriptionsApprovalModal} prescriptions={unorderedPrescriptions}
          setResponse={onPrescriptioApprovalResponse} toggleOpen={togglePrescriptionsApprovalModal} />
        {galleryModal && <GalleryModal toggleModal={toggleGallery} photoIndex={imgIdx} images={images} />}
      </>
    )
  };

  const renderTreatmentPlan = () => (
    <>
      <h5 className="mb-3">Treatment Plan</h5>
      <Row className="ml-2">
        <PhysicianNotes appointment={appointment} templates={symptomsTemplates} />

        {renderDiagnosisCPT()}
        <FollowupNotes appointment={appointment} templates={followupTemplates} />
        {appointment && <AppointmentPrescriptions appointment={appointment} checkForNewPrescriptions={checkForNewPrescriptions} />}
        {renderCurrentPharmacy()}
        {(appointment?.status === "Completed" ||
          appointment?.status === "Archived") && (
            <PhysicianAddendums appointment={appointment} />
          )}
      </Row>
    </>
  );

  const onFromDateChange = (e: any) => {
    setAddAbsenceNote({
      ...addAbsenceNote,
      from: e.target.valueAsDate || new Date(),
    });
    setAbsenceNoteObject({
      ...absenceNoteObject,
      from: e.target.valueAsDate || new Date(),
    })
  }

  const onNumberOfDaysChange = (e: any) => {
    setAddAbsenceNote({
      ...addAbsenceNote,
      to: moment(addAbsenceNote?.from, "MM-DD-YYYY").add(e.target.valueAsNumber - 1, 'days').toDate()
        || new Date(),
    });
    setAbsenceNoteObject({
      ...absenceNoteObject,
      to: moment(absenceNoteObject?.from, "MM-DD-YYYY").add(e.target.valueAsNumber - 1, 'days').add(3, 'hours').toDate()
        || new Date(),
    })
  }

  const onIncludeReasonsChange = (e: any) => {
    setAddAbsenceNote({
      ...addAbsenceNote,
      reasonForVisit: (e.target.checked) ? appointment?.why.reason : "",
    });
    setAbsenceNoteObject({
      ...absenceNoteObject,
      reasonForVisit: (e.target.checked) ? appointment?.why.reason : "",
    })
  }

  const onCommentChange = (e: any) => {
    setAddAbsenceNote({ ...addAbsenceNote, comment: e.target.value });
    setAbsenceNoteObject({ ...absenceNoteObject, comment: e.target.value });
  }

  const renderAbsenceNotesModal = () => (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        saveAbsenceNote(e);
      }}
    >
      <FormGroup>
        <Label for="addAbsenceSince">Absence since:</Label>
        <Input type="date" id="addAbsenceSince" placeholder="Absent from" name="addAbsenceSince"  required
          defaultValue={editAbsenceNote ? moment(absenceNoteObject?.from).format("MM-DD-YYYY") : ''}
          onChange={(e) => {
            onFromDateChange(e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label for="addAbsenceTo">Number of days:</Label>
        <Input type="number" id="addAbsenceTo" placeholder="Number of days" name="addAbsenceTo" min={1} required onChange={(e) => {
          onNumberOfDaysChange(e);
        }}
          defaultValue={editAbsenceNote ? (moment(absenceNoteObject?.to).diff(absenceNoteObject?.from, 'days') + 1) : ''}
        />
      </FormGroup>
      <FormGroup>
        {/* <Label for="reasonForVisit">Include Reason for Visit</Label> */}
        <CustomInput type="checkbox" id="reasonForVisit" label="Include Reason for Visit" name="reasonForVisit"
          defaultChecked={absenceNoteObject?.reasonForVisit ? true : false}
          onChange={(e) => {
            onIncludeReasonsChange(e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label for="addComments">Comments:</Label>
        <Input type="textarea" id="addComments" placeholder="Add comments" name="addComments"
          defaultValue={absenceNoteObject?.comment}
          onChange={(e) => {
            onCommentChange(e);
          }}
        />
      </FormGroup>
      <ModalFooter>
        <Button color="secondary" onClick={() => { setToggleAbsenceNotesModal(false); setAddAbsenceNote({}); }}>
          Close
        </Button>{" "}
        <Button color="primary" type="submit">
          Save
        </Button>{" "}
      </ModalFooter>
    </Form>
  );
  const getValue = (
    arr: [string, FormDataEntryValue][],
    field: string
  ): any => {
    let value = arr.filter(function (record) {
      return record[0] === field;
    });

    return value[0][1];
  };
  const calculateToAbsenceNote = (formData: [string, FormDataEntryValue][]) => {
    let to = getValue(formData, 'addAbsenceTo');
    return moment(absenceNoteObject?.from, "YYYY-MM-DD").add(to - 1, 'days').add(3, 'hours').toDate()
  }
  const saveAbsenceNote = (e: React.FormEvent<HTMLFormElement>) => {
    const data: FormData = new FormData(e.currentTarget);
    const formData: [string, FormDataEntryValue][] = Array.from(data.entries());
    if (!_.isEmpty(formData)) {
      let updatedAbsenceNote = { ...absenceNoteObject, to: calculateToAbsenceNote(formData) }

      if (appointmentId && addAbsenceNote) {
        (editAbsenceNote && absenceNoteObject?.id) ?
          updateAbsenceNote(appointmentId, updatedAbsenceNote, absenceNoteObject?.id).then((result) => { afterSavingAbsenceNote(result) })
          :
          postAbsenceNote(appointmentId, addAbsenceNote).then((result) => { afterSavingAbsenceNote(result) })
      }

    }
  };
  const afterSavingAbsenceNote = (result: IAbsenceNotes) => {
    let from = moment(result.from?.toString().substring(0, 10)).format("MM-DD-YYYY")
    let to = moment(result.to?.toString().substring(0, 10)).format("MM-DD-YYYY")
    setToggleAbsenceNotesModal(false);
    setAbsenceNote("Absence Note : " + from + " - " + to);
    setAddAbsenceNote({});
    setAbsenceNoteObject(result);
  }
  const renderEditAbsenceNoteButton = () => {
    return (
      <Button
        type="button"
        color="link"
        data-toggle="tooltip"
        data-original-title="Edit Absence Note"
        onClick={() => { setToggleAbsenceNotesModal(true); setEditAbsenceNote(true); }}
      >
        <span className="sr-only">Edit</span>{" "}
        <i className="fa fa-edit"></i>
      </Button>
    )
  }
  const renderAbsenceNotes = () => (
    <>
      <Row className="ml-2">
        <h5 className="mb-3">Absence Notes</h5>
      </Row>
      {absenceNote ? (
        <>
          <Row className="ml-2">
            <ListGroup>
              {/* {absenceNote.map((item) => ( */}
              <ListGroupItem style={{ padding: "10px" }}>
                {absenceNote}
                {appointment?.status !== 'Archived' ? renderEditAbsenceNoteButton() : null}
              </ListGroupItem>
              {/* ))} */}
            </ListGroup>
          </Row>
        </>
      ) : (
        <>
          <Label className="ml-2">No absence notes</Label>
          {(appointment?.status === "Completed" ||
            appointment?.status === "Started") &&
            <Row className="ml-2">
              <Button size="sm" outline color="primary" className="w-50 m-auto"
                style={{ marginRight: "auto" }} onClick={() => setToggleAbsenceNotesModal(true)}>
                Add Absence Note
              </Button>
            </Row>
          }
        </>
      )}
      <Modal isOpen={toggleAbsenceNotesModal}>
        <ModalHeader>{"Add Absence Note"}</ModalHeader>
        <ModalBody>{renderAbsenceNotesModal()}</ModalBody>
      </Modal>
    </>
  );

  const renderDiagnosisCPT = () => {
    if (appointment?.insurance || appointment?.insuranceAttachments) {
      return (
        <>



          <PhysicianDiagnosis appointment={appointment} />
          <br />
          <PhysicianCPT appointment={appointment} />
          <hr />

        </>)
    }

  }
  return (
    <>
      <BigCardFrame
        flowNav={renderFlowNav()}
        navItem={navItem}
        bodyClassName="tc-bg"
        // smallCard={getSmallCard()}
        modal={getModals()}
        horizontal
      >
        {appointment &&

          <CardBody>
            <div className="d-flex px-2">
              <h5 className="mb-0">Appointment Details</h5>
              <Button color="link" size="sm" className="ml-auto" onClick={() => navigate(Routes.homePhysician)}>
                Back
              </Button>
            </div>
            <small className="p-2">{"Service ID: " + appointmentId}</small>
            <hr className="mt-2 mb-4"></hr>
            <div>
              {renderHeaderInfo()}
              {renderPatientDetails()}
              <hr />
              {renderAppointmentDetails()}
              <hr />
              {renderTreatmentPlan()}
              <hr />

              {renderAbsenceNotes()}
            </div>
          </CardBody>

        }
      </BigCardFrame>
      <rfb.Container>
        <rfb.Button
          tooltip="Chat with Patient"
          icon="fas fa-2x fa-comments"
          onClick={toggleChatModal}
          styles={{ color: "#007bff" }} />
      </rfb.Container>
      {renderChatModal()}
    </>
  );
};
