import * as React from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { Routes } from "../../config/Routes";
import { UserConfig } from "../../config/userConfig";
import { BigCardFrame } from "../../components/layout/BigCardFrame";
import {
  getAllConditionsLookup,
  setPatientMedicalHistory,
  addSelfReportedMedicationService,
  removeSelfReportedMedicationService,
  logAgentUpdatingPatientMedicalHistory,
  editMedicationsAllergyStatus,
} from "../../core/service/services";
import { IQuickMedicalHistory } from "./MedicalHistortTypes";
import { TextField } from "../../components/commons/TextField";
import { ApiServiceError } from "../../core/service/ServiceCall";
import { toastError, navItem } from "../../App";
import PreferredPharmacy from "../../components/patientSettings/PreferredPharmacy";
import { IPharmacySearch } from "../../components/patientSettings/PatientSettings";
import { FormGroup, Label, CustomInput, Row, Col } from "reactstrap";
import { SelfReportedMedications } from "./SelfReportedMedications";
import { ISelfReportedMedicationsAction } from "./MedicalHistory";
import { CardBody } from "reactstrap";
import MedicalAllergiesDoseSpotForm, {
  IAddAllergy,
} from "../../components/medicalHistory/MedicationAllergiesDoseSpot";
import MedicationsAllergiesList from "../../components/medicalHistory/MedicationsAllergiesList";
interface IQuickMedicalHistoryProps extends RouteComponentProps {}

export const QuickMedicalHistory: React.FC<IQuickMedicalHistoryProps> = (
  props
) => {
  const [pharmacyNameInput, pharmacyPhoneInput, pharmacyAddressInput] = [
    React.useRef<TextField>(null),
    React.useRef<TextField>(null),
    React.useRef<TextField>(null),
  ];
  const [stageState, setStageState] = React.useState<number>(1);
  // const previousStageState = usePrevious(stageState)
  const [userInfo] = React.useState(UserConfig.getUserInfo());

  const [historyState, setHistoryState] = React.useState<IQuickMedicalHistory>(
    {}
  );
  const [conditionsState, setConditionsState] = React.useState<
    { id: number; name: string; checked: boolean }[]
  >([]);
  const [currentPreviousconditionsStatus, setCurrentPreviousconditionsStatus] =
    React.useState<boolean>();
  const [selfReportedMedicationsActions, setSelfReportedMedicationsActions] =
    React.useState<ISelfReportedMedicationsAction[]>([]);
  const [medicationAllergyStatus, setMedicationAllergyStatus] =
    React.useState<boolean>(true);
  const [newMedicationsAllergyList, setnewMedicationsAllergyList] =
    React.useState<IAddAllergy[]>();

  // state helpers
  const setTakingMeds = (taking: boolean) => {
    historyState.medications = historyState.medications || {
      taking: taking,
      currentMedications: [""],
    };
    historyState.medications.taking = taking;
    setHistoryState({ ...historyState });
  };
  const addMedication = () => {
    historyState.medications?.currentMedications.push("");
    setHistoryState({ ...historyState });
  };

  const changeGender = (gender: "m" | "f") => {
    historyState.gender = historyState.gender || {
      gender: "m",
      breastfeeding: false,
      pregnant: false,
    };
    historyState.gender.gender = gender;
    setHistoryState({ ...historyState });
  };
  const changeBreastfeeding = (feeding: boolean) => {
    historyState.gender!.breastfeeding = feeding;
    setHistoryState({ ...historyState });
  };
  const changePreggnant = (pregnent: boolean) => {
    historyState.gender!.pregnant = pregnent;
    setHistoryState({ ...historyState });
  };

  const changeHeightFt = (ft: string) => {
    historyState.measurements = historyState.measurements || {};
    historyState.measurements!.heightFt = Number.parseFloat(ft);
    setHistoryState({ ...historyState });
  };

  const changeHeightIn = (In: string) => {
    historyState.measurements = historyState.measurements || {};
    historyState.measurements!.heightIn = Number.parseFloat(In);
    setHistoryState({ ...historyState });
  };

  const changeWeight = (Lbs: string) => {
    historyState.measurements = historyState.measurements || {};
    historyState.measurements!.weight = Number.parseFloat(Lbs);
    setHistoryState({ ...historyState });
  };

  const returnErrorMessage = (error: any) => {
    let errorObj = JSON.parse(JSON.stringify(error));
    if (errorObj && errorObj.json.metaData) {
      let errorMessageStr = errorObj.json.metaData.Message;
      return (
        errorMessageStr + " Please click Back to Provider & Pharmacy section"
      );
    } else {
      return error.message ? error.message : error;
    }
  };

  const moveToStage = async (
    stage: number,
    chosenPharmacy?: IPharmacySearch
  ) => {
    if (stageState == 1) {
      if (chosenPharmacy !== undefined) {
        historyState.pharmacy = {
          name: chosenPharmacy?.name,
          phone: chosenPharmacy?.phone,
          address: chosenPharmacy?.address,
          pharmacyId: chosenPharmacy?.pharmacyId,
        };
        setHistoryState({ ...historyState });
      }
    }
    if (stageState == 2) {
      selfReportedMedicationsActions.forEach((item) => {
        if (item.action === "add") {
          addSelfReportedMedicationService(
           item.medication
          )
            .then((res) => {})
            .catch((err) => console.log(err));
        } else if (item.medication.id) {
          removeSelfReportedMedicationService(
            item.medication.id
          )
            .then((res) => {})
            .catch((err) => console.log(err));
        }
      });
    }
    if (stageState == 3) {
      historyState.medicationAllergy = newMedicationsAllergyList;
      setHistoryState({ ...historyState });
    }
    if (stageState == 4 && currentPreviousconditionsStatus !== undefined) {
      historyState.history = conditionsState
        .filter((c) => c.checked)
        .map((c) => c.id);
      setHistoryState({ ...historyState });
    }

    if (stageState == 5 && stage > 5) {
      // the user is clicking save
      let finalHistoryState = historyState;
      if (
        currentPreviousconditionsStatus !== undefined &&
        currentPreviousconditionsStatus === false
      ) {
        finalHistoryState.history = [];
        setHistoryState(finalHistoryState);
      }
      let body = {
        hasMedicationsAllergy: medicationAllergyStatus
      };
      if (medicationAllergyStatus !== undefined)
        editMedicationsAllergyStatus(body.hasMedicationsAllergy)
          .then((result: any) => {})
          .catch(() => {
            toastError("Medication Allergies status cannot be changed!");
          });

      setPatientMedicalHistory(finalHistoryState)
        .then(() => {
          setStageState(stage);
        })
        .catch((e) => {
          console.error(e);
          if (e instanceof ApiServiceError) {
            toastError(returnErrorMessage(e));
          } else {
            toastError("Operation experienced difficulty");
          }
        });
      if (UserConfig.getUserType() === "agent")
        await logAgentUpdatingPatientMedicalHistory();
    } else {
      setStageState(stage);
    }
  };

  // let items = conditionsState;
  React.useEffect(() => {
    getAllConditionsLookup().then((conditions) => {
      setConditionsState(
        conditions.map((c) => {
          return { checked: false, ...c };
        })
      );
    });
  }, []);

  React.useEffect(() => {
    if (stageState > 5) {
      navigate(Routes.homePatient);
    }
  });

  const flowNav = (
    <div className="flow-nav-tools">
      <a
        href="#"
        className={
          stageState == 1
            ? "btn btn-lg control-nav btn-outline-secondary opacity-0"
            : "btn btn-lg control-nav btn-outline-secondary"
        }
        style={{ marginLeft: "auto" }}
        onClick={(e) => {
          if (stageState > 1) {
            moveToStage(stageState - 1);
          }
        }}
      >
        Back
      </a>
      <span className="text-muted mt-2">Step {stageState} of 5</span>

      <a
        onClick={(e) => {
          moveToStage(stageState + 1);
          e.preventDefault();
          return false;
        }}
        href="#"
        style={{ marginRight: "auto" }}
        className={
          stageState == 5
            ? "btn control-nav btn-success btn-lg"
            : "btn control-nav btn-primary btn-lg"
        }
      >
        {" "}
        {stageState == 5 ? "Save" : "Next"}
      </a>
    </div>
  );
  const renderYesOrNoForPreviousConditions = () => {
    return (
      <FormGroup>
        <div id="intro">
          <Label for="intro">Do they have any previous conditions:</Label>
          <CustomInput
            onChange={(e: any) => {
              setCurrentPreviousconditionsStatus(true);
            }}
            type="radio"
            id="yes"
            name="intro"
            label="Yes"
            defaultChecked={false}
          />
          <CustomInput
            onChange={(e: any) => {
              setCurrentPreviousconditionsStatus(false);
            }}
            type="radio"
            id="no"
            name="intro"
            label="No"
            defaultChecked={false}
          />
        </div>
      </FormGroup>
    );
  };

  const renderMedicationAllergiesYesOrNo = () => {
    return (
      <Row>
        <Col sm={6}>
          <CustomInput
            onChange={(e: any) => {
              e.target.checked && setMedicationAllergyStatus(true);
            }}
            type="radio"
            id="yes"
            name="intro"
            label="Yes"
            defaultChecked={medicationAllergyStatus}
          />
        </Col>
        <Col sm={6}>
          <CustomInput
            onChange={(e: any) => {
              e.target.checked && setMedicationAllergyStatus(false);
            }}
            type="radio"
            id="no"
            name="intro"
            label="No"
            defaultChecked={
              medicationAllergyStatus === undefined
                ? false
                : !medicationAllergyStatus
            }
          />
        </Col>
      </Row>
    );
  };

  const saveNewMedicationAllergies = (allergy: IAddAllergy) => {
    newMedicationsAllergyList
      ? setnewMedicationsAllergyList([allergy, ...newMedicationsAllergyList])
      : setnewMedicationsAllergyList([allergy]);
  };
  const onMedicationAllergyRemove = (e: IAddAllergy) => {
    let newList = newMedicationsAllergyList?.filter(
      (med) => med.Code != e.Code
    );
    setnewMedicationsAllergyList(newList);
  };
  const renderPreviousConditions = () => {
    return (
      <>
        <Row>
          <Col sm={6}>{renderYesOrNoForPreviousConditions()}</Col>
        </Row>
        <div className="row">
          {currentPreviousconditionsStatus
            ? conditionsState.map((item, index) => {
                return (
                  <div className="col-sm-6 col-md-3 col-lg-6" key={index}>
                    <div className="mb-2">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id={"item" + item.id}
                          key={"item" + item.id}
                          className="custom-control-input"
                          value="Coronary Artery Disease (Heart Attack)"
                          defaultChecked={item.checked}
                          onChange={(e) => {
                            item.checked = e.target.checked;
                            setConditionsState(conditionsState);
                          }}
                        />
                        <label
                          htmlFor={"item" + item.id}
                          className="custom-control-label"
                        >
                          {item.name}
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </>
    );
  };

  return (
    <BigCardFrame
      flowNav={flowNav}
      // modal={modal}
      navItem={navItem}
      bodyClassName="tc-bg"
    >
      <>
        {
          // Card 1
          stageState == 1 && (
            <div className="card-body">
              <div className="d-flex px-2">
                <h5 className="mb-0">
                  Medical Profile &gt; Provider &amp; Pharmacy
                </h5>
                <a
                  href="#"
                  className="btn btn-link btn-sm ml-auto"
                  onClick={(e) => {
                    moveToStage(6);
                    e.preventDefault();
                    return false;
                  }}
                >
                  Skip
                </a>
              </div>
              <hr className="mt-2 mb-4" />

              <div className="container">
                <h5 className="card-title">
                  Welcome {userInfo?.firstname ?? ""}! Before we get started, we
                  need to find out a little more about you and your medical
                  profile.{" "}
                </h5>

                <h6 className="mb-3">Preferred Pharmacy</h6>
                <div className="row">
                  <PreferredPharmacy
                    info={{
                      contact: {
                        firstname: userInfo?.firstname,
                        lastname: userInfo?.lastname,
                      },
                    }}
                    physician={false}
                    fetch={(e: any) => {
                      console.log(e);
                    }}
                    onRegister={true}
                    registerPharmacy={(chosenPharmacy: IPharmacySearch) => {
                      moveToStage(stageState + 1, chosenPharmacy);
                      return false;
                    }}
                  />
                  {/* <div className="col-sm-6 mb-3">

                                <TextField ref={pharmacyNameInput} type="text" labelText="Preferred Pharmacy" placeholder="Walgreens" defaultValue={historyState.pharmacy?.name} />
                            </div>
                            <div className="col-sm-6 mb-3">

                                <TextField ref={pharmacyPhoneInput} type="phone" labelText="Pharmacy Phone" placeholder="312-555-1212" defaultValue={historyState.pharmacy?.phone} />

                            </div>
                            <div className="col-sm-12 mb-3">
                                <TextField ref={pharmacyAddressInput} type="text" labelText="Pharmacy Address" placeholder="151 N State St fl 1st, Chicago, IL 60601" defaultValue={historyState.pharmacy?.address} />

                            </div> */}
                </div>
              </div>
            </div>
          )
        }
        {
          // Card 2
          stageState == 2 && (
            <div className="card-body">
              <div className="d-flex px-2">
                <h5 className="mb-0">Medical Profile &gt; Medications</h5>
                <a
                  href="#"
                  className="btn btn-link btn-sm ml-auto"
                  onClick={(e) => {
                    moveToStage(6);
                    e.preventDefault();
                    return false;
                  }}
                >
                  Skip
                </a>
              </div>
              <hr className="mt-2 mb-4" />
              <div className="container">
                <h5 className="card-title">
                  Before we get started, we need to find out a little more about
                  you and your medical history.{" "}
                </h5>
                <h6 className="mb-3">
                  Are you Currently Taking any Medications?
                </h6>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-2">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          name="takingMedsRadios"
                          id="takingMedsRadios1"
                          className="custom-control-input"
                          value="No"
                          onChange={(e) => {
                            if (e.target.checked) setTakingMeds(false);
                          }}
                          defaultChecked={
                            (historyState.medications?.taking || false) ===
                            false
                          }
                        />{" "}
                        <label
                          htmlFor="takingMedsRadios1"
                          className="custom-control-label"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-2">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          name="takingMedsRadios"
                          id="takingMedsRadios2"
                          className="custom-control-input"
                          value="Yes"
                          onChange={(e) => {
                            if (e.target.checked) setTakingMeds(true);
                          }}
                          defaultChecked={
                            historyState.medications?.taking || false
                          }
                        />{" "}
                        <label
                          htmlFor="takingMedsRadios2"
                          className="custom-control-label"
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                {historyState.medications?.taking && (
                  <div className="mt-2">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-2">
                          {/* <ol>
                                        {
                                            historyState.medications?.currentMedications.map((item, index) => {
                                                return (
                                                    <li className="pl-3">
                                                        <TextField ref={pharmacyNameInput} type="text" labelText="Enter Your Medication" placeholder="Please enter your medication" defaultValue={item}
                                                            textChanged={(t) => { historyState.medications!.currentMedications[index] = t; }}
                                                        />
                                                    </li>
                                                )
                                            })}
                                    </ol> */}
                          <SelfReportedMedications
                            setSelfReportedMedicationsActions={
                              setSelfReportedMedicationsActions
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        }
        {stageState == 3 && (
          <div>
            <CardBody>
              <div className="d-flex px-2">
                <h5 className="mb-0">Medical Profile &gt; Previous History</h5>
                <a
                  href="#"
                  className="btn btn-link btn-sm ml-auto"
                  onClick={(e) => {
                    moveToStage(6);
                    e.preventDefault();
                    return false;
                  }}
                >
                  Skip
                </a>
              </div>
              <hr className="mt-2 mb-4" />
              <div className="container">
                <h5 className="card-title">
                  Before we get started, we need to find out a little more about
                  you and your medication allergies history.{" "}
                </h5>
                <h6 className="mb-3">Are you allergic to any medication?</h6>
                {renderMedicationAllergiesYesOrNo()}
                <hr />
                {medicationAllergyStatus && (
                  <MedicalAllergiesDoseSpotForm
                    setNewMedication={saveNewMedicationAllergies}
                  />
                )}
                <MedicationsAllergiesList
                  onRemove={onMedicationAllergyRemove}
                  onEdit={(e: any) => {
                    console.log(e);
                  }}
                  maxHeight={150}
                  medicationsAllergy={newMedicationsAllergyList}
                />
              </div>
            </CardBody>
          </div>
        )}

        {
          // Card 4
          stageState == 4 && (
            <div className="card-body">
              <div className="d-flex px-2">
                <h5 className="mb-0">Medical Profile &gt; Previous History</h5>
                <a
                  href="#"
                  className="btn btn-link btn-sm ml-auto"
                  onClick={(e) => {
                    moveToStage(6);
                    e.preventDefault();
                    return false;
                  }}
                >
                  Skip
                </a>
              </div>
              <hr className="mt-2 mb-4" />
              <div className="container">
                <h5 className="card-title">
                  Before we get started, we need to find out a little more about
                  you and your medical history.{" "}
                </h5>
                <h6 className="mb-3">Previous Conditions</h6>
                {renderPreviousConditions()}

                <hr />
              </div>
            </div>
          )
        }

        {stageState == 5 && (
          <div className="card-body">
            <div className="d-flex px-2">
              <h5 className="mb-0">
                Medical Profile &gt; Measurements and Gender
              </h5>
              <a
                href="#"
                className="btn btn-link btn-sm ml-auto"
                onClick={(e) => {
                  moveToStage(6);
                  e.preventDefault();
                  return false;
                }}
              >
                Skip
              </a>
            </div>
            <hr className="mt-2 mb-4" />
            <div className="container">
              <h5 className="card-title">
                Before we get started, we need to find out a little more about
                you and your medical history.{" "}
              </h5>
              <h6 className="mb-2">Your Measurements</h6>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-2">
                    <TextField
                      type="number"
                      labelText="Height (Ft)"
                      defaultValue={
                        "" + (historyState?.measurements?.heightFt || "")
                      }
                      textChanged={(t) => changeHeightFt(t)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2">
                    <TextField
                      type="number"
                      labelText="Height (In)"
                      defaultValue={
                        "" + (historyState?.measurements?.heightIn || "")
                      }
                      textChanged={(t) => changeHeightIn(t)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2">
                    <TextField
                      type="number"
                      labelText="Weight (Lbs)"
                      defaultValue={
                        "" + (historyState?.measurements?.weight || "")
                      }
                      textChanged={(t) => changeWeight(t)}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <h6 className="mb-2">Your Gender</h6>
              <div className="row my-3">
                <div className="col-md-6">
                  <div className="mb-4">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        name="gendersRadios"
                        id="gendersRadios1"
                        className="custom-control-input"
                        value="Male"
                        // defaultChecked={(historyState.gender?.gender  || "m" )== 'm'}
                        onChange={(e) => {
                          if (e.target.checked) changeGender("m");
                        }}
                      />
                      <label
                        htmlFor="gendersRadios1"
                        className="custom-control-label"
                      >
                        Male
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        name="gendersRadios"
                        id="gendersRadios2"
                        className="custom-control-input"
                        value="Female"
                        defaultChecked={
                          (historyState.gender?.gender || "m") == "f"
                        }
                        onChange={(e) => {
                          if (e.target.checked) changeGender("f");
                        }}
                      />
                      <label
                        htmlFor="gendersRadios2"
                        className="custom-control-label"
                      >
                        Female
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {historyState.gender?.gender == "f" && (
                <div className="my-3">
                  <h6 className="mt-3 mb-2">Are You Pregnant?</h6>{" "}
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="pregnantRadios"
                            id="pregnantRadios1"
                            className="custom-control-input"
                            value="No"
                            onChange={(e) => {
                              if (e.target.checked) changePreggnant(false);
                            }}
                            defaultChecked={!historyState.gender.pregnant}
                          />
                          <label
                            htmlFor="pregnantRadios1"
                            className="custom-control-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="pregnantRadios"
                            id="pregnantRadios2"
                            className="custom-control-input"
                            value="Yes"
                            defaultChecked={historyState.gender.pregnant}
                            onChange={(e) => {
                              if (e.target.checked) changePreggnant(true);
                            }}
                          />
                          <label
                            htmlFor="pregnantRadios2"
                            className="custom-control-label"
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h6 className="mb-2">Are You Breastfeeding?</h6>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="breastFeedingRadios"
                            id="breastFeedingRadios1"
                            className="custom-control-input"
                            value="No"
                            onChange={(e) => {
                              if (e.target.checked) changeBreastfeeding(false);
                            }}
                            defaultChecked={!historyState.gender.breastfeeding}
                          />
                          <label
                            htmlFor="breastFeedingRadios1"
                            className="custom-control-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="breastFeedingRadios"
                            id="breastFeedingRadios2"
                            className="custom-control-input"
                            value="Yes"
                            onChange={(e) => {
                              if (e.target.checked) changeBreastfeeding(true);
                            }}
                            defaultChecked={historyState.gender.breastfeeding}
                          />
                          <label
                            htmlFor="breastFeedingRadios2"
                            className="custom-control-label"
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <hr />
            </div>
          </div>
        )}
      </>
    </BigCardFrame>
  );
};