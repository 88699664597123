import React, { useEffect } from 'react';
var postscribe = require('postscribe');
interface IProps{
    id:string
}
export const LegitScript=(props:IProps)=>{
    useEffect(()=> {
        
        postscribe(`#${props.id}`, '<script src=https://static.legitscript.com/seals/9513825.js><\/script>', {
            done: function() {
                console.info('Script has been executed.');
            }
        })
    },[])
    return(
        <div id={props.id}></div>
    )
}