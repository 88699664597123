import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

interface IProps {
    service:string,
    drug1:string,
    drug2:string,
    description:string,
    isOpen:boolean,
    toggle:any
}
const DrugInteractionDescriptionModal = (props: IProps) => {
  const toggle = () => props.toggle();
  return (
    <div>
      <Modal isOpen={props.isOpen}>
        <ModalHeader>
            {props.service} between {props.drug1} and {props.drug2}
        </ModalHeader>
        <ModalBody style={{overflow:"auto", maxHeight:"300px"}}>
          {props.description}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DrugInteractionDescriptionModal;
