import React, { useState, useEffect, useRef } from "react";
import { Row, Col, FormGroup, Input, Label, Form, Button, Modal, ModalHeader, ModalBody,
  ModalFooter, Alert, ListGroup, ListGroupItemHeading, ListGroupItemText, UncontrolledCollapse, ListGroupItem} from "reactstrap";
import _ from "lodash";
import { IPatientInfoSettings, ISearchPharmacyParams, IPharmacySearch } from "./PatientSettings";
import { requestPatientInfo, searchPharmacy, LoadingState, updateAppointmentPharmacy, getUSStatesLookup } from "../../core/service/services";
import { IUSState } from "../../page/settings/PhysicianSettings";
import { LoadingButton } from "../commons/LoadingButton";
import { toastError } from "../../App";

interface IProps {
  info: IPatientInfoSettings;
  pharmacy?:any,
  fetch: any;
  physician: boolean;
  appointmentId?: string;
  onRegister:boolean;
  registerPharmacy?:any;
  readOnly?: boolean;
  appointmentStatus?:string
}

export interface ICurrentPharmacyProps {
  id?: string;
  name?: string;
  address?: string;
  phone?: string;
}

export const renderCurrentPharmacyInAppointmentDetails = (props: ICurrentPharmacyProps) => {
  return props ? (
    <dl>
      <dt>
        <Label>{props.name}</Label>
      </dt>

      <dd>{props.address}</dd>
      <dd>{props.phone}</dd>
    </dl>
  ) : null;
};

export const PreferredPharmacy = (props: IProps) => {
  const [modal, setModal] = useState(false);
  const [pharmacies, setPharmacies] = useState<IPharmacySearch[]>();
  const toggle = () => setModal(!modal);
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.nuteral);
  const [usStates, setUsStates] = useState<IUSState[]>();
  const [searchValues, setSearchValues] = useState<ISearchPharmacyParams>({});
  const [submitLoading, setSubmitLoadding] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    initializeStates();
  }, []);

  const initializeStates = () => {
    setPharmacies([]);
    setSearchValues({});
    setIsLoading(LoadingState.nuteral);
    fetchUSStates();
  };

  async function fetchUSStates() {
    const states = await getUSStatesLookup();
    setUsStates(states)
  }

  const onClickEditButton = (e: any) => {
    toggle();
    e.preventDefault();
  };

  const onclickSearchButton = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    search(e);
  };
  useEffect(() => {
    if (listRef.current && observer.current === null) {
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            loadMore(); // Pass currentPage to loadMore
          }
        },
        { threshold: 1.0 }
      );
      observer.current.observe(listRef.current);
    }
    return () => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current=null

      }
    };
  }, [hasMore,page]);

  const loadMore = () => {
    searchPharmacy(props.physician, {...searchValues,pageNumber:page}).then((result) => {
      setPharmacies([...pharmacies!,...result.items]);
      if(result.totalPages==page){
        setHasMore(false)
      }
      else{
        setHasMore(true)
        setPage(prevPage => prevPage + 1);
      }
  
    })
  };
  const search = async (e: React.FormEvent<HTMLFormElement>) => {
    if(!_.isEmpty(searchValues)){    
    setIsLoading(LoadingState.loading);
    searchPharmacy(props.physician, searchValues).then((result) => {
      if(result.pageNum===result.totalPages){
        setHasMore(false)
      }else{
        setHasMore(true)
        setPage(prevPage => prevPage + 1);
      }        
      setPharmacies(result.items);
        result.items.length > 0
          ? setIsLoading(LoadingState.succeed)
          : setIsLoading(LoadingState.failed);
      }).catch(() => {
        setIsLoading(LoadingState.failed);
      });
    } else{
      toastError("Please Enter at least one field!")
    }
  };

  const renderLoadingState = () => {
    if (isLoading === LoadingState.failed) {
      return (
        <Alert className="mt-2" color="danger">
          No matching found, please try again!
        </Alert>
      );
    }
    return null;
  };

  const returnErrorMessage=(error:any)=>{
    let errorObj = JSON.parse(JSON.stringify(error));
    if(errorObj && errorObj.json){
      let errorMessageStr = errorObj.json.message;
      let errorMessage="";
      if(errorMessageStr.split(',').length>1)
        errorMessage = errorMessageStr.split(',')[1];
      else errorMessage = errorMessageStr
      return errorMessage;
    }
    else{
      return error;
    }
  }
  const submitPharmacy = (chosenPharmacy: IPharmacySearch, id?: string) => {
    let patientInfo: IPatientInfoSettings = {};
    setSubmitLoadding(true);
    patientInfo.pharmacy = {
      ...chosenPharmacy,
    };
    if(props.onRegister){
      props.registerPharmacy(chosenPharmacy);
    }
    else if (!props.physician) {

      requestPatientInfo("POST", patientInfo)
        .then(() => {
          afterSubmit();
        })
        .catch((err) => {
          console.log(err)
          toastError(returnErrorMessage(err));
          setSubmitLoadding(false);
        });
    } else {
      //Doctor updating Appointment Pharmacy
      if (props.appointmentId) {
        updateAppointmentPharmacy(props.appointmentId,id? {...chosenPharmacy,id:id}:chosenPharmacy)
          .then((res) => {
            afterSubmit();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  
  const afterSubmit = () => {
    setSubmitLoadding(false);
    props.fetch();
    initializeStates();
    toggle();
  };
  
  const onCancelClick=()=>{
    toggle();
    initializeStates();
  }
  const getValue = (
    arr: [string, FormDataEntryValue][],
    field: string
  ): any => {
    let value = arr.filter(function (record) {
      return record[0] === field;
    });

    return value[0][1];
  };

  const renderEditButton = () => {
    return (
      <Button color="link" size="sm" className="pt-0" onClick={onClickEditButton}>
        <span className="sr-only">Edit</span>{" "}
        <i className="fa fa-edit fa-lg"></i>
      </Button>
    );
  };
  
  const renderStates = () => {
    return usStates?.map((state, idx) => {
      return (
        <option key={idx} value={state.shortName}>
          {state.name}
        </option>
      );
    });
  };
  
  const renderForm = () => {
    return (
      <fieldset disabled={isLoading === LoadingState.loading}>
      <Form id="preferredPharmacy" onSubmit={(e) => onclickSearchButton(e)}>
        <Row form>
          <Col md={4}>
            <FormGroup className="form-label-group">
              <Label for="exampleCity">City</Label>
              <Input type="text" name="city" id="exampleCity" onChange={(e)=>setSearchValues({...searchValues, city:e.target.value})}/>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Input defaultValue="" type="select" name="state" id="exampleState"
                onChange={(e)=>setSearchValues({...searchValues, state:e.target.value})}
              >
                <option value=''>
                  State
                </option>
                {renderStates()}
              </Input>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className="form-label-group">
              <Label for="exampleZip">Zip</Label>
              <Input type="text" name="zip" id="exampleZip" onChange={(e)=>setSearchValues({...searchValues, zip:e.target.value})}/>
            </FormGroup>
          </Col>
        </Row>

        <UncontrolledCollapse toggler="#toggler">
          <Row>
            <Col sm="6" mb="3">
              <FormGroup className="form-label-group">
                <Input type="text" id="name" name="name" onChange={(e)=>setSearchValues({...searchValues, name:e.target.value})}></Input>
                <Label for="name">Pharmacy Name</Label>
              </FormGroup>
            </Col>

            <Col sm="6" mb="3">
              <FormGroup className="form-label-group">
                <Input type="tel" id="phone" name="phone" placeholder="312-555-1212"
                  onChange={(e)=>setSearchValues({...searchValues, phoneOrFax:parseInt(e.target.value)})}
                ></Input>
                <Label for="phone">Pharmacy Phone or Fax</Label>
              </FormGroup>
            </Col>

            <Col sm="12" mb="3" md="6">
              <FormGroup className="form-label-group">
                <Input type="text" id="address" name="address" onChange={(e)=>setSearchValues({...searchValues, address:e.target.value})}></Input>
                <Label for="preferredPharamacyLocation">Pharmacy Address</Label>
              </FormGroup>
            </Col>

            {/* <Col md={6}>
              <FormGroup className="form-label-group">
                <Label for="fax">Fax</Label>
                <Input type="tel" name="fax" id="fax" />
              </FormGroup>
            </Col> */}
            <Col md={6}>
              <FormGroup>
                <Label for="speciality">Select a Specialty</Label>
                <Input type="select" name="specialty" id="speciality" onChange={(e)=>setSearchValues({...searchValues, specialty:e.target.value})}>
                <option value="">None</option>
                  <option value="Retail">Retail</option>
                  <option value="EPCS">EPCS</option>
                  <option value="TwentyFourHourPharmacy">
                    24 Hour Pharmacy
                  </option>
                  <option value="LongTermCarePharmacy">
                    Long-Term Care Pharmacy
                  </option>
                  <option value="MailOrder">Mail Order</option>
                  <option value="SpecialtyPharmacy">Specialty Pharmacy</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </UncontrolledCollapse>

        <div style={{ textAlign: "center" }}>
          <Button color="link" size="sm" id="toggler">
            <i className="fas fa-chevron-circle-down"></i> Advanced Search
          </Button>
        </div>

        <LoadingButton size="sm" isLoading={isLoading === LoadingState.loading} type="submit" for="preferredPharmacy" color="primary"
           block={true} className="w-50 m-auto text-uppercase mt-4">
          Search
        </LoadingButton>
      </Form>
      </fieldset>
    );
  };

  const renderSearchItems = () => {
    let content: any[] = new Array();
    pharmacies?.map((record) => {
      content.push(
        <ListGroupItem disabled={submitLoading} color="primary" action
          onClick={(e) => {
            e.preventDefault();
            submitPharmacy(record, props.info.pharmacy?.id);
          }}
        >
          <ListGroupItemHeading>{record.name}</ListGroupItemHeading>
          <ListGroupItemText>
            {record.address}
            <br />
            {record.city}
            <br />
            {record.phone}
          </ListGroupItemText>
        </ListGroupItem>
      );
    });

    return (
      <ListGroup
        className={
          "mt-2 p-2" + (LoadingState.succeed != isLoading ? " d-none" : "")
        }
        style={{ maxHeight: "20rem", overflow: "auto" }}
      >
        <br />
        {content}
        <div ref={listRef} />

      </ListGroup>
    );
  };

  const renderModal = () => {
    return (
      <div>
        <Modal isOpen={modal} toggle={toggle} className="preferedPharmacy" size="lg">
          <ModalHeader toggle={toggle}>
            <h5>Search for Pharmacy</h5>
            <small>Search using any of the criteria below</small>
          </ModalHeader>
          <ModalBody>
            {renderForm()}
            {renderLoadingState()}
            {renderSearchItems()}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onCancelClick}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };
  
  const renderCurrentPharmacy = () => {
    let pharmacy: ICurrentPharmacyProps = props.pharmacy? {...props.pharmacy}: {
      ...props.info.pharmacy,
    };
    return renderCurrentPharmacyInAppointmentDetails(pharmacy);
  };
  
  return (
    <div>
      {props.onRegister ? (
        <>
        {renderForm()}
        {renderLoadingState()}
        {renderSearchItems()}
        </>
      ) : (
        <>
          <h6 className="mb-3">
            Pharmacy
            {!props.readOnly && renderEditButton()}
          </h6>
          {renderModal()}
          {renderCurrentPharmacy()}
        </>
      )}
    </div>
  );
};
export default PreferredPharmacy;
