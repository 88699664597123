import * as React from 'react';
import { Routes } from '../../config/Routes';
import { Link } from '@reach/router';
import { IBookingProps } from './AppointmentModels';
import { navItem } from '../../App';
import Asterisk from '../../components/commons/Asterisk';
import { postPatientDependent } from '../../core/service/services';
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Container,
  Input,
  Row,
  Col,
  Label,
  CustomInput,
  Form,
} from 'reactstrap';
import { IDPatient } from '../../components/patientSettings/PatientSettings';
import { SideCardFrame } from '../../components/layout/SideCardFrame';
import { useState, useEffect } from 'react';
import AddNewDependentModal from './choosePatient/AddNewDependentModal';
import { useAppDispatch, useAppSelector } from './../../redux/hooks';
import {
  dependentAdded,
  selectDependents
} from './../../redux/slices/general/patientInfoSlice'
import {
  patientChosen,
  dependentUpdated,
  dependentCleared,
  selectApptForUser,
  selectDependent
} from '../../redux/slices/booking/choosePatientStepSlice';
import {
  whoSectionUpdated,
  nextStepInitiated
} from '../../redux/slices/booking/bookingSlice'
import { ApptForUser } from '../../redux/types/enums/bookingEnums';
import { IDependent } from '../../redux/types/interfaces/patientInfoInterfaces';
import { IWho } from './../../redux/types/interfaces/bookingInterfaces';
import { ChoosePatientErrorMessages } from '../../core/service/ErrorMessages';
import { isPatientChosen } from '../../core/service/Validators';
import { areThereErrors } from '../../core/service/ErrorService';
import { errorsDisplayed, selectShowErrors } from '../../redux/slices/booking/errorSlice';
import CustomErrorMessage from '../../components/CustomErrorMessage';

enum SelectDependentOption {
  Placeholder = -1,
  AddNewDependent = -2
}

const BookingChoosePatientStep = ({
  smallCard
}: IBookingProps) => { //TO-DO change interface
  const dependents = useAppSelector(selectDependents);
  const apptForUser = useAppSelector(selectApptForUser);
  const dependent = useAppSelector(selectDependent);
  const showErrors = useAppSelector(selectShowErrors);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [choiceErrorMsg, setChoiceErrorMsg] = useState<string>('');
  const [addingDependent, setAddingDependent] = useState<boolean>(false)

  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const updateCreateApptPayload = () => {
    let newWhoSection: IWho = {
      patientId: updatePatientId(),
      isRequestedFor: apptForUser !== undefined
    }
    dispatch(whoSectionUpdated(newWhoSection))
  };

  const updatePatientId = () => {
    if (apptForUser === ApptForUser.Dependent) {
      return dependent?.id
    }
  }

  const handleDependentSelection = (e: any) => {
    e.preventDefault();
    let value = Number.parseInt(e.target.value);
    if (value === SelectDependentOption.AddNewDependent) {
      setAddingDependent(true)
      setOpenModal(!openModal);
    }
    else if (dependents?.length !== 0) {
      const selectedDependent = dependents?.find((dependent: IDependent) => value === dependent.id)
      dispatch(dependentUpdated(selectedDependent))
    }
  }

  const onSubmitingForm = (modal: IDPatient) => {
    return postPatientDependent(modal).then((res: IDPatient) => {
      let result = res as IDependent
      dispatch(dependentAdded(result))
      dispatch(dependentUpdated(result))
      setAddingDependent(false)
      return result;
    });
  };

  const handleNextClicked = () => {
    if (areThereErrors()) {
      if (apptForUser === ApptForUser.Dependent) {
        setChoiceErrorMsg(ChoosePatientErrorMessages.selectDependent)
      } else {
        setChoiceErrorMsg(ChoosePatientErrorMessages.selectPatient)
      }
      dispatch(errorsDisplayed(true));
    } else {
      updateCreateApptPayload()
      dispatch(errorsDisplayed(false));
      dispatch(nextStepInitiated());
    }
  };

  const renderFlowNav = () => {
    return (
      <div className='flow-nav-tools'>
        <span className='text-muted mt-2 ml-auto'>Step 1 of 7</span>
        <Button
          onClick={() => {
            handleNextClicked()
          }}
          color='primary'
          size='lg'
          className='control-nav mr-auto'
        >
          Next
        </Button>
      </div>
    );
  };
  const renderSelectDependentTitle = () => {
    if (dependents?.length === 0) {
      return "Book Appointment For a New Account Member (e.g. Spouse, Child)"
    }
    else {
      return "Book Appointment For Another Account Member (e.g. Spouse, Child)"
    }
  }
  const renderChoosePatient = () => {
    return (
      <Card
        className='my-xs-2 my-sm-2 my-md-2'
        id='contactTimingSelected'
        style={{ border: 'none' }}
      >
        <CardBody>
          <CardTitle>
            Select which patient is requesting service:
            <Asterisk />
          </CardTitle>
          <Col>
            <div className='d-block my-3 ml-3'>
              <Row>
                <CustomInput
                  name='apptForUser'
                  type='radio'
                  id='apptForUser'
                  checked={apptForUser === ApptForUser.Self}
                  onChange={() => {
                    dispatch(patientChosen(ApptForUser.Self))
                    dispatch(dependentCleared())
                  }}
                  required
                />
                <Label>Book Appointment For Me {renderAge()}</Label>
              </Row>
              <Row>
                <CustomInput
                  name='apptForDepedent'
                  type='radio'
                  id='apptForDepedent'
                  checked={apptForUser === ApptForUser.Dependent}
                  onChange={() => {
                    setChoiceErrorMsg('');
                    dispatch(patientChosen(ApptForUser.Dependent))
                  }}
                  required
                />
                <Label>{renderSelectDependentTitle()}</Label>
              </Row>
              <Row hidden={!apptForUser || apptForUser === ApptForUser.Self || dependents?.length === 0}>
                <Input
                  type='select'
                  className='form-control'
                  style={{ marginBottom: '10px', width: '80%' }}
                  id='patientSeekingContact'
                  value={dependent ? dependent.id : SelectDependentOption.Placeholder}
                  onChange={handleDependentSelection}
                  required
                >
                  <option value={SelectDependentOption.Placeholder} selected disabled>Select Account Member</option>
                  {dependents?.map((dependent, index) => {
                    return (
                      <option value={dependent.id || ''} key={index}>
                        {dependent.firstname} {dependent.lastname}
                      </option>
                    );
                  })}
                  <option value={SelectDependentOption.AddNewDependent} className='h5'>-- Add New Account Member --</option>
                </Input>
              </Row>
              <CustomErrorMessage
                invalid={!isPatientChosen(dependent, dependents, apptForUser) && !addingDependent}
                showValidation={showErrors}
                errorMessage={choiceErrorMsg}
              />
            </div>
          </Col>
          <AddNewDependentModal
            onSubmitingForm={onSubmitingForm}
            openModal={openModal}
            toggleModal={() => {
              setOpenModal(!openModal)
              setAddingDependent(false)
            }}
          />
        </CardBody>
      </Card>
    );
  };

  const renderAge = () => {
    return <text style={{ color: 'blue' }}>(18+)</text>
  }

  return (
    <SideCardFrame
      flowNav={renderFlowNav()}
      navItem={navItem}
      bodyClassName='tc-bg'
      smallCard={smallCard}
    >
      <CardBody>
        <Form>
          <div className='d-flex px-2'>
            <h5 className='mb-0'>Book an Appointment</h5>
            <Link
              to={Routes.homePatient}
              className='btn btn-link btn-sm ml-auto'
            >
              Cancel
            </Link>
          </div>
          <hr className='mt-2 mb-4'></hr>
          <Container>{renderChoosePatient()}</Container>
          <div className='flow-nav-tools'></div>
        </Form>
      </CardBody>
    </SideCardFrame>
  );
};

export default BookingChoosePatientStep;
