import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { UpcomingAppointments } from './UpcomingAppointments'
import { PastAppointments } from '../appointment/PastAppointments'
import { IMessage } from '../../chat-module/MessageModel'

interface IProps {
  onChatOpened:  (appointmentId: string, receiverName: string) => void;
  addedMessage?: IMessage,
  onMessageReceived?: (msg: IMessage) => void,
    appointmentToUpdate?: {id: string, count: number}

}

export const PatientAppointments: React.FC<IProps> = (props) => {
  return (
    <>
    <h5 className="text-white mx-3">
      <i className="fa fa-calendar"></i> Appointments
    </h5>
    <Card className="card-telecare mt-3 mb-5">
      <CardBody>
        <section className="section-content-block">
          <div className="section-content-body" id="apptBody">
            <UpcomingAppointments onChatOpened={props.onChatOpened}  onMessageReceived={props.onMessageReceived} addedMessage={props.addedMessage}  updatedAppointment={props.appointmentToUpdate}></UpcomingAppointments>
            <PastAppointments onChatOpened={props.onChatOpened}  onMessageReceived={props.onMessageReceived} addedMessage={props.addedMessage} ></PastAppointments>
          </div>
        </section>
      </CardBody>
    </Card>
    </>
  )
}