import React from "react";
import { IMessage } from "./MessageModel";
import moment from "moment";
import { Badge, Container } from "reactstrap";
import ReactLinkify from "react-linkify";

interface IProps {
  message: IMessage;
}

export const MessageReceived: React.FC<IProps> = props => {

  const renderMessage = () => {
    let formatedMessage = props.message.text;
    return <span className="highlight-front" style={{whiteSpace: "break-spaces"}}><ReactLinkify>{formatedMessage}</ReactLinkify></span>;
  };

  return (
    <Container>
      <h5
        className="d-flex justify-content-start "
        style={{ maxWidth: 400 }}
      >
        <Badge
          color="secondary"
          style={{ wordBreak: "break-word", maxWidth: 350, textAlign: "left" }}
        >
          <p
            style={{
              maxWidth: 490,
              margin: 5
            }}
          >
            {renderMessage()}
          </p>
          <small className="d-block mt-2">
            <span className="yesterday">{moment(props.message.createdAt).format('YYYY-MM-DD h:mm a')}</span>
          </small>
        </Badge>
      </h5>
    </Container>
  );
};
