import React, { useState, useEffect } from "react";
import { ModalBody, ModalHeader, Button, Modal, Label, FormGroup, Input } from "reactstrap";
import 'react-datepicker/dist/react-datepicker.css';
import { IUnlockReason, getUnlockAppointmentReasons } from "../../../core/service/services";
import { TextField } from "../../../components/commons/TextField";
import { toastError } from "../../../App";
import { AlertMessage } from "../../../core/alert/Alerts";

export interface IProps {
  unlock: (reason: IUnlockReason) => void;
  toggleModal: () => void;
  cancelAppt: () => void;
  isOpen: boolean;
}

export const UnlockAppointmentReasonModal: React.FC<IProps> = props => {
  const [reason, setReason] = useState<IUnlockReason>()
  const [allReasons, setAllreasons] = useState<IUnlockReason[]>([])
  const [otherIsChosen, setOtherIsChosen] = useState<boolean>(false)
  const [otherReason, setOtherReason] = useState<string>()
  const [alertOpen, setAlertOpen] = useState<boolean>(false)

  useEffect(() => {
    // setOpen(props.isOpen)
    if (props.isOpen) {
      getUnlockAppointmentReasons().then(res => {
        setAllreasons(res)
      })
    }
  }, [props.isOpen])

  const handleReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtherIsChosen(e.target.value === "other")
    if (e.target.value === "") {
      setReason(undefined)
      setOtherReason(undefined)
      return
    }
    for (let item of allReasons) {
      if (e.target.value === item.reasonId) {
        setReason(item)
        break
      }
    }
  }

  const submitData = (e: React.FormEvent) => {
    e.preventDefault()
    if (!otherIsChosen && reason) {
      props.unlock(reason)
      return
    } else if (otherIsChosen && otherReason && otherReason !== "") {
      props.unlock({reasonId: "other", reasonMessage: otherReason})
      return
    }
    toastError("Please type/choose a reason for unlocking the appointment")
  }

  const toggleAlertMessage = () => {
    setAlertOpen(!alertOpen)
  }

  const onCancelConfirmed = (confirmed: boolean) => {
    if (confirmed) {
      props.cancelAppt()
    }
    else {
      toggleAlertMessage()
    }
  }

  const toggleModal=()=>{
    props.toggleModal();
  }

  return (
    <Modal isOpen={props.isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
        <h5 id="rescheduleModal" className="modal-title">Please state unlock reason</h5>
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Select a reason</Label>
          <Input type="select" name="reasons" onChange={e => handleReasonChange(e)}>
            <option value="">Please Select</option>
            {allReasons.map(item => (
              <option value={item.reasonId}>
                {item.reasonMessage}
              </option>
            ))}
          </Input>
        </FormGroup>
        {otherIsChosen &&
        <TextField labelText="State unlock reason" type="text" textChanged={(text) => setOtherReason(text)} />
        }
        <AlertMessage isOpen={alertOpen} message={"Are you sure you want to cancel this appointment?"}
          options={["Yes, Cancel Appointment & Refund", "No, Keep Appointment"]} setResponse={onCancelConfirmed} toggleOpen={toggleAlertMessage} />
        <Button color="primary" size="sm" className="w-50 m-auto" onClick={submitData}>Unlock</Button>
        <Button color="danger" size="sm" className="w-50 m-auto" onClick={toggleAlertMessage}>Cancel Appointment</Button>
      </ModalBody>
    </Modal>
  );
};
