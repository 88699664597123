import React, {useState, useRef, useEffect} from 'react';
import useIsInViewport from "../../core/service/UseIsInViewport"

export const HowItWorksVideo = () => {
    const [isFacade, setIsFacade] = useState(true)
    const iframeSrc = "https://www.youtube.com/embed/NJsjH2Vi2IU";
    const ref = useRef(null);

    const isInViewport = useIsInViewport(ref);
    useEffect(()=> {
      if(isInViewport) {
        setIsFacade(false)
      }
    },[isInViewport])
    return(
        <div style={{borderRadius: "1rem", marginBottom: "3rem", flexDirection: "row", backgroundColor: "#fff" }}>
          <div style={{width: "100%", padding: "1.25rem", flex: "1 1 auto", minHeight: "1px"}}>
              <div className="card-block px-2" ref={ref} >
                {!isFacade && <iframe
                  id="youtube-iframe"
                  width="100%" 
                  height="400" 
                  src={iframeSrc}
                  // data-src={iframeSrc}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="How It works!"
                /> }
                {/* <video width="320" height="240">
                  <source src="https://www.telmdcare.com/c/video/MAinvideorevision280070.mp4" type="video/mp4"/>
                </video> */}
              </div>
            </div> 
          </div>
    )
}