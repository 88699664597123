import React, { useState, useEffect } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export interface IProps {
  images: string[];
  photoIndex: number;
  toggleModal: () => void
}

export const GalleryModal: React.FC<IProps> = props => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  useEffect(() => {
    setCurrentIndex(props.photoIndex)
  }, [props.photoIndex])

  const images = props.images

  return (
    <Lightbox
      mainSrc={images[currentIndex]}
      nextSrc={images[(currentIndex + 1) % images.length]}
      prevSrc={images[(currentIndex + images.length - 1) % images.length]}
      onCloseRequest={props.toggleModal}
      onMovePrevRequest={() =>
        setCurrentIndex((currentIndex + images.length - 1) % images.length)
      }
      onMoveNextRequest={() =>
        setCurrentIndex((currentIndex + 1) % images.length)
      }
    />
  );
};
