import * as React from 'react';
import { Routes } from '../../config/Routes';
import { Link } from '@reach/router';
import { createAppointment } from '../../core/service/services';
import {
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Label,
  CustomInput,
  FormGroup,
  Button,
  FormFeedback,
  Spinner
} from 'reactstrap';
import { TextField } from '../../components/commons/TextField';
import { IBookingProps } from './AppointmentModels';
import { SideCardFrame } from '../../components/layout/SideCardFrame';
import { toastError, navItem } from '../../App';
import { LoadingButton } from '../../components/commons/LoadingButton';
import Asterisk from '../../components/commons/Asterisk';
import moment from 'moment';
import { useEffect, useState } from 'react'
import {
  cardSelected,
  creditCardUpdated,
  billingAddressUpdated,
  selectCreditCard,
  selectSelectedCard,
  selectBillingAddress
} from '../../redux/slices/booking/paymentStepSlice'
import {
  creditCardUpdated as creditCardSaved,
  billingAddressUpdated as billingAddressSaved,
  appointmentCreated,
  nextStepInitiated,
  previousStepInitiated,
  selectCreateApptPayload,
  selectPaymentAmount,
  selectTransactionFee
} from '../../redux/slices/booking/bookingSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { } from '../../redux/slices/booking/bookingSlice';
import { selectCreditCards } from '../../redux/slices/general/creditCardsSlice';
import { selectAllStates } from '../../redux/slices/general/USAStatesSlice';
import { selectContactInfo } from '../../redux/slices/general/patientInfoSlice';
import {
  selectCopaymentAmount,
  selectIsCoveredByInsurance,
  selectInsuranceTemEntity
} from '../../redux/slices/booking/insuranceStepSlice';
import { selectDependent } from '../../redux/slices/booking/choosePatientStepSlice';
import { ICreditCard, IBillingAddress, ICreateAppointmentPayload, IInsuranceTemEntity } from '../../redux/types/interfaces/bookingInterfaces';
import { ICreditCardListingItem } from '../../redux/types/interfaces/otherInterfaces';
import { I_USAState } from '../../redux/types/interfaces/otherInterfaces';
import { IContact } from '../../redux/types/interfaces/patientInfoInterfaces';
import { IDependent } from '../../redux/types/interfaces/patientInfoInterfaces';
import { isInputDefined, isDefined, isNumber } from '../../core/service/Validators';
import { AddressErrorMessages, ContactErrorMessages, CreditCardErrorMessages } from '../../core/service/ErrorMessages';
import { errorsDisplayed, selectShowErrors } from '../../redux/slices/booking/errorSlice';
import { areThereErrors } from '../../core/service/ErrorService';

enum KeyDown {
  Backspace = "Backspace"
}
enum Card {
  New = 'new'
}

const BookingPaymentStep = ({
  smallCard
}: IBookingProps) => {
  const creditCard: ICreditCard | undefined = useAppSelector(selectCreditCard)
  const selectedCard: string | undefined = useAppSelector(selectSelectedCard)
  const address: IBillingAddress = useAppSelector(selectBillingAddress)
  const creditCards: Array<ICreditCardListingItem> = useAppSelector(selectCreditCards)
  const createApptPayload: ICreateAppointmentPayload = useAppSelector(selectCreateApptPayload)
  const allUSStates: Array<I_USAState> = useAppSelector(selectAllStates)
  const contactInfo: IContact = useAppSelector(selectContactInfo)
  const dependent: IDependent | undefined = useAppSelector(selectDependent)
  const paymentAmount: number | undefined = useAppSelector(selectPaymentAmount)
  const transactionFee: number | undefined = useAppSelector(selectTransactionFee)
  const copaymentAmount: number | undefined = useAppSelector(selectCopaymentAmount)
  const isCoveredByInsurance: boolean | undefined = useAppSelector(selectIsCoveredByInsurance)
  const { haveInsurance }: IInsuranceTemEntity = useAppSelector(selectInsuranceTemEntity)
  const showErrors = useAppSelector(selectShowErrors);

  const [showPaymentForm, setShowPaymentForm] = useState<boolean>(creditCards.length === 0)
  const [addressIsEditable, setAddressIsEditable] = useState<boolean>(creditCards.length === 0)
  const [loading, setLoading] = useState<boolean>(false)
  const [createApptPayloadIsUpdated, setCreateApptPayloadIsUpdated] = useState<boolean>(false)

  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (creditCards.length !== 0) {
      const firstCard = getFirstSelectedCard()
      if (firstCard) {
        dispatch(cardSelected(firstCard.id))
        dispatch(billingAddressUpdated(firstCard.address))
      }
    }
  }, [])

  useEffect(() => {
    if (createApptPayloadIsUpdated) {
      createTheAppointment()
      setCreateApptPayloadIsUpdated(false)
    }
  }, [createApptPayloadIsUpdated])

  const getFirstSelectedCard = () => {
    if (creditCards && creditCards.length > 0) {
      return creditCards[0]
    }
  }

  const validateExpirationDateWrapper = (text: string) => {
    let creditCardState = creditCard
    if (!creditCardState) {
      creditCardState = {}
    }
    if (typeof (creditCardState) === 'object' && creditCardState.expiration && text.length === 0) {
      dispatch(creditCardUpdated({ ...creditCard, expiration: ('') }))
      return;
    }
    let i = 0;
    while (typeof (creditCardState) === 'object' && creditCardState.expiration?.charAt(i) === text.charAt(i)) {
      i++;
    }
    let key = text.charAt(i);
    if (
      typeof (creditCardState) === 'object' && creditCardState.expiration
      && text.length < creditCardState.expiration.length
    ) {
      key = KeyDown.Backspace
    }
    validateExpirationDate(key)
  }

  const validateExpirationDate = (key: string) => {
    let creditCardState = creditCard
    if (!creditCardState) {
      creditCardState = {}
    }
    let textValue = creditCard?.expiration ? creditCard.expiration : '';
    if (key === KeyDown.Backspace) {
      textValue = handleBackspaceKeyDown(textValue);
    } else if (keyIsANumber(key)) {
      textValue = handleNumberKeyDown(textValue, key);
    }
    dispatch(creditCardUpdated({ ...creditCard, expiration: textValue }))
  }

  const handleBackspaceKeyDown = (textValue: string) => {
    textValue = textValue.slice(0, -1);
    if (textValue.length === 2) {
      textValue = textValue.slice(0, -1);
    }
    return textValue;
  }

  const keyIsANumber = (key: string) => {
    return !isNaN(parseInt(key));
  }

  const handleNumberKeyDown = (textValue: string, key: string) => {
    let keyNumber = parseInt(key);
    switch (textValue?.length) {
      case 0:
        if (keyNumber > 1) {
          textValue = `0${keyNumber}/`;
        } else {
          textValue = textValue + keyNumber;
        }
        break;
      case 1:
        if ((textValue === '1' && keyNumber < 3) || (textValue === '0' && keyNumber !== 0)) {
          textValue = textValue + keyNumber + '/';
        }
        break;
      case 3:
      case 4:
        textValue = textValue + keyNumber;
    }
    return textValue;
  }

  const handleCardSelection = (cardId: string) => {
    if (cardId === Card.New) {
      setShowPaymentForm(true)
      setAddressIsEditable(true)
    } else {
      dispatch(cardSelected(cardId))
      const addressFound = getAddressFromCards(cardId)
      addressFound && dispatch(billingAddressUpdated(addressFound))
      setShowPaymentForm(false);
      setAddressIsEditable(false);
    }
  }

  const getAddressFromCards = (cardId: string) => {
    let cards = creditCards || []
    for (let index = 0; index < cards.length; index++) {
      if (cards[index].id === cardId) {
        return cards[index].address
      }
    }
  }

  const updateCreateApptPayload = () => {
    if (selectedCard) {
      dispatch(creditCardSaved(selectedCard))
    } else {
      const newCard: ICreditCard = {
        nameOnCard: creditCard?.nameOnCard,
        cardNumber: creditCard?.cardNumber,
        expiration: creditCard?.expiration,
        cvv: creditCard?.cvv,
        keepCardInFile: creditCard?.keepCardInFile
      }
      dispatch(creditCardSaved(newCard))
    }
    if (!addressIsEditable) {
      dispatch(billingAddressSaved('use on file'))
    } else {
      dispatch(billingAddressSaved(address))
    }
    setCreateApptPayloadIsUpdated(true)
  }

  const createTheAppointment = () => {
    setLoading(true);
    createAppointment(createApptPayload)
      .then(res => {
        let result = JSON.parse(JSON.stringify(res));
        dispatch(appointmentCreated(result))
        dispatch(nextStepInitiated())
      })
      .catch(err => {
        let error = JSON.parse(JSON.stringify(err))
        toastError("" + error.json.message);
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const handleNext = () => {
    if (areThereErrors()) {
      dispatch(errorsDisplayed(true))
    }
    else {
      dispatch(errorsDisplayed(false))
      updateCreateApptPayload();
    }
  }
  const renderFlowNav = () => {
    return (
      <div className="flow-nav-tools">
        <Button
          disabled={loading}
          onClick={() => { dispatch(previousStepInitiated()) }}
          size="lg"
          className="control-nav ml-auto"
          outline
          color="secondary"
        >
          Back
        </Button>
        <span className="text-muted mt-2">
          Step 7 of 7
        </span>
        <LoadingButton color="success" size="lg" className="control-nav mr-auto" isLoading={loading}
          onClick={handleNext}
        >
          Pay and Confirm
        </LoadingButton>
      </div>
    );
  };

  const renderTitle = () => {
    let fullName = ''
    if (dependent) {
      fullName = `${dependent.firstname} ${dependent.lastname}`
    } else {
      fullName = `${contactInfo?.firstname} ${contactInfo?.lastname}`
    }
    return `Book an Appointment for ${fullName}`
  }

  const renderCosts = () => {
    if (paymentAmount && transactionFee) {
      if (!haveInsurance) {
        return renderCostsIfHaveNoInsurance()
      }
      if (!isCoveredByInsurance) {
        return renderCostsIfNotCoveredByInsurance()
      }
      if (!copaymentAmount) {
        return renderCostsIfCopaymentAmountDoesntExist()
      }
      return renderCostsIfCopaymentAmountExists()
    }
  }

  const renderCostsIfHaveNoInsurance = () => {
    return (
      <>
        {renderCostsOnly()}
        <h5>Total: US${paymentAmount! + transactionFee!}</h5>
      </>
    )
  }

  const renderCostsIfNotCoveredByInsurance = () => {
    return (
      <>
        {renderCostsOnly()}
        <h5>Total: US${paymentAmount! + transactionFee!}</h5>
        <div>
          According to the insurance info provided, we found that your insurance does not cover telehealth service.
        </div>
      </>
    )
  }

  const renderCostsIfCopaymentAmountDoesntExist = () => {
    return (
      <>
        {renderEligiblePatient()}
        {renderCostsOnly()}
        <h5>Total: US${paymentAmount! + transactionFee!}</h5>
        <div>
          We will contact your insurance company to get co-payment info. For now, the full service fees <b>“${paymentAmount! + transactionFee!}“</b> will be authorized with online payment, and in 3 - 4 weeks, you will get a refund depending on your insurance company feedback
        </div>
      </>
    )
  }

  const renderCostsIfCopaymentAmountExists = () => {
    return (
      <>
        {renderEligiblePatient()}
        {renderCostsOnly()}
        <h5>Co-Payment: US${copaymentAmount}</h5>
        <h5>Total: US${paymentAmount! + transactionFee! - copaymentAmount!}</h5>
      </>
    )
  }

  const renderCostsOnly = () => {
    return (
      <>
        <h5>Service Cost: US${paymentAmount}</h5>
        <h5>Transaction Fees: US${transactionFee}</h5>
      </>
    )
  }

  const renderEligiblePatient = () => {
    return (
      <>
        <Col>
          <Row>
            <i className="fas fa-check-circle fa-1x" style={{ color: "green" }} />
            <div>{"      "}</div>
            <h5>   We contacted your insurance and you are eligible to use it</h5>
          </Row>
        </Col>
      </>
    )
  }

  const renderLoadingState = () => {
    if (loading) {
      return <Spinner color="primary" style={{ alignSelf: 'center' }} />;
    }
  };

  const renderPaymentForm = () => {
    if (showPaymentForm) {
      return (
        <>
          <h6 className="mb-3">Payment</h6>
          <div className="d-block my-3 ml-3">
            <Row className="my-3">
              <Col md="6">
                <TextField
                  type="text"
                  placeholder="Name on Card"
                  labelText="Name on card"
                  defaultValue={creditCard?.nameOnCard}
                  required
                  disabled={loading}
                  textChanged={(text) => {
                    dispatch(creditCardUpdated({ ...creditCard, nameOnCard: text }))
                  }}
                  invalid={!isInputDefined(creditCard?.nameOnCard)}
                  showValidation={showErrors}
                  errorMessage={CreditCardErrorMessages.enterName}
                />
              </Col>
              <Col md="6">
                <TextField
                  type="text"
                  placeholder="Card Number"
                  labelText="Card Number"
                  defaultValue={creditCard?.cardNumber}
                  required
                  disabled={loading}
                  textChanged={(text) => {
                    dispatch(creditCardUpdated({ ...creditCard, cardNumber: text }))
                  }}
                  invalid={!isInputDefined(creditCard?.cardNumber) && showErrors}
                  showValidation={showErrors}
                  errorMessage={CreditCardErrorMessages.enterNumber}
                />
              </Col>
              <Col md="3" className="mb-3">
                <TextField
                  type="text"
                  placeholder={renderExpirationDatePlaceholder()}
                  labelText="Expiration"
                  value={creditCard?.expiration}
                  maxLength={5}
                  required
                  disabled={loading}
                  textChanged={(text) => {
                    validateExpirationDateWrapper(text)
                  }}
                  invalid={!isInputDefined(creditCard?.expiration) && showErrors}
                  showValidation={showErrors}
                  errorMessage={CreditCardErrorMessages.enterExpirationDate}
                />
              </Col>
              <Col md="3" className="mb-3">
                <TextField
                  type="text"
                  placeholder="123"
                  labelText="CVV"
                  defaultValue={creditCard?.cvv}
                  required
                  disabled={loading}
                  textChanged={(text) => {
                    dispatch(creditCardUpdated({ ...creditCard, cvv: text }))
                  }}
                  invalid={!isInputDefined(creditCard?.cvv) && showErrors}
                  showValidation={showErrors}
                  errorMessage={CreditCardErrorMessages.enterCvv}
                />
              </Col>
            </Row>
          </div>
        </>
      )
    }
  }

  const renderExpirationDatePlaceholder = () => {
    const extraNumOfYears = 2;
    const currentYear = parseInt(moment().format("YY"));
    return `e.g. 06/${currentYear + extraNumOfYears}`;
  }

  const renderBillingAddressEditButton = () => {
    if (!addressIsEditable) {
      return (
        <Button disabled={loading} color="link" size="sm" className="pt-0" onClick={() => { setAddressIsEditable(true) }}>
          <span className="sr-only">Edit</span>{" "}
          <i className="fa fa-edit fa-lg"></i>
        </Button>
      )
    }
  }

  const renderBillingAddressForm = () => {
    return (
      <Row className="row my-3" id="billingAddressDetail">
        <Col md="6" lg="3">
          <FormGroup className="form-label-group">
            <Label>First Name<Asterisk /></Label>
            <Input
              type="text"
              placeholder="First Name"
              value={address.firstname}
              required
              disabled={!addressIsEditable || loading}
              invalid={!isInputDefined(address.firstname) && showErrors}
              onChange={(e) => {
                dispatch(billingAddressUpdated({ ...address, firstname: e.target.value }))
              }}
            />
            <FormFeedback className='error' hidden={isInputDefined(address.firstname)}>{ContactErrorMessages.invalidFirstName}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6" lg="3">
          <FormGroup className="form-label-group">
            <Label>Last Name<Asterisk /></Label>
            <Input
              type="text"
              placeholder="Last Name"
              value={address.lastname}
              required
              disabled={!addressIsEditable || loading}
              invalid={!isInputDefined(address.lastname) && showErrors}
              onChange={(e) => {
                dispatch(billingAddressUpdated({ ...address, lastname: e.target.value }))
              }}
            />
            <FormFeedback className='error' hidden={isInputDefined(address.lastname)}>{ContactErrorMessages.invalidLastName}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6" lg="6">
          <FormGroup className="form-label-group">
            <Label>Email Address<Asterisk /></Label>
            <Input
              type="text"
              value={address.email}
              invalid={!isInputDefined(address.email) && showErrors}
              required
              disabled={!addressIsEditable || loading}
              onChange={(e) => {
                dispatch(billingAddressUpdated({ ...address, email: e.target.value }))
              }} />
            <FormFeedback className='error' hidden={isInputDefined(address.email)}>{ContactErrorMessages.enterEmail}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6" lg="12">
          <FormGroup className="form-label-group">
            <Label>Address<Asterisk /></Label>
            <Input
              type="text"
              value={address.description}
              required
              disabled={!addressIsEditable || loading}
              invalid={!isInputDefined(address.description) && showErrors}
              onChange={(e) => {
                dispatch(billingAddressUpdated({ ...address, description: e.target.value }))
              }} />
            <FormFeedback className='error' hidden={isInputDefined(address.description)}>{AddressErrorMessages.enterAddress}</FormFeedback>

          </FormGroup>
        </Col>
        <Col md="4" lg="5">
          <FormGroup className="form-label-group">
            <Label>City<Asterisk /></Label>
            <Input
              type="text"
              value={address.city}
              required
              invalid={!isInputDefined(address.city) && showErrors}
              disabled={!addressIsEditable || loading}
              onChange={(e) => {
                dispatch(billingAddressUpdated({ ...address, city: e.target.value }))
              }} />
            <FormFeedback className='error' hidden={isInputDefined(address.city)}>{AddressErrorMessages.enterCity}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="4" lg="3">
          <FormGroup>
            <Label for="state">State</Label>
            <Input
              type="select"
              id="state"
              name="state"
              defaultValue={address.stateId}
              value={address.stateId}
              invalid={(!isDefined(address?.stateId) || !isNumber(address?.stateId!)) && showErrors}

              required
              disabled={!addressIsEditable || loading}
              onChange={(e) => {
                dispatch(billingAddressUpdated({ ...address, stateId: parseInt(e.target.value) }))
              }}
            >
              <option value={-1}>Select state</option>
              {renderUSStatesSelectOptions()}
            </Input>
            <FormFeedback className='error' hidden={isDefined(address?.stateId) && isNumber(address?.stateId!)}>{AddressErrorMessages.enterState}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="4" lg="4">
          <FormGroup className="form-label-group">
            <Label>Zip Code<Asterisk /></Label>
            <Input
              type="text"
              value={address.zipCode}
              invalid={!isInputDefined(address.zipCode) && showErrors}
              required
              disabled={!addressIsEditable || loading}
              onChange={(e) => {
                dispatch(billingAddressUpdated({ ...address, zipCode: e.target.value }))
              }}
            />
            <FormFeedback className='error' hidden={isInputDefined(address.zipCode)}>{AddressErrorMessages.enterZipCode}</FormFeedback>

          </FormGroup>
        </Col>
      </Row>
    );
  }

  const renderUSStatesSelectOptions = () => {
    return allUSStates.map((state, idx) => {
      return (
        <option key={idx} value={state.id}>
          {state.name}
        </option>
      );
    });
  };

  const renderKeepCardOnFile = () => {
    if (!selectedCard || selectedCard === Card.New) {
      return (
        <Row>
          <CustomInput
            id="keepCardInFile"
            type="checkbox"
            checked={creditCard?.keepCardInFile}
            disabled={loading}
            onChange={(e) => {
              dispatch(creditCardUpdated({ ...creditCard, keepCardInFile: (e.target.checked) }))
            }}
          />
          <Label>Keep Card on File</Label>
        </Row>
      )
    }
  }

  return (
    <SideCardFrame flowNav={renderFlowNav()} navItem={navItem} bodyClassName="tc-bg" smallCard={smallCard}>
      <CardBody>
        <div className="d-flex px-2">
          <h5 className="mb-0">
            {renderTitle()}
          </h5>
          <Link
            to={Routes.homePatient}
            className="btn btn-link btn-sm ml-auto"
          >
            Cancel
          </Link>
        </div>
        <hr className="mt-2 mb-4"></hr>
        <CardTitle>
          Confirm your billing info and choose your payment method:
          {renderCosts()}
        </CardTitle>
        <Row>
          <Col lg={{ size: 8, offset: 5 }} xl={{ size: 8, offset: 5 }}>
            {renderLoadingState()}
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Select a payment method</Label>
              <Input
                type="select"
                name="creditCard"
                disabled={loading}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleCardSelection(e.target.value) }}
              >
                {creditCards?.map((card, index) => (
                  <option value={card.id} selected={selectedCard === card.id} key={index}>
                    {card.cardNumber + " - " + card.expiration}
                  </option>
                ))}
                <option value={Card.New} selected={selectedCard === undefined}>
                  Add new Payment Method
                </option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        {renderPaymentForm()}
        <hr className="mb-4"></hr>
        <h6 className="mb-3">
          Billing address
          {renderBillingAddressEditButton()}
        </h6>
        <div className="d-block my-3 ml-3">
          <Row className="row my-3">
            {renderBillingAddressForm()}
          </Row>
        </div>
        {renderKeepCardOnFile()}
      </CardBody>
    </SideCardFrame>
  );
}

export default BookingPaymentStep