import * as React from "react";
import Footer from "./Footer";
import TCNavBar from "./TCNavBar";
import { Container, Row, Col, Card } from "reactstrap";

interface ConsecutiveCardFrameProps {
  children?: React.ReactNode;
  bodyClassName: null | "tc-bg"; // | "tc-bg modal-open"; // | "signup-bg";
  navItem: React.ReactNode;
  modal?: React.ReactNode;
  flowNav?: React.ReactNode;
  smallCard?: React.ReactNode;
}

interface ConsecutiveCardFrameState {}

export class ConsecutiveCardFrame extends React.Component<
  ConsecutiveCardFrameProps,
  ConsecutiveCardFrameState
> {
  constructor(props: ConsecutiveCardFrameProps) {
    super(props);
    
    this.state = {};
  }

  render = () => {
    const nav = (
      <TCNavBar bodyClassName={this.props.bodyClassName}>
        {this.props.navItem}
      </TCNavBar>
    );
    return (
      <div>
        {nav}

        <Container style={{maxWidth: 1510}}>
          {this.props.modal}
          <Row>
            <Col lg="7" xl="7">
              <Card className="card-telecare main my-lg-5 my-xs-2 my-sm-2 my-md-2">
                {this.props.children}
              </Card>
              {this.props.flowNav}
            </Col>
            <Col lg="5" xl="5">
              <Card className="card-telecare my-5">
                {this.props.smallCard}
              </Card>
            </Col>
          </Row>
        </Container>

        <Footer />
      </div>
    );
  }
}
