import { IPatientForAgents } from "../page/dashboard/agent/PatientsOverview";

export interface IUserInfo{
        id?:number;
        firstname?: string;
        lastname?: string;
        middlename?: string;
        visitCount: number;  
        patientAsAgent?: {
            id: number;
            firstName: string;
            lastName:string
        }; 
}

export interface IAgentAsPatient{
    id:number,
    firstName?: string,
    lastName?: string
}
export class UserConfig {

    // static init() {
    //     if (!(window as any).userConfig) {
    //         (window as any).userConfig = {
    //             token: null as string | null,
    //             name: null as string | null
    //         }
    //         console.error("#### UserConfigInialized ####")
    //     }

    // }
    private static readonly tokenKey = "userToken"
    static readonly setToken = (token: string | null) => {
        // UserConfig.init()
        if (token === null) {
            window.localStorage.removeItem(UserConfig.tokenKey)
        } else {
            window.localStorage.setItem(UserConfig.tokenKey, token);
        }
    }
    static readonly getToken: () => string | null = () => {
        // UserConfig.init()
        return window.localStorage.getItem(UserConfig.tokenKey);
    }


    private static readonly userInfoKey = "userInfo"
    static readonly setUserInfo = (info?: IUserInfo| null) => {
        // UserConfig.init()

        if (info) {
            const j = JSON.stringify(info);
            window.localStorage.setItem(UserConfig.userInfoKey, j);
        } else {
            window.localStorage.removeItem(UserConfig.userInfoKey);
        }
    }
    static readonly getUserInfo: () => IUserInfo | null = () => {
        // UserConfig.init()
        const j = window.localStorage.getItem(UserConfig.userInfoKey);
        if(j){
            return JSON.parse(j);
        }
        return null;
    }

    private static readonly userType = "userType"
    static readonly setUserType = (type: string) => {
        if (type) {
            window.localStorage.setItem(UserConfig.userType, type);
        } else {
            window.localStorage.removeItem(UserConfig.userType);
        }
    }
    static readonly getUserType: () => string | null = () => {
        const type = window.localStorage.getItem(UserConfig.userType);
        return type;
    }
    static readonly getPatientLoggedByAgent=()=>{
        const userInfo = window.localStorage.getItem(UserConfig.userInfoKey);
        if(userInfo){
            let agent = JSON.parse(userInfo);
            return agent.patientAsAgent? agent.patientAsAgent : null;
        }
        return null;
    }
    static readonly setPatientLoggedByAgent=(patient:IPatientForAgents | null)=>{
        if(patient !== null){
            const userInfo = window.localStorage.getItem(UserConfig.userInfoKey);
            if(userInfo){
                let agent = JSON.parse(userInfo);
                agent = {...agent, patientAsAgent: {
                    id:patient.id,
                    firstName: patient.firstName,
                    lastName: patient.lastName
                }}
                window.localStorage.setItem(UserConfig.userInfoKey, JSON.stringify(agent));
            }
        }else{
            const userInfo = window.localStorage.getItem(UserConfig.userInfoKey);
            if(userInfo){
                let agent = JSON.parse(userInfo);
                delete agent.patientAsAgent;
                window.localStorage.setItem(UserConfig.userInfoKey, JSON.stringify(agent));
            }
        }
    }
    private static readonly emailPatientRegisteredByAgent = "emailPatientRegisteredByAgent"
    static readonly setemailPatientRegisteredByAgent=(email:string | null)=>{
        if(email === null)
            window.localStorage.removeItem(UserConfig.emailPatientRegisteredByAgent)
        else
            window.localStorage.setItem(UserConfig.emailPatientRegisteredByAgent, email);
    }
    static readonly getEmailPatientRegisteredByAgent=()=>{
        return window.localStorage.getItem(UserConfig.emailPatientRegisteredByAgent);
    }
}

