import React, { useRef } from "react";
import { Input } from "reactstrap";
import { IMessage } from "./MessageModel";
import { postMessageChat } from "../../core/service/services";

interface IProps {
  closeClicked?: () => void;
  appointementId?: string;
  messageSent?: (message: IMessage) => void;
  showClosed?: boolean;
}

export const SendMessageModule: React.FC<IProps> = props => {
  let inputRef = useRef<HTMLInputElement>(null);
  const onSendMessage = () => {
    let value = inputRef.current?.value || "";
    inputRef.current && (inputRef.current.value = "");
    let newMessage: IMessage = { text: value };
    postMessageChatNow(newMessage);
  };
  const postMessageChatNow = (newMessage: IMessage) => {
    if (props.appointementId) {
      postMessageChat(props.appointementId, newMessage)
        .then((res: IMessage) => {
          if (props.messageSent) {
            props.messageSent(res);
          }
        })
        .catch(err => console.log("error", err));
    }
  };

  const handleKeyPress = (target: any) => {
    if (target.charCode == 13) {
      onSendMessage();
    }
  };

  const showClose = () => {
    if (!props.showClosed) {
      return;
    } else {
      return (
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={props.closeClicked}
        >
          Close
        </button>
      );
    }
  };

  return (
    <div className="modal-footer">
      <div className="input-group d-flex">
        <Input
          type="text"
          className="form-control"
          placeholder="Enter message"
          id="sendDrMsgInput"
          aria-describedby="sendDrMsgInputBtn "
          onKeyPress={handleKeyPress}
          innerRef={inputRef}
        ></Input>

        <div className="input-group-append" style={{ marginRight: 10 }}>
          <button
            className="btn btn-outline-secondary"
            type="button"
            id="sendDrMsgInputBtn"
            onClick={onSendMessage}
          >
            Send
          </button>
        </div>
        {showClose()}
      </div>
    </div>
  );
};
