import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { IBillingAddress, ICreditCard } from '../../types/interfaces/bookingInterfaces'
import { bookingCleared } from './bookingSlice';

export interface IPaymentStepState {
   creditCard?: ICreditCard;
   billingAddress: IBillingAddress;
   selectedCard?: string;
}

const initialState: IPaymentStepState = {
   billingAddress: {}
}

const paymentStepSlice = createSlice({
   name: 'paymentStep',
   initialState,
   reducers: {
      creditCardUpdated: (state, action: PayloadAction<ICreditCard | undefined>) => {
         state.creditCard = { ...action.payload }
         state.selectedCard = undefined
      },
      billingAddressUpdated: (state, action: PayloadAction<IBillingAddress>) => {
         state.billingAddress = { ...action.payload }
      },
      cardSelected: (state, action: PayloadAction<string | undefined>) => {
         state.selectedCard = action.payload
         state.creditCard = undefined
      }
   },
   extraReducers: (builder: ActionReducerMapBuilder<IPaymentStepState>) => {
      builder.addCase(bookingCleared, () => {
         return initialState
      })
   }
})

export const {
   creditCardUpdated,
   billingAddressUpdated,
   cardSelected
} = paymentStepSlice.actions

export const selectCreditCard = (state: RootState) => state.paymentStep.creditCard
export const selectBillingAddress = (state: RootState) => state.paymentStep.billingAddress
export const selectSelectedCard = (state: RootState) => state.paymentStep.selectedCard

export default paymentStepSlice.reducer
