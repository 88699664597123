import React from 'react';
import { SmallCardFrame } from '../../components/layout/SmallCardFrame';
import { CardBody, CardTitle, Form, Button } from 'reactstrap';
import { EmptyButton } from '../../components/commons/EmptyButton';
import { navigate, RouteComponentProps } from '@reach/router';
import { Routes } from '../../config/Routes';
import { TextField } from '../../components/commons/TextField';
import { strings } from '../../Constants';

export interface ILoginProps extends IProps {
  userType: "patient" | "agent" | "biller" | string;
  logIn: any;
  routesTo: any;
  errorMessage: any;
}
interface IProps extends RouteComponentProps {
  targetPath?: string
}
export const Login = (props: ILoginProps) => {
  let email = (props.location?.state as any)?.email;
  let password = (props.location?.state as any)?.password;
  const emailField = new TextField({
    type: "text",
    labelText: "Email address",
    placeholder: "Email address",
    textChanged: () => { },
    defaultValue: email
  });
  const passwordFiled = new TextField({
    type: "password",
    labelText: "Password",
    placeholder: "Password",
    textChanged: () => { },
    defaultValue: password
  });
  const getTitle = () => {
    switch (props.userType) {
      case ("agent"): {
        return strings.biller.agentLoginTitle
      }
      case ("patient"): {
        return strings.biller.patientLoginTitle

      }
      case ("biller"): {
        return strings.biller.billerLoginTitle

      }


    }
  }
  return (
    <SmallCardFrame navItem={null} bodyClassName="tc-bg">
      <div className="card-img-left d-none d-md-flex">
        {/* <!-- Background image for card set in CSS! --> */}
      </div>
      <CardBody>
        <CardTitle className="text-center">{getTitle()}</CardTitle>
        <hr />
        <Form className="form-signin" onSubmit={(e) => {
          e.preventDefault();
          props.logIn({ email: emailField.getValue(), password: passwordFiled.getValue() })
        }}>
          {emailField.render()}
          {passwordFiled.render()}
          <div className="text-center mb-4 mt-4" style={{ color: "red" }}>
            {props.errorMessage}
          </div>

          <Button type="submit" size="lg" color="primary" className="btn-block text-uppercase btn-login mt-4">Sign In</Button>

          <div className="text-center mb-4 mt-4">
            Forgot Password? {" "}
            <a onClick={(e) => { navigate(Routes.requestReset, { state: { email: emailField.getValue(), userType: props.userType } }); e.preventDefault(); return false; }} href="#" className=" mt-2"> Reset Here</a>
          </div>
          <hr />
          <div className="form-text mt-2 text-center mb-2 mt-4">
            Not yet a member?
          </div>
          <EmptyButton label="Sign Up" to={props.routesTo} />
        </Form>
      </CardBody>
    </SmallCardFrame>
  );
}