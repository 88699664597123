import {
  FormGroup,
  Row,
  Label,
  Input,
  Container,
  Col,
  UncontrolledCollapse,
  Button,
} from "reactstrap";
import React, { useState } from "react";
import { IUSState } from "../../settings/PhysicianSettings";
import moment from "moment";

export interface ISearchBarProps {
  uSStates?: IUSState[];
  searchFilters: (searchOpts: any) => void;
  userType: "physician" | "agent" | "biller"
}

export const PhysiciansSearchBar = (props: ISearchBarProps) => {
  const [search, setSearch] = useState<any>();

  const applySearchFilters = (searchOpts: any) => {
    props.searchFilters(searchOpts);
  };

  const renderAdvancedSearch = () => {
    return (
      <Row className="ml-auto">
        <Col md="8" lg="8" className="pl-0">
          <Label className="mb-0">Joining Date</Label>
          <Container className="d-flex">
            <Row>
              <Col>
                <Row>
                  <Col md="12" lg="12" className="pl-0">
                    <small><Label className="mb-0">from</Label></small>
                  </Col>
                  <Col md="12" lg="12" className="pl-0">
                    <Input type="date" placeholder="From" className="form-control form-control-sm"
                      onChange={(e) => {
                        const searchObj = { ...search, joinDateFrom: moment(e.target.value).format('YYYY-MM-DD') || undefined }
                        setSearch(searchObj);
                        applySearchFilters(searchObj)
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col md="12" lg="12" className="pl-0">
                    <small><Label className="mb-0">to</Label></small>
                  </Col>
                  <Col md="12" lg="12" className="pl-0">
                    <Input type="date" placeholder="To" className="form-control form-control-sm "
                      onChange={(e) => {
                        const searchObj = { ...search, joinDateTo: moment(e.target.value).format('YYYY-MM-DD') || undefined }
                        setSearch(searchObj);
                        applySearchFilters(searchObj)
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Col>

        <Col md="4" lg="4" className="pl-0">
          <Label className="mt-3">Status</Label>
          <Input
            type="select"
            className="form-control form-control-sm"
            onChange={(e) => {
              const searchObj = { ...search, status: e.currentTarget.value }
              setSearch(searchObj);
              applySearchFilters(searchObj)
            }}
          >
            <option value={""}>All</option>
            <option value="New">Pending Approval</option>
            <option value="Active">Active</option>
            <option value="Disabled">Disabled</option>
          </Input>
        </Col>
      </Row>
    );
  };
  const renderPrimarySearch = () => {
    return (
      <Row>
        <Col>
          <Label>
            {props.userType === 'physician' ? "Physician Name" : props.userType === 'agent' ? "Agent Name" : "Biller Name"}
          </Label>
          <Input id="searchinput"
            placeholder={props.userType === 'physician' ? "Physician Name" : props.userType === 'agent' ? "Agent Name" : "Biller Name"}
            type="text" className="form-control form-control-sm"
            onChange={(e) => {
              const searchObj = { ...search, name: e.target.value }
              setSearch(searchObj);
              applySearchFilters(searchObj)
            }}
          />
        </Col>
        {props.userType === "physician" && <Col>
          <Label>State</Label>
          <Input type="select" className="form-control form-control-sm"
            onChange={(e) => {
              const searchObj = { ...search, stateId: e.target.value }
              setSearch(searchObj);
              applySearchFilters(searchObj)
            }}
          >
            <option value={""}>All</option>
            {props.uSStates?.map((state) => (
              <option value={state.id}>{state.name}</option>
            ))}
          </Input>
        </Col>}
      </Row>
    );
  };
  return (
    <FormGroup className="px-3 mb-0">
      {renderPrimarySearch()}

      <UncontrolledCollapse toggler="#phtoggler" className="mt-1">
        {renderAdvancedSearch()}
      </UncontrolledCollapse>
      <div style={{ textAlign: "center" }}>
        <Button color="link" size="sm" id="phtoggler">
          <i className="fas fa-chevron-circle-down"></i> Advanced Search
        </Button>
      </div>
    </FormGroup>
  );
};
