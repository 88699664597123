import { createSlice, PayloadAction, original, ActionReducerMapBuilder } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import {
   IMeasurements,
   IItem,
   ISelfReportedItem,
   ISelfReportedMedicationsAction,
   IDoseSpotPrescription
} from '../../types/interfaces/medicalProfileInterfaces'
import { SelfReportedMedicationAction, Gender } from '../../types/enums/medicalProfileEnums';
import { medicationAllergyExistsInArray, getMedItemFromPres } from '../../helper/medicalProfileHelper'
import { bookingCleared } from '../booking/bookingSlice';
import { IFullSelfReportedItem } from '../../../core/service/services';
import { IAddAllergy, IFullAllergy } from '../../../components/medicalHistory/MedicationAllergiesDoseSpot';

export interface IMedicalProfileState {
   measurements: IMeasurements;
   previousConditions: Array<IItem>;
   medicationAllergies: Array<any>; //Array<IFullAllergy>
   newMedicationAllergies: Array<IAddAllergy>;
   editedMedicationAllergies: Array<IFullAllergy>;
   deletedMedicationAllergies: Array<IFullAllergy>;
   hasPreviousConditions: boolean;
   hasMedicationAllergies: boolean;
   selfReportedMedications: Array<IFullSelfReportedItem>;
   selfReportedMedicationsActions: Array<ISelfReportedMedicationsAction>;
}

const initialState: IMedicalProfileState = {
   measurements: {
      pregnant: false,
      breastfeeding: false
   },
   previousConditions: [],
   medicationAllergies: [],
   newMedicationAllergies: [],
   editedMedicationAllergies: [],
   deletedMedicationAllergies: [],
   hasPreviousConditions: false,
   hasMedicationAllergies: false,
   selfReportedMedications: [],
   selfReportedMedicationsActions: []
}

const medicalProfileSlice = createSlice({
   name: 'medicalProfile',
   initialState,
   reducers: {
      measurementsUpdated: (state, action: PayloadAction<IMeasurements>) => {
         state.measurements = { ...action.payload }
      },
      pregnantUpdated: (state, action: PayloadAction<boolean>) => {
         state.measurements.pregnant = action.payload
      },
      breastfeedingUpdated: (state, action: PayloadAction<boolean>) => {
         state.measurements.breastfeeding = action.payload
      },
      genderUpdated: (state, action: PayloadAction<Gender>) => {
         state.measurements.gender = action.payload
      },
      previousConditionsUpdated: (state, action: PayloadAction<Array<IItem>>) => {
         state.previousConditions = action.payload.map(item => item)
      },
      medicationAllergiesUpdated: (state, action: PayloadAction<Array<IFullAllergy>>) => {
         state.medicationAllergies = action.payload.map(item => item)
      },
      newMedicationAllergiesUpdated: (state, action: PayloadAction<Array<IAddAllergy>>) => {
         state.newMedicationAllergies = action.payload.map(item => item)
      },
      medicationAllergyAdded: (state, action: PayloadAction<IAddAllergy>) => {
         state.newMedicationAllergies.push(action.payload)
      },
      medicationAllergyEdited: (state, action: PayloadAction<IFullAllergy>) => {
         if (medicationAllergyExistsInArray(state.medicationAllergies, action.payload)) {
            state.editedMedicationAllergies.push(action.payload)
         }
      },
      medicationAllergyRemoved: (state, action: PayloadAction<any>) => { //change any to interface
         state.medicationAllergies = state.medicationAllergies.filter(item => item.Code !== action.payload.Code)
         state.newMedicationAllergies = state.newMedicationAllergies.filter(item => item.Code !== action.payload.Code)
         state.deletedMedicationAllergies.push(action.payload)
      },
      hasPreviousConditionsBooleanChanged: (state, action: PayloadAction<boolean>) => {
         state.hasPreviousConditions = action.payload
      },
      hasMedicationAllergiesBooleanChanged: (state, action: PayloadAction<boolean>) => {
         state.hasMedicationAllergies = action.payload
      },
      selfReportedMedicationsUpdated: (state, action: PayloadAction<Array<IFullSelfReportedItem>>) => {
         state.selfReportedMedications = action.payload.map(item => item)
      },
      selfReportedMedicationAdded: (state, action: PayloadAction<IDoseSpotPrescription>) => {
         const medItem = getMedItemFromPres(action.payload)
         state.selfReportedMedications.push(medItem)
         state.selfReportedMedicationsActions.push({
            medication: medItem,
            action: SelfReportedMedicationAction.Add
         })
      },
      selfReportedMedicationRemoved: (state, action: PayloadAction<IFullSelfReportedItem>) => {
         const index = original(state.selfReportedMedications)!.indexOf(action.payload)
         if (index > -1) {
            state.selfReportedMedications.splice(index, 1)
            let indexInActions = -1
            indexInActions = state.selfReportedMedicationsActions.findIndex((item: ISelfReportedMedicationsAction) => {
               return (item.medication.name === action.payload.name) && item.action === SelfReportedMedicationAction.Add
            })
            if (indexInActions > -1) {
               state.selfReportedMedicationsActions.splice(indexInActions, 1)
            } else {
               state.selfReportedMedicationsActions.push({
                  medication: action.payload,
                  action: SelfReportedMedicationAction.Remove
               })
            }
         }
      },
      updatingArraysCleared: (state) => {
         state.selfReportedMedicationsActions = initialState.selfReportedMedicationsActions
         state.newMedicationAllergies = initialState.newMedicationAllergies
         state.editedMedicationAllergies = initialState.editedMedicationAllergies
         state.deletedMedicationAllergies = initialState.deletedMedicationAllergies
      }
   },
   extraReducers: (builder: ActionReducerMapBuilder<IMedicalProfileState>) => {
      builder.addCase(bookingCleared, () => {
         return initialState
      })
   }
})

export const {
   measurementsUpdated,
   pregnantUpdated,
   breastfeedingUpdated,
   genderUpdated,
   previousConditionsUpdated,
   medicationAllergiesUpdated,
   newMedicationAllergiesUpdated,
   medicationAllergyAdded,
   medicationAllergyEdited,
   medicationAllergyRemoved,
   hasPreviousConditionsBooleanChanged,
   hasMedicationAllergiesBooleanChanged,
   selfReportedMedicationsUpdated,
   selfReportedMedicationAdded,
   selfReportedMedicationRemoved,
   updatingArraysCleared
} = medicalProfileSlice.actions

export const selectMeasurements = (state: RootState) => state.medicalProfile.measurements
export const selectPreviousConditions = (state: RootState) => state.medicalProfile.previousConditions
export const selectMedicationAllergies = (state: RootState) => state.medicalProfile.medicationAllergies
export const selectNewMedicationAllergies = (state: RootState) => state.medicalProfile.newMedicationAllergies
export const selectEditedMedicationAllergies = (state: RootState) => state.medicalProfile.editedMedicationAllergies
export const selectDeletedMedicationAllergies = (state: RootState) => state.medicalProfile.deletedMedicationAllergies
export const selectHasPreviousConditions = (state: RootState) => state.medicalProfile.hasPreviousConditions
export const selectHasMedicationAllergies = (state: RootState) => state.medicalProfile.hasMedicationAllergies
export const selectSelfReportedMedications = (state: RootState) => state.medicalProfile.selfReportedMedications
export const selectSelfReportedMedicationsActions = (state: RootState) => state.medicalProfile.selfReportedMedicationsActions

export default medicalProfileSlice.reducer
