import React, { useState } from 'react';
import { RouteComponentProps, navigate } from "@reach/router";
import Footer from "../../../components/layout/Footer";
import TCNavBar from "../../../components/layout/TCNavBar";
import { Container, Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import { Routes } from '../../../config/Routes';
import { PhysiciansOverview } from './PhysiciansOverview';
import { AppointmentsOverview } from './AppointmentsOverview';
import { navItem, toastError, toastSuccess } from '../../../App';
import { AgentsOverview } from './AgentsOverview';
import { sendNewsletterEmails } from '../../../core/service/services';
import { AlertMessage } from '../../../core/alert/Alerts';
import * as str from '../../../Constants'
import { BillerOverview } from './biller-overivew/BillerOverview';
interface IProps extends RouteComponentProps {
}

export const AdminConsole: React.FC<IProps> = (props) => {
  const [showSendNLAlert, setShowSendNLAlert] = useState<boolean>(false);

  const toggleAlert = () => { setShowSendNLAlert(!showSendNLAlert); }

  const onAlertResponse = (response: boolean) => {
    if (response === true) {
      onSendNewsletterEmail();
    }
    toggleAlert();
  }

  const onSendNewsletterEmail = () => {
    sendNewsletterEmails().then(() => {
      toastSuccess("Newsletter Emails sent!")
    }).catch((error) => {
      toastError("Newsletter emails cannot be sent!")
    })
  }

  const renderSettingsButton = () => (
    <Card className="card-telecare btn-responsive-5 my-5">
      <CardBody className="btn btn-primary" onClick={e => navigate(Routes.adminConfigurationSettings)}>
        <span className="features-icons-icon d-inline-block "><i className="fa fa-cogs  mb-3"></i></span>
        <CardTitle className="mb-0">Settings Configuration</CardTitle>
      </CardBody>
    </Card>
  )

  const renderSendNewsLetterButton = () => (
    <Card className="card-telecare btn-responsive-2 my-2">
      <Button color="primary" size="md" onClick={toggleAlert} >
        <i className="fas fa-envelope-open-text"></i><br />
        <span>Send Newsletter Email</span>
      </Button>
    </Card>
  )

  const renderPayerList = () => (
    <Card className="card-telecare btn-responsive-3 my-3">
      <Button color="primary" size="md" onClick={e => navigate(Routes.insuranceAdmin)} >
        <i className="fas fa-hospital-user"></i><br />
        <span>{str.strings.adminConsoleStrings.payer_button}</span>
      </Button>
    </Card>
  )

  return (
    <>
      <TCNavBar bodyClassName="tc-bg">{navItem}</TCNavBar>
      <Container className="patient-list">
        <AlertMessage isOpen={showSendNLAlert} toggleOpen={toggleAlert} message="Are you sure you want to send the newsletter to subscribed patients?" setResponse={onAlertResponse} options={["Yes", "No"]} />
        <Row>
          <Col lg="10" xl="10">
            <h5 className="text-white mx-3">
              <i className="fa fa-clinic-medical"></i> Welcome, Admin
              {/* {UserConfig.getUserInfo()?.firstname} */}
            </h5>
            <Card className="card-telecare my-5">
              <CardBody>
                <CardTitle className="mb-0">
                  Use this console to manage physicians and agents, configure system properties and overview appointments activity.
                </CardTitle>
              </CardBody>
            </Card>
            <PhysiciansOverview />
            <AgentsOverview />
            <BillerOverview />
            <AppointmentsOverview />
          </Col>
          <Col lg="2" xl="2">
            <Row>
              <Col md={12}>
                {renderSettingsButton()}
              </Col>
              <Col md={12}>
                {renderSendNewsLetterButton()}
              </Col>
              <Col md={12}>
                {renderPayerList()}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
