import React from 'react';
import { FormFeedback, Input } from 'reactstrap';
interface CustomErrorMessageProps {
  invalid?: boolean;
  errorMessage?: string;
  showValidation?: boolean
}
const CustomErrorMessage = ({
  invalid, 
  errorMessage, 
  showValidation = true
}: CustomErrorMessageProps) => {
  return (
    <>
      <Input hidden={true} invalid={invalid && showValidation} />
      <FormFeedback hidden={!invalid} className='error'>
        {errorMessage}
      </FormFeedback>
    </>
  );
};

export default CustomErrorMessage;
