import * as React from "react";
import {
  ListGroup,
  ListGroupItem,
  Collapse,
  Card,
  CardHeader,
  Button,
  CardBody,
  ListGroupItemHeading,
} from "reactstrap";
import { BigCardFrame } from "../components/layout/BigCardFrame";
import { LandingPageHeader } from "../page/LandingPageHeader";
import { Helmet } from "react-helmet";
export const FAQ = () => {
  const [accordion, setAccording] = React.useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const listOfDrugs = [
    "Accutane",
    "Adderall",
    "Adipex-P (phentermine)",
    "Ambien",
    "Ativan",
    "Clozapine",
    "Codeine-containing medications",
    "Concerta",
    "Dexedrine",
    "Diazepam",
    "Fioricet",
    "Fiorinal",
    "Klonopin",
    "Naltrexone for alcohol abuse disorder",
    "Provigil (modafinil)",
    "Pseudoephedrine-containing medications",
    "Ritalin",
    "Robaxin",
    "Soma (carisoprodol)",
    "Testosterone injections",
    "Tramadol",
    "Valium",
    "Vitamin B12 injections",
    "Vyvanse",
    "Xanax",
  ];

  const toggleAccordion = (tab: number) => {
    let prevState = accordion;
    let state = prevState.map((x, index) => (tab === index ? !x : false));
    setAccording(state);
  };
  const renderDownIconButton = (tab: number, title: string) => {
    return (
      <>
        <span>{title}</span> {"  "}
        <i
          className="fa fa-angle-down"
          onClick={() => toggleAccordion(tab)}
          aria-expanded={accordion[tab]}
          // aria-controls="collapseOne"
        ></i>
      </>
    );
  };

  const renderLinkButton = (tab: number, title: string) => {
    return (
      <Button
        block
        color="link"
        className="text-left m-0 p-0"
        onClick={() => toggleAccordion(tab)}
        aria-expanded={accordion[tab]}
      >
        {title}
      </Button>
    );
  };
  return (
    <>
      <Helmet>
        <title>FAQ - TelMDCare</title>
      </Helmet>
      <div>
        <h2 className="text-white mx-3" style={{ textAlign: "center" }}>
          {" "}
          FREQUENTLY ASKED QUESTIONS
        </h2>
      </div>
      <BigCardFrame flowNav={<LandingPageHeader />} navItem={null} bodyClassName="tc-bg">
        <CardHeader>
          <h5>General</h5>
        </CardHeader>
        <CardHeader>
          {renderLinkButton(
            0,
            "How to get detailed answers for my health queries?"
          )}
        </CardHeader>
        <Collapse
          isOpen={accordion[0]}
          data-parent="#accordion"
          id="collapseOne"
          aria-labelledby="headingOne"
        >
          <CardBody>
            When posting a health query to the doctors, make sure you have
            provided enough details for them to understand your problem. Details
            of the symptoms. Details regarding current treatment and
            medications. Attach photos or medical reports, if any.
          </CardBody>
        </Collapse>
        <Card className="mb-0">
          <CardHeader id="headingTwo">
            {renderLinkButton(1, "How can I register on TelMDCare as a doctor?")}
          </CardHeader>
          <Collapse
            isOpen={accordion[1]}
            data-parent="#accordion"
            id="collapseTwo"
          >
            <CardBody>
              Visit the registration page on the TelMDCare website to officially register as a certified and practicing physician for our platform.
            </CardBody>
          </Collapse>
        </Card>
        <Card className="mb-0">
          <CardHeader id="headingThree">
            {renderLinkButton(2, "Can my family members use my TelMdCare account as well?")}
          </CardHeader>
          <Collapse
            isOpen={accordion[2]}
            data-parent="#accordion"
            id="collapseThree"
          >
            <CardBody>
              Yes, you can create separate profiles for your family members through your account and submit individual queries for each user.
            </CardBody>
          </Collapse>
        </Card>
        <Card className="mb-0">
          <CardHeader id="headingFour">
            {renderLinkButton(3, "Can TelMDCare be used on more than one device?")}
          </CardHeader>
          <Collapse
            isOpen={accordion[3]}
            data-parent="#accordion"
            id="collapseFour"
          >
            <CardBody>
              Yes, registered users can login using more than one device (Android, iOS, & Webpage) at a given time.
            </CardBody>
          </Collapse>
        </Card>
        <CardHeader>
          <h5>Query</h5>
        </CardHeader>
        <Card>
          <Card className="mb-0">
            <CardHeader>
              {renderLinkButton(
                4,
                `How to share previous history and consultation documents with
                  my doctor when I post a query?`
              )}
            </CardHeader>
            <Collapse
              isOpen={accordion[4]}
              data-parent="#accordion"
              id="collapseOne"
              aria-labelledby="headingOne"
            >
              <CardBody>
                The user will have to upload files while posting a query or to
                book a consultation.
              </CardBody>
            </Collapse>
          </Card>
          <Card className="mb-0">
            <CardHeader id="headingTwo">
              {renderLinkButton(5, "How do I chat with a doctor?")}
            </CardHeader>
            <Collapse
              isOpen={accordion[5]}
              data-parent="#accordion"
              id="collapseTwo"
            >
              <CardBody>
                You can chat with a doctor on the site by sending a message
                through the chat system, please be aware if it is an emergency
                you need to call 911 or go to closest hospital as provider has
                up to 24 hrs to respond back to your message.
              </CardBody>
            </Collapse>
          </Card>
          <Card className="mb-0">
            <CardHeader id="headingThree">
              {renderLinkButton(
                6,
                "Will I receive the doctor’s answer in my email?"
              )}
            </CardHeader>
            <Collapse
              isOpen={accordion[6]}
              data-parent="#accordion"
              id="collapseThree"
            >
              <CardBody>
                The user will be notified via email once their query has been
                answered. They will have to log in to their TelMDCare account to
                see the answer.
              </CardBody>
            </Collapse>
          </Card>
          <Card className="mb-0">
            <CardHeader id="headingFour">
              {renderLinkButton(
                7,
                "How soon will my queries be answered?"
              )}
            </CardHeader>
            <Collapse
              isOpen={accordion[7]}
              data-parent="#accordion"
              id="collapseFour"
            >
              <CardBody>
                Our online doctors address each query as soon as possible. You can typically expect a response in less than 1 hour. It may take longer if requests come after 10pm or before 7am.
              </CardBody>
            </Collapse>
          </Card>
          <Card className="mb-0">
            <CardHeader id="headingFour">
              {renderLinkButton(
                8,
                "How will I find out whether or not my query has been answered?"
              )}
            </CardHeader>
            <Collapse
              isOpen={accordion[8]}
              data-parent="#accordion"
              id="collapseFour"
            >
              <CardBody>
                You’ll receive an email from our team once our physician has answered your query.
              </CardBody>
            </Collapse>
          </Card>
        </Card>
        <CardHeader>
          <h5>Consultation</h5>
        </CardHeader>
        <Card className="mb-0">
          <CardHeader>
            {renderLinkButton(9, "How do I speak to a doctor over the phone?")}
          </CardHeader>
          <Collapse
            isOpen={accordion[9]}
            data-parent="#accordion"
            id="collapseOne"
            aria-labelledby="headingOne"
          >
            <CardBody>
              You will be able to speak with a doctor over the phone by booking
              a phone consultation. You will receive a call from one of our
              providers.
            </CardBody>
          </Collapse>
        </Card>
        <CardHeader>
          {" "}
          <h5>COVID-19 Diagnosis and Treatment? </h5>
        </CardHeader>
        <Card className="mb-0">
          <CardBody>
            Corona virus Risks, Symptoms, Diagnosis or Treatment Related
            <br /><br />
            <ListGroup flush>
              <ListGroupItemHeading>Are there any restrictions as far as online doctor consultations are concerned?</ListGroupItemHeading>
              <ListGroupItem>
                We do not document investigation reports.
              </ListGroupItem>
              <ListGroupItem>
                Consultations with our online doctors are non-refundable. This applies to patients with prescriptions.
              </ListGroupItem>
            </ListGroup>
            <br />
            <ListGroup flush>
              <ListGroupItemHeading>
                Our primary care physicians can:
              </ListGroupItemHeading>
              <ListGroupItem>
                Help decide whether different conditions are almost certain and
                recommend treatment(s).
              </ListGroupItem>
              <ListGroupItem>
                Advise when in-person assessment and research center testing are
                justified.
              </ListGroupItem>
              <ListGroupItem>
                Provide solution for lab test or potentially treatment option(s)
                for your thought.
              </ListGroupItem>
              <ListGroupItem>
                Issue a specialist's note for your work or school.
              </ListGroupItem>
            </ListGroup>
            <br />
          </CardBody>
        </Card>
        <CardHeader>
          <h5>What is TelMDCare and when should I use it?</h5>
        </CardHeader>
        <Card className="mb-0">
          <CardBody>
            TelMDCare is a facility which uses technology to deliver health care
            at a distance for acute non emergennt medical problems. It can be as
            a telephone call or video conference.
          </CardBody>
        </Card>
        <CardHeader>
          <h5>Can I get prescription or medication refill from TelMDCare?</h5>{" "}
        </CardHeader>
        <Card className="mb-0">
          <CardBody>
            Yes you can. However, there are many medications that cannot
            be prescribed online. Prescription or medication refills are ordered
            at the physician's discretion and require an online assessment of
            the patient before issuance.
          </CardBody>
        </Card>
        <CardHeader>
          <h5>
            Below is a partial list of commonly requested medications that our
            doctors do not prescribe online
          </h5>
        </CardHeader>
        <Card className="mb-0">
          <CardBody>
             
            {listOfDrugs.map((drug) => {
              return (
                <>
                  {drug} <br />
                </>
              );
            })}
          </CardBody>
        </Card>
        <CardHeader>
          <h5>Mental health (psychiatric care)?</h5>
        </CardHeader>
        <Card className="mb-0">
          <CardBody>
            <ListGroup flush>
              <ListGroupItem>
                Our providers will provide consultation and treatment for
                previously diagnosed psychiatric conditions mainly like
                medication refill or dose adjustment.
              </ListGroupItem>
              <ListGroupItem>
                No controlled substances, antipsychotic medications or
                therapeutic cannabinoids.
              </ListGroupItem>
              <ListGroupItem>
                Please Call 911 of you expererience suicidal or homicidal
                thoughts as our provider will not be able to help these
                cinditions.
              </ListGroupItem>
              <ListGroupItem>
                Please go to ER or call 911 if develop uncontrolled psychotic
                symptoms like delusions or hallucinations.
              </ListGroupItem>
            </ListGroup>
            <br />
          </CardBody>
        </Card>
        <CardHeader>
          {" "}
          <h5>Children (pediatric care)?</h5>
        </CardHeader>
        <Card className="mb-0">
          <CardBody>
            Will be able to provide care to children ( minimum age of 4 years
            old) with precense of parents.
          </CardBody>
        </Card>
        <CardHeader>Can I have a doctor's note for work or school?</CardHeader>
        <Card className="mb-0">
          <CardBody>
            Yes you can have one. Kindly schedule an appointment with our
            doctors who will advise further once they have diagnosed you.
          </CardBody>
        </Card>
        <CardHeader>
          {" "}
          <h5>Why does the doctor turn off video sometimes?</h5>
        </CardHeader>
        <Card className="mb-0">
          <CardBody>
            Our doctors respect the privacy of our patients and they as well
            uphold the code of ethics that medical practitioners abide by at all
            times. We have instructions on how patients can upload pictures
            before a scheduled visit which do not include the face. Our doctors
            only use videos when it is necessary and there is no other
            alternative.
          </CardBody>
        </Card>
        <CardHeader>
          <h5>Can I have an online visit without insurance?</h5>
        </CardHeader>
        <Card className="mb-0">
          <CardBody>
            Yes we do offer our services to all types of patients. All that is
            required is the doctor’s visit flat rate fee of $43.
          </CardBody>
        </Card>
        <CardHeader>
          <h5>Do you accept insurance?</h5>
        </CardHeader>
        <Card className="mb-0">
          <CardBody>
          Yes. We accept different insurance plans, including not limited to Blue Cross Blue Shields PPO plans, Aetna, Cigna, United Healthcare and Medicare. You may need to allow us to hold the full amount of the visit till the insurance confirms your eligibility and determines your co-payment. The exact amount of your co-payment and the transaction will be informed to you by email as you will receive a receipt of each visit. Our program protects you from any financial responsibility goes over $43.
          </CardBody>
        </Card>
      </BigCardFrame>
    </>
  );
};
