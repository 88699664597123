import * as React from "react";
import { ListGroup, ListGroupItemHeading, ListGroupItem, CardBody } from "reactstrap";
import TCNavbar from "../components/layout/TCNavBar";
import { BigCardFrame } from "../components/layout/BigCardFrame";
import { navItem } from "../App";
import { LandingPageHeader } from "../page/LandingPageHeader";
import { Helmet } from "react-helmet";
export const Help = () => {
    
  return (
    <>
      <Helmet>
        <title>Help - TelMDCare</title>
      </Helmet>
      <TCNavbar bodyClassName="tc-bg" />
      

      <div style={{ width: "100%"}}>
      <h3 className="text-white mx-3" style={{ textAlign: "center" }}>
        How to Unblock Calls From Unkwon Number
      </h3>
      <BigCardFrame flowNav={<LandingPageHeader />} navItem={navItem} bodyClassName="tc-bg">
          <CardBody>
        <ListGroup flush>
            <ListGroupItemHeading>
            Apple Iphone:
            </ListGroupItemHeading>
            <ListGroupItem>
                1. Go to Settings `{'>'}` Phone.
            </ListGroupItem>
            <ListGroupItem>
                2. Scroll down and turn off the option to Silence Unknown Callers.
            </ListGroupItem>
        </ListGroup>
        <br />
        <ListGroup flush>
        <ListGroupItemHeading>
            Android Phone:
            </ListGroupItemHeading>
            <ListGroupItem>
                1. Open your Phone app.
            </ListGroupItem>
            <ListGroupItem>
                2. Tap More ( upper  right corner).
            </ListGroupItem>
            <ListGroupItem>
                3. Tap Settings. `{'>>'}` Call rejection `{'>>'}` Auto reject list `{'>>'}` Swith off Blocking unkwon number.
            </ListGroupItem>
        </ListGroup>
        </CardBody>
        </BigCardFrame>
      </div>
    </>
  );
};
