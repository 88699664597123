import {
    FormGroup,
    Row,
    Label,
    Input,
    Container,
    Col
} from "reactstrap";
import React, { useState } from "react";
import moment from "moment";
import { BillingStatus, IBillerSearch } from "./HomeBiller";

export interface IBillerSearchProps {
    searchFilters: (searchOpts: any) => void;
}

export const BillerSearchBar = (props: IBillerSearchProps) => {
    const [search, setSearch] = useState<IBillerSearch>();

    const applySearchFilters = (searchOpts: any) => {
        console.log("search", searchOpts)
        props.searchFilters(searchOpts);
    };
    const validateTarget = (e: React.ChangeEvent<HTMLInputElement>) => {
        return e.target.value.length > 2
    }

    const renderSearch = () => {
        return (
            <>
                <Label>
                    Search Appointments by <strong>Patient Info</strong>
                </Label>
                <Row>
                    <Col>

                        <Input id="patientID"
                            placeholder={"Patient ID"}
                            type="text" className="form-control form-control-sm"
                            onChange={(e) => {

                                const searchObj = { ...search, patientID: parseInt(e.target.value) }
                                setSearch(searchObj);
                                applySearchFilters(searchObj)

                            }}
                        />
                    </Col>
                    <Col>

                        <Input id="patient_name"
                            placeholder={"Patient Name"}
                            type="text" className="form-control form-control-sm"
                            onChange={(e) => {
                                if (validateTarget(e)) {
                                    const searchObj = { ...search, patientName: e.target.value }
                                    setSearch(searchObj);
                                    applySearchFilters(searchObj)
                                } else if (e.target.value.length === 0) {
                                    const searchObj = { ...search, patientName: undefined }
                                    setSearch(searchObj);
                                    applySearchFilters(searchObj)
                                }
                            }}
                        />
                    </Col>
                    <Col>

                        <Input id="patient_mail"
                            placeholder={"Patient Email"}
                            type="text" className="form-control form-control-sm"
                            onChange={(e) => {
                                if (validateTarget(e)) {
                                    const searchObj = { ...search, patientEmail: e.target.value }
                                    setSearch(searchObj);
                                    applySearchFilters(searchObj)
                                } else if (e.target.value.length === 0) {
                                    const searchObj = { ...search, patientEmail: undefined }
                                    setSearch(searchObj);
                                    applySearchFilters(searchObj)
                                }
                            }}
                        />
                    </Col>
                    <Col>

                        <Input id="patient_phone"
                            placeholder={"Patient Phone Number"}
                            type="text" className="form-control form-control-sm"
                            onChange={(e) => {
                                if (validateTarget(e)) {
                                    const searchObj = { ...search, patientPhone: e.target.value }
                                    setSearch(searchObj);
                                    applySearchFilters(searchObj)
                                } else if (e.target.value.length === 0) {
                                    const searchObj = { ...search, patientPhone: undefined }
                                    setSearch(searchObj);
                                    applySearchFilters(searchObj)
                                }
                            }}
                        />
                    </Col>
                </Row>
                <br />
                <Label>
                    Search Appointments by <strong>Appointment Info</strong>
                </Label>
                <Row >

                    <Col >
                        <Label className="mb-0">Appointment Date</Label>
                        <Container className="d-flex">
                            <Row>
                                <Col>
                                    <Row>
                                        <Col md="12" lg="12" className="pl-0">
                                            <small><Label className="mb-0">from</Label></small>
                                        </Col>
                                        <Col md="12" lg="12" className="pl-0">
                                            <Input type="date" placeholder="From" className="form-control form-control-sm"
                                                onChange={(e) => {
                                                    console.log("date ", e.target.value)
                                                    let searchObj = { ...search }
                                                    if (e.target.value) {
                                                        searchObj = { ...search, appointmentDateFrom: moment(e.target.value).format('YYYY-MM-DD') || undefined }
                                                    } else {
                                                        searchObj = { ...search, appointmentDateFrom: undefined }

                                                    }
                                                    setSearch(searchObj);
                                                    applySearchFilters(searchObj)
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col md="12" lg="12" className="pl-0">
                                            <small><Label className="mb-0">to</Label></small>
                                        </Col>
                                        <Col md="12" lg="12" className="pl-0">
                                            <Input type="date" placeholder="To" className="form-control form-control-sm "
                                                onChange={(e) => {
                                                    console.log("date to ", e.target.value)

                                                    let searchObj = { ...search }
                                                    if (e.target.value) {
                                                        searchObj = { ...search, appointmentDateTo: moment(e.target.value).format('YYYY-MM-DD') || undefined }
                                                    } else {
                                                        searchObj = { ...search, appointmentDateTo: undefined }

                                                    }
                                                    setSearch(searchObj);
                                                    applySearchFilters(searchObj)
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>

                    </Col>
                    <Col >
                        <Label className="mt-3">Appointment Number</Label>
                        <Input id="appointment_number"
                            placeholder={"Appointment Number"}
                            type="text" className="form-control form-control-sm"
                            onChange={(e) => {
                                if (validateTarget(e)) {
                                    const searchObj = { ...search, appointmentNumber: e.target.value }
                                    setSearch(searchObj);
                                    applySearchFilters(searchObj)
                                } else if (e.target.value.length === 0) {
                                    const searchObj = { ...search, appointmentNumber: undefined }
                                    setSearch(searchObj);
                                    applySearchFilters(searchObj)
                                }
                            }}
                        />
                    </Col>
                </Row>

                <br />
                <Label>
                    Search Appointments by <strong>Insurance Info</strong>
                </Label>
                <Row>
                    <Col>

                        <Input id="payerCode"
                            placeholder={"Payer Code"}
                            type="text" className="form-control form-control-sm"
                            onChange={(e) => {
                                if (validateTarget(e)) {
                                    const searchObj = { ...search, payerCode: e.target.value }
                                    setSearch(searchObj);
                                    applySearchFilters(searchObj)
                                } else if (e.target.value.length === 0) {
                                    const searchObj = { ...search, payerCode: undefined }
                                    setSearch(searchObj);
                                    applySearchFilters(searchObj)
                                }
                            }}
                        />
                    </Col>
                    <Col>

                        <Input id="payerName"
                            placeholder={"Payer Name"}
                            type="text" className="form-control form-control-sm"
                            onChange={(e) => {
                                if (validateTarget(e)) {
                                    console.log("e.target.value", e.target.value)
                                    const searchObj = { ...search, payerName: e.target.value }
                                    setSearch(searchObj);
                                    applySearchFilters(searchObj)
                                } else if (e.target.value.length === 0) {
                                    const searchObj = { ...search, payerName: undefined }
                                    setSearch(searchObj);
                                    applySearchFilters(searchObj)
                                }
                            }}
                        />
                    </Col>

                </Row>
                <br />
                <Row >
                    <Col >
                        <Label >Insurance Listed</Label>
                        <Input
                            type="select"
                            className="form-control form-control-sm"
                            onChange={(e) => {
                                console.log(e.target.value)
                                var insuranceListed: boolean | undefined = undefined
                                if (e.target.value !== "All") {
                                    insuranceListed = (e.target.value === "true")
                                } else {
                                    insuranceListed = undefined
                                }
                                const searchObj = { ...search, insuranceListed: insuranceListed }
                                setSearch(searchObj);
                                applySearchFilters(searchObj)
                            }}
                        >
                            <option value={"All"}>All</option>
                            <option value="true">Insurance Listed</option>
                            <option value="false">Insurance Not Listed</option>
                        </Input>

                    </Col>

                    <Col>
                        <Label >Billing Status</Label>
                        <Input
                            type="select"
                            className="form-control form-control-sm"
                            onChange={(e) => {
                                let searchObj = { ...search }
                                switch (e.target.value) {
                                    case "Pending": {
                                        searchObj = { ...search, billingStatus: BillingStatus.Pending }
                                        break
                                    }
                                    case "Failed": {
                                        searchObj = { ...search, billingStatus: BillingStatus.Failed }
                                        break

                                    }
                                    case "Completed": {
                                        searchObj = { ...search, billingStatus: BillingStatus.Completed }
                                        break

                                    }
                                    case "All": {
                                        searchObj = { ...search, billingStatus: undefined }
                                        break

                                    }
                                }


                                setSearch(searchObj);
                                applySearchFilters(searchObj)
                            }}
                        >
                            <option value={"All"}>All</option>
                            <option value={BillingStatus.Pending}>Pending</option>
                            <option value={BillingStatus.Failed}>Failed</option>
                            <option value={BillingStatus.Completed}>Completed</option>
                        </Input>
                    </Col>

                </Row>
            </>
        );
    };
    return (
        <FormGroup className="px-3 mb-0">

            {renderSearch()}



        </FormGroup>
    );
};
