import React, { useState } from 'react';
// import { Button } from 'reactstrap';
import Asterisk from '../commons/Asterisk';

interface IProps {
  isEditable?: any;
  measurements?: any;
}
const YourMeasurements = (props: IProps) => {
  const [editMode, setEditMode] = useState(false);
  return (
    <h6 className="mb-3">
      Your Measurements{" "}<Asterisk/>
      {/* <Button
        color="link"
        size="sm"
        className="pt-0"
        onClick={() => {
          setEditMode(!editMode);
          props.isEditable();
        }}
      >
        <span>{!editMode ? 'Edit' : 'Done'}</span>
      </Button> */}
    </h6>
  );
};
export default YourMeasurements;
