export enum Routes {
    home = "/",
    patientSignin = "/patient-signin",
    patientSignup = "/patient-signup",
    homePatient = "/home-patient",
    patientVerication = "/verify-patient",
    quick = "/quick",
    medicalHistory = "/medical-history",
    requestReset = "/request-reset",
    passwordReset = "/password-reset",
    physicianPasswordReset = "/password-reset-doctor",
    passwordResetConfirm = "/password-reset-confirm",
    activateAccountPatient = "/verify",
    activateAccountAgent = "/verify-agent",
    activateAccountBiller = "/verify-biller",
    passwordResetAgent = "/password-agent",
    activateAccountPhysician = "/password-doctor",
    successfulSignup = "/signup-success",
    duplicateRegistryEmail = "/duplicate-email",
    welcome = "/welcome",
    physicianSignin = "/physician-signin",
    physicianSignup = "/physician-signup",
    physicianAuthentication = "/physician-authentication",
    homePhysician = "/home-physician",
    physicianTemplates = "/physician-templates",
    physicianSettings = "/physician-settings",
    patientSettings = "/patient-settings",
    bookAppointment = '/schedule-appt',
    patientAppointmentDetails = '/patient-appt-details',
    physicianAppointmentDetails = '/physician-appt-details',
    adminLogin = "/admin-login",
    agentLogin = "/agent-login",
    agentSignup = "/agent-signup",
    homeAgent = "home-agent",
    billerLogin = "/biller-login",
    billerSignup = "/biller-signup",
    homeBiller = "home-biller",
    passwordResetBiller = "/password-biller",
    adminConsole = "/admin-console",
    appointmentHistory = "/appointment-history",
    agentHistory = "/agent-history",
    adminConfigurationSettings = "/settings",
    adminPasswordReset = "/password-admin",
    adminPhysicianSettings = "/admin-physician-settings",
    faq = "/FAQ",
    about = "/About",
    privacyPolicy = "/privacy-policy",
    termsOfUse = "/terms-of-use",
    contact = "/contact",
    help = "/help",
    notFound = "*",
    unsubscribe = '/unsubscribe',
    resendEmail = '/resendEmail',

    //insurance 

    insuranceAdmin = "/insurance-list",

}

export enum CRoutes {
    virtualAppointments = "/c/virtual-appointments-and-consultation.html",
    onlinePrescriptions = "/c/online-prescriptions-doctors.html",
    acuteVaginitis = "/c/acute-vaginitis.html",
    vaginalYeastInfection = "/c/online-doctor-for-vaginal-yeast-infection.html",
    bacterialVaginosis = "/c/online-doctor-for-acute-vaginitis.html",
    sexuallyTransmittedDiseases = "/c/online-doctor-for-sexually-transmitted-diseases.html",
    sinusInfection = "/c/online-doctor-for-sinus-infection.html",
    urinaryTractInfection = "/c/online-doctor-for-urinary-tract-infection.html",
    urinaryTractInfectionForWomen = "/c/online-doctor-for-urinary-tract-infection-in-women.html",
    urinaryTractInfectionFormen = "/c/online-doctor-for-urinary-tract-infection-in-men.html",
    scabies = "/c/online-doctor-for-scabies.html",
    allergies = "/c/online-doctor-for-allergies.html",
    coldSores = "/c/online-doctor-for-cold-sores.html",
    bronchitis = "/c/online-doctor-for-bronchitis.html",
    nauseaVomiting = "/c/online-doctor-for-nausea-vomiting.html",
    pinkEye = "/c/online-doctor-for-pink-eye.html",
    respiratoryInfection = "/c/online-doctor-for-respiratory-infection.html",
    painRelief = "/c/online-doctor-for-pain-relief.html",
    oralThrush = "/c/online-doctor-for-oral-thrush.html",
    erectileDysfunctions = "/c/online-doctor-for-erectile-dysfunction.html",
    motionSickness = "/c/online-doctor-for-motion-sickness.html",
    strepThroat = "/c/online-doctor-for-strep-throat.html",
    goutAttack = "/c/online-doctor-for-gout-attack.html",
    earInfections = "/c/online-doctor-for-ear-infections.html",
    dentalInfection = "/c/online-doctor-for-dental-infection.html",
    covidQuestions = "/c/online-doctor-for-covid-19-questions.html",
    diabetes = "/c/online-doctor-for-diabetes.html",
    asthma = "/c/online-doctor-for-acid-reflux.html",
    antiDepression = "/c/online-doctor-for-anti-depression.html",
    acidReflux = "/c/online-doctor-for-acid-reflux.html",
    highCholestrol = "/c/online-doctor-for-high-cholesterol.html",
    hypertensionTreatments = "/c/online-doctor-for-hypertension-treatments.html",
    hypothyroidism = "/c/online-doctor-for-hypothyroidism.html",
    migraine = "/c/online-doctor-for-migraine.html",
    smokeCessation = "/c/online-doctor-for-smoke-cessation.html",
    medicationRefill = "/c/online-doctor-for-medication-refill.html",
    molnupiravirAndPaxlovid = "/c/online-doctor-for-molnupiravir-and-paxlovid.html",
    ivermectin = "/c/online-doctor-for-ivermectin.html",
    acne = "/c/online-doctor-for-acne.html",
    trichomonas= "/c/online-doctor-for-trichomoniasis.html",
    chlamydiaGonorrhea = "/c/online-doctor-for-chlamydia-gonorrhea.html",
    genitalHerpes = "/c/online-doctor-for-genital-herpes.html",
    birthControl = "/c/online-doctor-for-birth-control-refills.html",
    menstrualSuppression = "/c/online-doctor-for-menstrual-suppression.html",
    jockItch = "/c/online-doctor-for-jock-itch.html",
    urethritis = "/c/online-doctor-for-urethritis.html",
    prematureEjaculation = "/c/online-doctor-for-premature-ejaculation.html",
    balanitis = "/c/online-doctor-for-balanitis.html",
    dandruff = "/c/online-doctor-for-dandruff.html",
    eczema = "/c/online-doctor-for-eczema.html",
    excessiveSweating = "/c/online-doctor-for-hyperhidrosis-excessive-sweating.html",
    hairLoss = "/c/online-doctor-for-hair-loss.html",
    stye = "/c/online-doctor-for-stye.html",
    skinInfection = "/c/online-doctor-for-skin-infection.html",
    psoriasis = "/c/online-doctor-for-psoriasis.html",

    blog = "/c/blog.html",
    siteMap = "/c/sitemap.html",

    illions = "/c/illinois-TelMDCare.html",
    alabama = "/c/alabama.html",
    arizona = "/c/arizona.html",
    colorado = "/c/colorado.html",
    idaho = "/c/idaho.html",
    indiana = "/c/indiana.html",
    iowa = "/c/iowa.html",
    kansas = "/c/kansas.html",
    kentucky = "/c/kentucky.html",
    michigan = "/c/michigan.html",
    minnesota = "/c/minnesota.html",
    nebraska = "/c/nebraska.html",
    nevada = "/c/nevada.html",
    northDakota = "/c/north-dakota.html",
    oklahoma = "/c/oklahoma.html",
    southDakota = "/c/south-dakota.html",
    tennessee = "/c/tennessee.html",
    utah = "/c/utah.html",
    washington = "/c/washington.html",
    wisconsin = "/c/wisconsin.html",
    newJersey = "/c/new-jersey.html",
    florida = "/c/florida.html",
    oregon = "/c/oregon.html",
    maine = "/c/maine.html",
    maryland = "/c/maryland.html",
    montana = "/c/montana.html",
    louisiana = "/c/louisiana.html",
    vermont = "/c/vermont.html",
    guam = "/c/guam.html",
    mississippi = "/c/mississippi.html",
    newHampshire = "/c/new-hampshire.html",
    westVirginia = "/c/west-virginia.html",
    wyoming = "/c/wyoming.html",
    georgia = "/c/georgia.html",
    texas = "/c/texas.html",
    delaware = "/c/delaware.html",
    ohio = "/c/ohio.html"
}
