import React, { useState, useEffect } from 'react';
import {
    Button,
    Input,
    Label
} from 'reactstrap';
import { IPatientInfoSettings } from '../PatientSettings';
import {
    getPayersListForPatient,
    deleteInsurancePatient,
    getUserHasInsurance,
} from '../../../core/service/services';
import { strings } from '../../../Constants';
import { IPayerPreview } from '../../../page/avility/InsuranceAdmin';
import ConfigurationInsuranceModal from './configurationsComponents/ConfigurationsInsuranceModal';
import { AlertMessage } from '../../../core/alert/Alerts';
import { IInsuranceResponse } from '../../../redux/types/interfaces/bookingInterfaces';

interface IProps {
    info?: IPatientInfoSettings;
    insurance?: IInsuranceResponse;
    insuranceRemovedCallback?: any;
    insuranceSelectedCallback?: any;
    dependentId?: number
}

const InsuranceSection = (props: IProps) => {
    const [payersList, setPayerList] = useState<IPayerPreview[]>();
    const [payerSelected, setPayerSelected] = useState<string | undefined>("");
    const [payerNameSelected, setPayerName] = useState("");

    const [isConfigModalOpen, setConfigModalOpen] = useState(false);
    const toggleConfigModal = () => setConfigModalOpen(!isConfigModalOpen);
    const [isAlert, setIsAlert] = useState<boolean>(false);
    const toggleAlert = () => { setIsAlert(!isAlert); }

    const [userHasInsurance, setUserHasInsurance] = useState(false)
    const [payerSelectedAfterConf, setPayerSelectedAfterConf] = useState<IPayerPreview | undefined>(undefined)
    useEffect(() => getData(), []);

    const getData = () => {
        console.log("dependent id", props.dependentId)
        getPayersListForPatient()
            .then((res) => {
                setPayerList(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (props.info?.insurance) {
            setPayerSelectedAfterConf(
                {
                    name: props.info?.insurance.payerName,
                    id: props.info?.insurance.id,
                    code: props.info?.insurance.payerCode,
                    description: ""
                }
            )
            setUserHasInsurance(true)
        } else if (props.insurance) {
            setPayerSelectedAfterConf(
                {
                    name: props.insurance.payerName,
                    id: props.insurance.id,
                    code: props.insurance.payerCode,
                    description: ""
                }
            )
            setUserHasInsurance(true)
        } else if (props.dependentId) {
            getUserHasInsurance(props.dependentId).then((res) => {
                if (Object.entries(res).length === 0) {
                    return
                }

                setPayerSelectedAfterConf(
                    {
                        name: res.payerName,
                        id: res.id,
                        code: res.payerCode,
                        description: ""
                    }
                )
                setUserHasInsurance(true)
            }).catch((err) => {
                console.log(err)

            })
        }
    }, [props])


    const createSelectItems = () => {
        if (payersList) {
            return payersList.map((payer, idx) => {
                return (
                    <option key={idx} value={payer.code} >
                        {payer.name}
                    </option>
                );
            });
        }
    };
    // el takhalof ely ana 3amlo dah lazem ashilo before committing 
    const payersSelected = (e: any) => {
        setPayerSelected(e.target.value)
        if (payersList) {
            payersList.forEach((item) => {
                if (item.code == e.target.value) {
                    setPayerName(item.name)

                }
            })
        }


        console.log("Payer Name", e.target)
        // open modal for conf
        // call conf 
        toggleConfigModal();
    }
    const onCancel = () => {
        toggleConfigModal();
        setPayerSelected(strings.patientSettings.select_insurance)

    }
    const onSave = (res: any) => {
        setUserHasInsurance(true)
        setPayerSelectedAfterConf(
            {
                name: res.payerName,
                id: res.id,
                code: res.payerCode,
                description: ""
            }
        )
        setUserHasInsurance(true)
        if (props.insuranceSelectedCallback) {
            props.insuranceSelectedCallback(res)

        }
        toggleConfigModal();

    }

    const deleteInsurance = (response: boolean) => {
        if (response) {
            deleteInsurancePatient(props.dependentId).then(() => {
                setPayerSelectedAfterConf(undefined)
                setUserHasInsurance(false)
                setPayerSelected(strings.patientSettings.select_insurance)

                toggleAlert()
                if (props.insuranceRemovedCallback) {
                    props.insuranceRemovedCallback()
                }// => so that it would be removed in the booking process 
            })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            toggleAlert()

        }

    }
    const renderInsuranceSelected = () => {

        if (userHasInsurance) {
            return (
                <>
                    <dl>
                        <dt>
                            <Label>{payerSelectedAfterConf?.name}</Label>
                            {"    "}
                            <Button
                                data-toggle="modal"
                                onClick={toggleAlert}
                                size="sm"
                                color="link"
                            >
                                <span className="sr-only">Remove</span>
                                <i className="fa fa-trash"></i>
                            </Button>
                        </dt>



                    </dl>


                </>
            )
        } else {
            return (
                <>
                    <Label for="state">{strings.patientSettings.please_select_insurance}</Label>

                    <Input
                        type="select"
                        id="insurance"
                        name="insurance"
                        required
                        onChange={payersSelected}
                        value={payerSelected}
                    >
                        <option value="">{strings.patientSettings.select_insurance}</option>
                        {createSelectItems()}
                    </Input>
                    <br></br>
                </>
            )
        }
    }
    const renderAlertRemoveInsurance = () => {
        return (
            <AlertMessage isOpen={isAlert} toggleOpen={toggleAlert} message="Are you sure you want to delete your insurance ?" setResponse={deleteInsurance} options={["Yes", "No"]} />
        )
    }

    return (
        <div>
            {renderAlertRemoveInsurance()}
            <h6 className="mb-3">
                <Label>{strings.patientSettings.insurance}</Label>

            </h6>
            <ConfigurationInsuranceModal dependentId={props.dependentId} isOpen={isConfigModalOpen} toggle={toggleConfigModal} onSave={onSave} onCancel={onCancel} payerCode={payerSelected} payerName={payerNameSelected}></ConfigurationInsuranceModal>

            {renderInsuranceSelected()}




        </div>
    );
};
export default InsuranceSection;
