import React, { useEffect } from 'react';
var postscribe = require('postscribe');
interface IProps{
    id:string
}
export const BBBscript=(props:IProps)=>{
    useEffect(()=> {
        
        postscribe(`#${props.id}`, `<script type="text/javascript" src="https://seal-chicago.bbb.org/inc/legacy.js"></script>
        <a href="https://www.bbb.org/us/il/lemont/profile/telemedicine/telmdcare-com-0654-1000019608/#sealclick" id="bbblink" class="sevtbum" target="_blank" rel="nofollow"><img loading="lazy" src="https://seal-chicago.bbb.org/logo/sevtbum/bbb-1000019608.png" style="border: 0;" alt="TelMDCare.com BBB Business Review" /></a>`, {
            done: function() {
                console.info('BBB Script has been executed.');
            }
        })
    },[])
    return(
        <div id={props.id}></div>
    )
}