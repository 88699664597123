import React, { useState, useEffect } from 'react';
import { Card, CardBody, ListGroupItem, ListGroup, Row, Col } from 'reactstrap';
import { AgentPatientSearchBar } from './AgentPatientSearchBar';
import { CustomedPagination } from '../admin/Pagination';
import { PatientItemCard } from './PatientItemCard';
import { IUSState } from '../../settings/PhysicianSettings';
import { getUSStatesLookup, getPatientsForAgent } from '../../../core/service/services';
import { AgentSessionConfig } from '../../../config/agentSessionConfig';

export interface IListPatientsRequestQuery {
  name?: string,
  stateId?: number,
  phoneNumber?: string,
  dob?: string,
  emailAddress?: string,
  joinDateFrom?: string,
  joinDateTo?: string,
  appointmentStatus?: any,
  patientId?:number
}

export interface IPatientForAgents{
  id:number,
  firstName?: string,
  lastName?: string,
  DOB?: Date | null,
  age?: number,
  gender?: string | null;
  active?: boolean
  isUser?: boolean,
  dependsOnRelation?: string
}

export interface IListPatientsResponse{
  pageSize:number,
  pageIndex:number,
  length:number,
  items:IPatientForAgents[],
}

export const PatientsOverview = () => {

  const [patients, setPatients] = useState<IPatientForAgents[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [uSStates, setUSStates] = useState<IUSState[]>([]);
  const [params, setParams] = useState<IListPatientsRequestQuery>();

  useEffect(() => {
    fetchData_States();

    let index = AgentSessionConfig.getPageIndex();
    let filters = AgentSessionConfig.getFiltersInfo()  || undefined;
    setParams(filters);
    fetchPatients(index, pageSize, filters)
  }, []);

  const fetchPatients=(index: number, size: number, searchOptions?: IListPatientsRequestQuery) =>{
    getPatientsForAgent(index,size,searchOptions)
      .then((result:IListPatientsResponse)=>{
        setPatients(result.items);
        setPageIndex(result.pageIndex);
        setPageSize(result.pageSize);
        result.pageSize != 0 && setPageCount(Math.ceil(result.length/result.pageSize));
      }).catch((error) => {
        initializeStates();
      })
  }

  const initializeStates=()=>{
    setPatients([]);
    setPageCount(0);
    setPageIndex(0);
    setPageSize(10);
  }
  
  const fetchData_States = () => {
    getUSStatesLookup().then((states) => {
      setUSStates(states);
    });
  };

  const setAgentSession=()=>{
    AgentSessionConfig.setPageIndex(pageIndex);
    AgentSessionConfig.setFiltersInfo(params || null);
  }

  const returnBorderColor=(patient:IPatientForAgents)=>{
    return patient.isUser === true ? "#007bff" : 'green';
  }
  const renderPatientCards = () => {
    return (
      <ListGroup className="timeline patients">
        {patients.map((item, key) => (
          <ListGroupItem key={key} style={{ borderTopWidth: "thin", borderColor: `${returnBorderColor(item)}`, padding: "0.5rem" }}>
            <PatientItemCard patient={item} setAgentSession={setAgentSession}/>
          </ListGroupItem>
        ))}
      </ListGroup>
    )
  }
  const onPaginate=(idx:any)=>{
    setPageIndex(idx); 
    fetchPatients(idx, pageSize, params)
  }
  const searchFilters =(searchOptions:IListPatientsRequestQuery)=>{
    
    setParams(searchOptions);
    fetchPatients(0, pageSize,searchOptions )
  }
  return (
    <>
      <h5 className="text-white mx-3"><i className="fa fa-user m-auto"></i> Patients</h5>
      <Card className="card-telecare mt-3 mb-5">
        <CardBody>
          <section className="section-content-block">
            <AgentPatientSearchBar 
              uSStates={uSStates}
              filters={params} 
              searchFilters={searchFilters}/>
            <ListGroup>
              {renderPatientCards()}
            </ListGroup>
          </section>
        </CardBody>
        { patients.length > 0 && pageCount &&
        <CustomedPagination
          pageCount={pageCount}
          pageNum={pageIndex}
          onPaginate={onPaginate}
        />
        }
      </Card>
    </>
  )
}