import { IFullSelfReportedItem } from "../../core/service/services"
import {
  IDoseSpotPrescription,
} from "../types/interfaces/medicalProfileInterfaces"

export const medicationAllergyExistsInArray = (tempArray: Array<any>, medicationAllergy: any) => {
  return tempArray.filter(item => item.Code === medicationAllergy.Code).length !== 0
}

export const getMedItemFromPres = (item: IDoseSpotPrescription): IFullSelfReportedItem => {
  return {
    selfReportedMedicationItem:{
      Quantity: 1,
      Status: 1,
      DispensableDrugId:item.DispensableDrugId,
      SelfReportedMedicationId:0
    },
    name: item.DisplayName,
    strength: item.Strength,
  };
}