import React, { useState, useEffect } from "react";
import { Link, navigate } from "@reach/router";
import { UncontrolledTooltip } from "reactstrap";
import { IPhysician } from "./PhysiciansOverview";
import { AlertMessage } from "../../../core/alert/Alerts";
import { enablePhysician } from "../../../core/service/services";
import { toastSuccess } from "../../../App";
import { Routes } from "../../../config/Routes";

interface IProps {
  data: IPhysician
  updateItem: (item: IPhysician) => void
}

export type AdminAction = "APPROVE" | "ENABLE" | "DISABLE" | "NONE"

export const PhysicianItemActions: React.FC<IProps> = (props) => {
  
  const [confirmationMessage, setConfirmationMessage] = useState<string>("")
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const [currentAction, setCurrentAction] = useState<AdminAction>("NONE")
  const [physician, setPhysician] = useState<IPhysician>(props.data)

  useEffect(() => {
    setPhysician(props.data)
  }, [props.data])

  const phName = props.data.firstname + " " + props.data.lastname

  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal)
  }

  const openPhysicianSettings = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(Routes.adminPhysicianSettings + '?phId=' + physician.id)
  }

  const settingsBtn = () => (
    <Link to="" onClick={openPhysicianSettings} className="text-secondary">
      <UncontrolledTooltip placement="top" target="phSettings">Open Physician Settings</UncontrolledTooltip>
      <i className="fa fa-2x fa-cog" id="phSettings" />
    </Link>
  )

  const enableBtn = () => (
    <Link to="" onClick={() => onEnableClicked(phName)} className="text-primary">
      <UncontrolledTooltip placement="top" target="enablePhysician">Enable Physician</UncontrolledTooltip>
      <i className="fa fa-2x fa-check-circle" id="enablePhysician" />
    </Link>
  )

  const disableBtn = () => (
    <Link to="" onClick={() => onDisableClicked(phName)} className="text-danger">
      <UncontrolledTooltip placement="top" target="disablePhysician">Disable Physician</UncontrolledTooltip>
      <i className="fa fa-2x fa-times-circle" id="disablePhysician" />
    </Link>
  )
  
  const approveBtn = () => (
    <Link to="" onClick={() => onApproveClicked(phName)} className="text-success">
      <UncontrolledTooltip placement="top" target="approvePhysician">Approve Physician</UncontrolledTooltip>
      <i className="fa fa-2x fa-check-square" id="approvePhysician" />
    </Link>
  )

  const enableDoctor = (enabled: boolean) => {
    enablePhysician(enabled, physician.id).then(res => {
      props.updateItem(res)
      toastSuccess(enabled? "Physician is allowed to use the platform functions!" : "Physician Disabled!")
    }).catch(err => console.log(err))
  }

  const onAdminAction = (response: boolean) => {
    if (!response) {
      switch(currentAction) {
        case "APPROVE":
          enableDoctor(true)
          break;
        case "ENABLE":
          enableDoctor(true)
          break;
        case "DISABLE":
          enableDoctor(false)
          break;  
      }
    }
    else{
    toggleConfirmationModal()
  }}

  const onApproveClicked = (phName: string) => {
    setConfirmationMessage("Are you sure you want to approve Dr. " + phName + "?")
    setCurrentAction("APPROVE")
    setConfirmationModal(true)
  }

  const onEnableClicked = (phName: string) => {
    setConfirmationMessage("Are you sure you want to enable access for Dr. " + phName + "?")
    setCurrentAction("ENABLE")
    setConfirmationModal(true)
  }

  const onDisableClicked = (phName: string) => {
    setConfirmationMessage("Are you sure you want to disable access for Dr. " + phName + "?")
    setCurrentAction("DISABLE")
    setConfirmationModal(true)
  }

  const status = physician.status

  return (
    <div className="actionsStyle">
      <AlertMessage isOpen={confirmationModal} message={confirmationMessage} options={["No","Yes"]} 
          toggleOpen={toggleConfirmationModal} setResponse={onAdminAction} />
      {settingsBtn()}
      {status === "Disabled" && enableBtn()}
      {status === "Active" && disableBtn()}
      {status === "New" && approveBtn()}
    </div>
  )
}
