import { IListPatientsRequestQuery } from "../page/dashboard/agent/PatientsOverview";

export class AgentSessionConfig {
	private static readonly filters = "filters";
	static readonly setFiltersInfo = (filters: IListPatientsRequestQuery | null) => {
		console.log("Filters", filters);
		if (filters != null || filters != undefined) {
			const info = JSON.stringify(filters);
			window.sessionStorage.setItem(AgentSessionConfig.filters, info);
		} else {
			window.sessionStorage.removeItem(AgentSessionConfig.filters);
		}
	}
	static readonly getFiltersInfo: () => IListPatientsRequestQuery | null = () => {
		const info = window.sessionStorage.getItem(AgentSessionConfig.filters);
		if (info != undefined && info != null) {
			return JSON.parse(info);
		}
		return null;
	}
	private static readonly pageSize = "pageSize";
	static readonly setPageSize = (pageSize: number | null) => {
		if (pageSize !== null) {
			const info = pageSize.toString();
			window.sessionStorage.setItem(AgentSessionConfig.pageSize, info);
		} else {
			window.sessionStorage.removeItem(AgentSessionConfig.pageSize);
		}
	}
	static readonly getPageSize: () => number = () => {
		const info = window.sessionStorage.getItem(AgentSessionConfig.pageSize);
		if (info && info !== null) {
			return parseInt(info);
		}
		return 0;
	}

	private static readonly pageIndex = "pageIndex";
	static readonly setPageIndex = (pageIndex: number | null) => {
		if (pageIndex && pageIndex !== null) {
			const info = pageIndex.toString();
			window.sessionStorage.setItem(AgentSessionConfig.pageIndex, info);
		} else {
			window.sessionStorage.removeItem(AgentSessionConfig.pageIndex);
		}
	}
	static readonly getPageIndex: () => number = () => {
		const info = window.sessionStorage.getItem(AgentSessionConfig.pageIndex);
		if (info) {
			return parseInt(info);
		}
		return 0;
	}
	private static readonly collapse = "collapse";
	static readonly setIsCollapsed = (collapse: boolean | null) => {
		if (collapse && collapse !== null) {
			const info = collapse.toString();
			window.sessionStorage.setItem(AgentSessionConfig.collapse, info);
		} else {
			window.sessionStorage.removeItem(AgentSessionConfig.collapse);
		}
	}
	static readonly getIsCollapsed: () => boolean = () =>{
		const info = window.sessionStorage.getItem(AgentSessionConfig.collapse);
		if (info) {
			return (info === "true"? true:false);
		}
		return false;
	}
}