import React from 'react';
import { Label } from 'reactstrap';

interface IProps {
  hidden?: boolean
}

export const Asterisk: React.FC<IProps> = (props) => {
  if (!props.hidden) {
    return <Label className="fas fa-asterisk" style={Style.asterisk}></Label>;
  }
  return <></>
};

const Style = {
  asterisk: {
    position: 'absolute' as 'absolute',
    color: 'red',
    fontSize: '6px',
  },
};

export default Asterisk;
