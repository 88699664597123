import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";
import { IRxReconcile } from "../../../core/service/services";

interface IProps {
    rxReconcile: IRxReconcile,
    onItemClicked: (IRxReconcile: IRxReconcile) => void
}

export const RxReconcileItem: React.FC<IProps> = props => {

    const [rxReconcile, setRxReconcile] = useState<IRxReconcile>(props.rxReconcile)

    useEffect(() => {
        setRxReconcile(props.rxReconcile)
    }, [props.rxReconcile])


    const renderTitle = () => {
        return (

            <Link onClick={e => onRxClicked(e)} className="mr-auto" to="#">
                <div>Reconcile Medicine : {rxReconcile.doseSpotDisplayName}</div>
                {rxReconcile.patientFirstName + " " + rxReconcile.patientLastName}
            </Link>



        )

    }


    const onRxClicked = (e: React.MouseEvent) => {
        e.preventDefault()
        props.onItemClicked(rxReconcile)
    }


    // const statusBadge = () => {
    //     let color = ""

    //     return (
    //         <Badge color={color} className="p-1 mr-1 ">{status}</Badge>
    //     )
    // }


    return (
        <div>

            {renderTitle()}



        </div>
    );
};
