import React from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";

interface IInsuranceNotListedModal {
    isOpen: boolean,
    toggleModal: any,
    onSave: any,
}
export const NotEligibleModal = (props: IInsuranceNotListedModal) => {


    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={props.toggleModal} >
                <ModalHeader toggle={props.toggleModal}>Action Required </ModalHeader>
                <ModalBody >
                    <div>According to the insurance info provided, we did not receive a positive response from your insurance company. However, we can still contact your insurance company to get coverage details, and - just for now - the full service fees will be authorized on your credit card. To do so, please click on "Upload insurance ID". Otherwise, you can proceed with booking your appointment without insurance coverage by clicking on "Proceed without Insurance". </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        props.onSave(true)
                        props.toggleModal()
                    }}>Upload My Card</Button>{' '}
                    <Button color="secondary" onClick={() => {
                        props.onSave(false)
                        props.toggleModal()
                    }}>Proceed without Insurance</Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
    );
}