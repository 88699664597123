import * as React from "react";
import { BigCardFrame } from "../components/layout/BigCardFrame";
import {
  CardBody,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
} from "reactstrap";
import { LandingPageHeader } from "../page/LandingPageHeader";
import { Helmet } from "react-helmet";
export const TermsOfUse = () => {
  const renderHeaderAndContent = (title: string, content: string) => {
    return (
      <>
        <h5>{title}</h5>
        {content}
        <br />
        <br />
      </>
    );
  };
  return (
    <>
      <Helmet>
        <title>Terms Of Use - TelMDCare</title>
      </Helmet>
      <h3 className="text-white mx-3" style={{ textAlign: "center" }}>
        TERMS AND CONDITIONS
      </h3>
      <BigCardFrame
        flowNav={<LandingPageHeader />}
        navItem={undefined}
        bodyClassName="tc-bg"
      >
        <CardBody>
          These Terms and Conditions set forth the guidelines governing your use
          of the TelMDCare telemedicine service (the "Service"). The service is
          offered by TelMDCare. Please read the Terms and Conditions carefully
          before using the Service. BY CLICKING "I ACCEPT", YOU ARE ENTERING
          INTO A LEGALLY BINDING AGREEMENT TO THESE TERMS AND CONDITIONS JUST AS
          YOU WOULD BY SIGNING A PAPER CONTRACT. If you do not agree with and
          accept these Terms and Conditions, do not use the Service. DO NOT USE
          THIS SITE FOR EMERGENCY MEDICAL NEEDS. If you experience a medical
          emergency, call 911 immediately.
          <br />
          <br />
          {renderHeaderAndContent(
            "Website Content",
            `Other than information received directly by you from physicians in the context of a telemedicine service, the content on the website should not be considered medical advice. We may post or provide links to general information resources that may be of interest to you ("Posted Materials"). Posted Materials are not to be used as medical diagnosis, treatment or advice. Other than information received directly by you from physicians in the context of a telemedicine service, you should always talk to an appropriately qualified health care professional for diagnosis and treatment, including information regarding which medications or treatment may be appropriate for you. None of the content on this website represents or warrants that any particular medication or treatment is safe, appropriate, or effective for you. Without limitation, TelMDCare does not recommend or endorse any specific tests, physicians, medications, products or procedures.`
          )}
          {renderHeaderAndContent(
            "Account Enrollment ",
            `To access the Service, you must first enroll to establish an individual user account ("Account"), by providing certain information. You agree that you will not create more than one Account, or create an Account for anyone other than yourself without first receiving permission from the other person. In exchange for your use of the Service and, if applicable, in order for physicians to send notices to you, you agree to: (i) provide true, accurate, current and complete information about yourself as prompted by our Account enrollment form; and (ii) each time you log on, maintain and promptly update such Account information to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or we have reasonable grounds to suspect that such Account information is untrue, inaccurate, not current or incomplete, we reserve the right to suspend or terminate your Account and refuse any and all current or future use of the Service. You represent and warrant that you are at least 18 years of age and possess the legal right and ability to agree to these Terms and Conditions.
`
          )}
          {renderHeaderAndContent(
            "Acceptable Use ",
            `You agree not to access or use the Service in an unlawful way or for an unlawful or illegitimate purpose or in any manner that contravenes these Terms and Conditions. You shall not post, use, store or transmit (a) a message or information under a false name; (b) information that is unlawful, libelous, defamatory, obscene, fraudulent, predatory of minors, harassing, threatening or hateful to any person; or (c) information that infringes or violates any of the intellectual property rights of others or the privacy or publicity rights of others. You shall not attempt to disrupt the operation of the Service by any method, including through use of viruses, Trojan horses, worms, time bombs, denial of service attacks, flooding or spamming. You shall not use the Service in any manner that could damage, disable or impair the Service. You shall not attempt to gain unauthorized access to any user accounts or computer systems or networks, through hacking, password mining or any other means. You shall not use any robot, scraper or other means to access the Service for any purpose.`
          )}
          {renderHeaderAndContent(
            "Claims of Copyright Infringement ",
            `TelMDCare disclaims any responsibility or liability for copyrighted materials posted on its site. If you believe that your work has been copied in a manner that constitutes copyright infringement, please follow the procedures set forth below. 
TelMDCare respects the intellectual property rights of others and expects its users to do the same. In accordance with the Digital Millennium Copyright Act (“DMCA”), we will respond promptly to notices of alleged infringement that are reported. 
If you are a copyright owner, authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please report alleged copyright infringements taking place on or through our site by sending us a notice complying with the following 
`
          )}
          <ListGroup flush>
            <ListGroupItemHeading>requirements:-</ListGroupItemHeading>
            <ListGroupItem>
              Identify the copyrighted works that you claim have been infringed.
            </ListGroupItem>
            <ListGroupItem>
              Identify the material or link you claim is infringing and that
              access to which is to be disabled, including at a minimum, if
              applicable, the URL of the link shown on the site where such
              material may be found.
            </ListGroupItem>
            <ListGroupItem>
              Provide your mailing address, telephone number and if available
              email address.
            </ListGroupItem>
            <ListGroupItem>
              Include both of the following statements in the body of the
              Notice:
              <ListGroup flush>
                <ListGroupItem>
                  “I hereby state that I have a good faith belief that the
                  disputed use of the copyrighted material is not authorized by
                  the copyright owner, its agent, or the law.”
                </ListGroupItem>
                <ListGroupItem>
                  “I hereby state that the information in this Notice is
                  accurate and under penalty of perjury that I am the owner or
                  authorized to act on behalf of the owner of the copyright or
                  of an exclusive right under the copyright that is allegedly
                  infringed.” Provide your full legal name and your electronic
                  or physical signature.
                </ListGroupItem>
              </ListGroup>
            </ListGroupItem>
          </ListGroup>
          <br />
          <h5>Informed Consent</h5>
          <h6>1. Possible Risks of Telemedicine:</h6>
          As with any medical procedure, there are potential risks associated
          with the use of telemedicine. TelMDCare believes that the likelihood
          of these risks materializing is very low. These risks may include,
          without limitation, the following:
          <br />
          <ListGroup flush>
            <ListGroupItem>
              Delays in medical evaluation and consultation or treatment may
              occur due to deficiencies or failures of the equipment which may
              include poor video and data quality.
            </ListGroupItem>
            <ListGroupItem>
              Security protocols could fail, causing a breach of privacy of
              personal medical information.
            </ListGroupItem>
          </ListGroup>
          <br />
          By accepting these Terms of Conditions, you acknowledge that you
          understand and agree with the following:
          <br />
          You understand that the laws that protect privacy and the
          confidentiality of medical information also apply to telemedicine.
          <br />
          You understand that telemedicine may involve electronic communication
          of your personal medical information to medical practitioners who may
          be located in other areas.
          <br />
          <br />
          <h6>2. Patient Consent to the Use of Telemedicine</h6>I HAVE READ AND
          UNDERSTAND THE INFORMATION PROVIDED ABOVE, AND UNDERSTAND THE RISKS
          AND BENEFITS OF TELEMEDICINE, AND BY ACCEPTING THESE TERMS OF USE I
          HEREBY GIVE MY INFORMED CONSENT TO PARTICIPATE IN A TELEMEDICINE VISIT
          UNDER THE TERMS DESCRIBED HEREIN. BY CLICKING THE "AGREE" BUTTON YOU
          ARE CONSENTING TO RECEIVING CARE VIA THE SERVICE. THE SCOPE OF CARE
          WILL BE AT THE SOLE DISCRETION OF THE HEALTHCARE PHYSICIAN WHO IS
          TREATING YOU, WITH NO GUARANTEE OF DIAGNOSIS, TREATMENT, OR
          PRESCRIPTION. THE HEALTHCARE PHYSICIAN WILL DETERMINE WHETHER OR NOT
          THE CONDITION BEING DIAGNOSED AND/OR TREATED IS APPROPRIATE FOR A
          TELEHEALTH ENCOUNTER VIA THE SERVICE.
          <br />
          <br />
          {renderHeaderAndContent(
            "Medical Disclaimer ",
            `Any information or guidance we provide is not a substitute for the consultation, diagnosis, and/or medical treatment of your doctor or healthcare provider.
You must not rely on any information or guidance we provide you with as an alternative to medical advice from your doctor or healthcare provide and we expressly disclaim all responsibility, and shall have no liability, for any damages, loss, injury, or liability whatsoever suffered by you or any third party as a result of your reliance on any information or guidance we provide you with.
If you have any specific questions or concerns about any medical matter, you should consult your doctor or healthcare provider as soon as possible.
If you think you may be suffering from any medical condition, you should seek immediate medical attention from your healthcare provider. Do not delay seeking medical advice, disregard medical advice or discontinue medical treatment because of information or guidance we provide you with.
Nothing in this disclaimer will limit or exclude any liability that may not be limited or excluded by applicable law.
`
          )}
          {renderHeaderAndContent(
            "No Warranty ",
            `Please note that this is TelMDCare disclaimer of legal liability for the quality or reliability of our services. 

TELMDCARE AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY ABOUT THE SERVICES, INCLUDING ANY REPRESENTATION THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE AND PROVIDE THE SERVICES (INCLUDING CONTENT AND INFORMATION) ON “AS IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, TELMDCARE AND ITS AFFILIATES DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA, NON-INFRNGEMENT, MERCHANTIBILITY OR FITNESS FOR A PARTICULAR PURPOSE. 
`
          )}
          {renderHeaderAndContent(
            "Exclusion of Liability",
            `Please note that these are the limits of legal liability TelMDCare may have to you. 

TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS TELMDCARE HAS ENTERED INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS AGREEMENT), TELMDCARE INCLUDING ITS AFFILIATES, WILL NOT BE LIABLE IN CONNECTION WITH THIS AGREEMENT FOR LOST PROFITS OR LOST BUSINESS OPPORTUNITIES, REPUTATION (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA (E.G., DOWN TIME OR LOSS OF YOUR INFORMATION OR CONTENT) OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES. `
          )}
          {renderHeaderAndContent(
            "Basis of the Bargain Exclusions ",
            `The Limitations of Liability contained herein are part of the basis of the bargain between you and TelMDCare and shall apply to all claims of liability (e.g., tort, contract, negligence and warranty) even if Inner Happening or its affiliates has been told of the possibility of any such damage and even if these remedies fail their essential purpose. 

`
          )}
          {renderHeaderAndContent(
            "Consent to Automated Text Messages",
            `By accepting these Terms and Conditions and by providing your phone number you agree to receive automated text messages from TelMDCare including any persons or entities using that number. Message frequency will vary. Message and data rates may apply. `
          )}
        </CardBody>
      </BigCardFrame>
    </>
  );
};
