import * as React from 'react';
import { RouteComponentProps, Link, navigate } from '@reach/router';
import { Routes } from '../../../config/Routes';
import Footer from '../../../components/layout/Footer';
import TCNavBar from '../../../components/layout/TCNavBar';
import { IUserInfo, UserConfig } from '../../../config/userConfig';
import { Container, CardBody, CardTitle, Card, Col, Row } from 'reactstrap';
import {
  getMissingSectionsForBooking,
  IAppointmentUpdatedMessage,
  checkForAppointmentRightAge
} from '../../../core/service/services';
import { toastError, navItem } from '../../../App';
import { PatientMissingInfoCard } from './PatientMissingInfoCard';
import { PatientAppointments } from './PatientAppointments';
import { IMessage } from '../../chat-module/MessageModel';
import { ChatModule } from '../../chat-module/ChatModule';
import { initSocket } from '../../../core/socket/SocketInitializer';
import { appendPrefix } from '../../../core/service/PatientIdPrefixGenerator';

interface IProps extends RouteComponentProps {
  // children?: React.ReactNode
}

interface IState {
  userInfo?: IUserInfo | null;
  isChatOpened: boolean;
  appointmentChat?: { appointmentId: string, receiverName: string };
  addedMessage?: IMessage;
  appointmentToUpdate?: { id: string, count: number };
}

export const bookAppointment = async () => {
  let result: any = await checkForAppointmentRightAge();
  if (result.result === false) {
    toastError('You have to be at least 18 years old to book an appointment!')
  } else {
    navigate(Routes.bookAppointment);
  }
};

export class HomePatient extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      userInfo: UserConfig.getUserInfo(),
      isChatOpened: false
    };
  }

  componentWillMount() {
    initSocket(this.onMessageReceived, this.onUpdateAppointmentReceived)
    this.setTitle();
  }

  setTitle() {
    let title_el = document.querySelector("title");

    if (title_el)
      title_el.innerHTML = "TelMDCare";
  }

  onUpdateAppointmentReceived = (msg: IAppointmentUpdatedMessage) => {
    if (this.state.appointmentToUpdate && this.state.appointmentToUpdate.id === msg.appointmentId) {
      this.setState({
        appointmentToUpdate: { id: msg.appointmentId, count: this.state.appointmentToUpdate.count + 1 }
      })
    } else {
      this.setState({
        appointmentToUpdate: { id: msg.appointmentId, count: 0 }
      })
    }
  }

  renderHowItWorks() {
    return (
      <div className="col-md-12">
        <div className="card card-telecare mb-5">
          <div className="card-body">
            <section className="section-content-block">
              <div className="section-content-header">
                <h5 className="text-muted">
                  <i className="fa fa-info-circle"></i>{" "}
                  <span>How it Works</span>
                </h5>
              </div>
              <div className="section-content-body">
                <ol className="ml-0 pl-3 text-muted">
                  <li className="mb-3">
                    <strong className="d-block">
                      Schedule Virtual Appointment:
                    </strong>
                    Easily reserve an online consultation with one of
                    our many certified doctors and specialists for
                    only $43{' '}
                  </li>
                  <li className="mb-3">
                    <strong className="d-block">
                      Video Conferencing w/ Physican:
                    </strong>
                    Meet with one of our acute care physicians from
                    the comfort of your computer or mobile device.{' '}
                  </li>
                </ol>
              </div>
            </section>
          </div>
        </div>
      </div>
    )
  }

  onChatOpened = (appointementId: string, receiverName: string) => {
    // to do this should return the item of the appointement or the list of messages in the chat or the id of the appointement to get the messages from the chat
    this.setState({
      isChatOpened: true,
      appointmentChat: { appointmentId: appointementId, receiverName: receiverName },
      addedMessage:undefined
    })
  }

  toggleChat = () => {
    this.setState({ isChatOpened: !this.state.isChatOpened })
  }

  onMessageReceived = (message: IMessage) => {
    if (this.state.isChatOpened) {
      message.appointmentId = undefined
    }
    this.setState({ addedMessage: message })
  }

  render() {
    return (
      <>
        <TCNavBar bodyClassName="tc-bg">{navItem}</TCNavBar>
        {/*  Masthead  */}
        <Container>
          <ChatModule appointmentId={this.state.appointmentChat?.appointmentId} modalOpen={this.state.isChatOpened} addedMessage={this.state.addedMessage}
            onMessageReceived={this.onMessageReceived} toggleChatModal={this.toggleChat} receiverName={this.state.appointmentChat?.receiverName || ""} />
          <Row>
            <Col lg="8" xl="8">
              {UserConfig.getUserType() === "patient" &&
                <>
                  <h5 className="text-white mx-3">
                    <i className="fa fa-clinic-medical"></i> Welcome,{' '}
                    <span className="currentUserName">
                      {this.state.userInfo?.firstname || ' '}
                      <br />
                      {this.state.userInfo?.id && <small style={{ padding: '28px' }}>{`ID: ${appendPrefix(this.state.userInfo?.id)}`}</small>}
                    </span>
                  </h5>{' '}
                  <Card className="card-telecare my-5">
                    <CardBody>
                      <CardTitle className="mb-0">
                        Hey,{' '}
                        {this.state.userInfo?.firstname || ' '}
                        ! Below are your upcoming appointments and current
                        prescriptions. Feel free to update your medication history
                        or patient settings as needed.
                      </CardTitle>
                    </CardBody>
                  </Card>
                </>
              }
              <PatientMissingInfoCard></PatientMissingInfoCard>
              <PatientAppointments onChatOpened={this.onChatOpened} onMessageReceived={this.onMessageReceived} addedMessage={this.state.addedMessage}
                appointmentToUpdate={this.state.appointmentToUpdate}></PatientAppointments>
              {/* <PatientPrescriptions></PatientPrescriptions> */}
            </Col>
            <Col lg="4" xl="4">
              <div className="row">
                <div className="col-md-12">
                  <Link
                    to=""
                    onClick={bookAppointment}
                    className="card card-telecare btn-responsive-1 mt-md-0 mb-md-4 mt-sm-0 mt-lg-5 mt-xs-0"
                  >
                    <div className="card-body btn btn-primary">
                      <span className="features-icons-icon d-inline-block">
                        <i className="far fa-calendar-check m-auto"></i>
                      </span>
                      <h5 className="card-title mb-0">Book Appointment</h5>
                    </div>
                  </Link>

                </div>
                <div className="col-sm-12 col-md-6">
                  <Link
                    to={Routes.medicalHistory}
                    className="card card-telecare btn-responsive-2 mt-4 mt-md-0 mb-md-4 mb-sm-4"
                  >
                    <div className="card-body btn btn-primary">
                      <span className="features-icons-icon d-inline-block ">
                        <i className="fa fa-file-medical-alt mb-3"></i>
                      </span>
                      <h5 className="card-title mb-0">Medical Profile</h5>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-12 col-md-6">
                  <Link
                    to={Routes.patientSettings}
                    className="card card-telecare btn-responsive-3 mt-4 mt-md-0 mb-md-4 mb-sm-4"
                  >
                    <div className="card-body btn btn-primary">
                      <span className="features-icons-icon d-inline-block">
                        <i className="far fa-calendar-check m-auto"></i>
                      </span>
                      <h5 className="card-title mb-0">Personal Settings</h5>
                    </div>
                  </Link>
                </div>
                {this.renderHowItWorks()}
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}
