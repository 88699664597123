import React, { useState, useEffect } from "react";
import { Link, navigate } from "@reach/router";
import { Badge, Row, Col, Label } from "reactstrap";
import { localizeTimezone } from "../../../core/service/TimeZone";
import { IPhysician } from "./PhysiciansOverview";
import { PhysicianItemActions } from "./PhysicianItemActions";
import { male_physician } from "../../settings/PhysicianSettings";
import { getPhysicianProfileImageForAdmin } from "../../../core/service/services";
import { Routes } from "../../../config/Routes";

interface IProps {
  data: IPhysician;
  updateItem: (item: IPhysician) => void
}

export const PhysicianItemCard: React.FC<IProps> = props => {

  const [physician, setPhysician] = useState<IPhysician>(props.data)
  const [profileImage, setProfileImage] = useState<string>();

  useEffect(() => {
    setPhysician(props.data)
  }, [props.data])

  useEffect(() => {
    getPhysicianProfileImageForAdmin(props.data.id).then(res => {
      setProfileImage(res.imageBase64);
    }).catch(err => console.log(err))
  }, [])


  const openPhysicianSettings = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(Routes.adminPhysicianSettings + '?phId=' + physician.id)
  }

  const generateImage = (): any => {
    if (profileImage) {
      const imageUrl = 'data:image/jpeg;base64, ' + profileImage;
      return imageUrl;
    }
    return male_physician;
  };

  const renderTitle = () => {
    return (
    <Link onClick={openPhysicianSettings} className="mr-auto" to="#">
        <img src={generateImage().default} className="avatar"/>{"  "}
        {physician.firstname + " " + physician.lastname + (physician.title? (", " + physician.title) : "")}
    </Link>
    )
  }

  const renderInfo = () => {
    return (
      <Row>
        <Col xl="12" lg="12">
          <Label className="mr-1 mb-0 ph-info-label">Specialty:</Label>
          <text>{physician.specialty}</text>
        </Col>
        <Col xl="12" lg="12">
          <Row>
            <Col>
              <Label className="mr-1 mb-0 ph-info-label">DEA:</Label>
              <text>{physician.dea}</text>
            </Col>
            <Col>
              <Label className="mr-1 mb-0 ph-info-label">NPI:</Label>
              <text>{physician.npi}</text>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const statusBadge = () => {
    let color = ""
    const status = physician.status;
    switch (status) {
      case "Active":
        color = "primary"
        break;
      case "Disabled":
        color = "danger"
        break;
      case "New":
        color = "success"
        break;
    }
    return (
      <Badge color={color} className="p-1 mr-1 ">{status === "New" ? "Pending Approval" : status}</Badge>
    )
  }

  const renderStateAndDate = () => (
    <ul className="list-inline text-muted mb-1">
      <li className="list-inline-item mx-0">
        {statusBadge()}
      </li>
      <li className="list-inline-item mx-0">
        <small>
          <span className="day3">{" Joined On: " + localizeTimezone(physician.joinedAt.toString(),'ddd MMMM Do YYYY')}</span>
        </small>
      </li>
      
    </ul>
  )

  return (
    <div>
      <div style={{width: "80%"}}>
        <Row>
          <Col lg="5" xl="5">
            {renderTitle()}
            {renderStateAndDate()}
          </Col>
          {physician.specialty && physician.dea && 
            <Col lg="7" xl="7">
              {renderInfo()}
            </Col>
          }
        </Row>
        
      </div>
      <PhysicianItemActions data={physician} updateItem={props.updateItem}/>
        
    </div>
  );
};
