import * as React from "react";
import Footer from "./Footer";
import TCNavBar from "./TCNavBar";
import { Container, Row, Col, Card } from "reactstrap";

interface SmallCardFrameProps {
  children?: React.ReactNode;
  bodyClassName: null | "tc-bg"; // | "tc-bg modal-open"; // | "signup-bg";
  navItem: React.ReactNode;
  modal?: React.ReactNode;
  flowNav?: React.ReactNode;
}

interface SmallCardFrameState {}

export class SmallCardFrame extends React.Component<
  SmallCardFrameProps,
  SmallCardFrameState
> {
  constructor(props: SmallCardFrameProps) {
    super(props);
    
    this.state = {};
  }

  render = () => {
    const nav = (
      <TCNavBar bodyClassName={this.props.bodyClassName}>
        {this.props.navItem}
      </TCNavBar>
    );
    return (
      <div>
        {/* <!-- Navigation --> */}
        {nav}

        <Container>
          {this.props.modal}
          <Row>
            <Col lg="10" xl="9" className="mx-auto">
              <Card className="card-telecare flex-row signin my-5">
                {this.props.children}
              </Card>
              {this.props.flowNav}
            </Col>
          </Row>
        </Container>

        <Footer />
      </div>
    );
  }
}
