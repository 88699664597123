import React, { useEffect, useState } from 'react'
import { IAppointmentInfo } from '../../book-appointment/AppointmentModels'
import { getPhysicianPastAppointments, getPatientPastAppointments, getUSStatesLookup, ISearch } from '../../../core/service/services'
import { ListGroup, ListGroupItem, CardTitle, Label, Input, FormGroup, Row, Col, Container } from 'reactstrap'
import { AppointmentListingItemCard } from './AppointmentListingItemCard'
import { UserConfig } from '../../../config/userConfig'
import { IMessage } from '../../chat-module/MessageModel'
import ReactPaginate from 'react-paginate';
import { IUSState } from '../../settings/PhysicianSettings'
import _ from 'lodash' 
import { IPatientForAgents } from '../agent/PatientsOverview'


interface IProps {
  onChatOpened:  (appointmentId: string, receiverName: string)=> void;
  addedMessage?: IMessage,
  onMessageReceived?: (msg: IMessage) => void
}

export const PastAppointments: React.FC<IProps> = (props) => {

  const agentAsPatient:IPatientForAgents = UserConfig.getPatientLoggedByAgent();
  const [pastAppointments, setPastAppointments] = useState<IAppointmentInfo[]>([])
  const [searchPastAppointments, setSearchPastAppointments] = useState<IAppointmentInfo[]>([])
  const [uSStates, setUSStates] = useState<IUSState[]>([])
  const [search, setSearch] = useState<ISearch>();
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageCount, setPageCount] = useState<number>(1);

  const userType = UserConfig.getUserType()

  const getData = (index: number) => {
    const pastAppointmentsRequest = userType === 'physician' ? getPhysicianPastAppointments : getPatientPastAppointments 

    pastAppointmentsRequest({}, pageSize, index ).then(res => {
      setPastAppointments(res.items)
      setSearchPastAppointments(res.items)
      setPageIndex(res.pageIndex)
      setPageSize(res.pageSize)
      setPageCount(Math.ceil(res.length/pageSize))

    }).catch(err => console.log(err))
  }

  const fetchData_States = () => {
    getUSStatesLookup().then(states => {
      setUSStates(states);
    })
  }

  const getSearchData = (index:number) => {
    if (userType === 'physician' && search && _.valuesIn(search).toString().replace(',','')!='') {
      getPhysicianPastAppointments(search, pageSize, index).then((res) => {
        if(res.items.length>0)
          setPastAppointments(res.items);
        setSearchPastAppointments(res.items)
        setPageSize(res.pageSize)
        setPageCount(Math.ceil(res.length/pageSize))
    })
    } else {
      getData(index)
    }
  }

  const renderHeader = () => {
    return (
      <div id="pastApptSection" className="d-flex flex-nowrap section-content-header">
        <div className="order-1 mr-auto">
          <h5 className="text-muted"><span>Past Appointments</span></h5>
        </div>
        <div className="order-3 ml-auto">
          {/* {pastAppointments.length > 0 && <small className="pre-appt-scheduled">Viewing 1-10 of 23 Appointments</small>} */}
        </div>
      </div>
    )
  }

  const renderSearchBar = () => {
    if (userType === "physician" && pastAppointments.length > 0) {
      return (
        <FormGroup className="px-3 mb-0">
          <Row >
            <Col md="4" lg="4" >
              <Label>Patient Name</Label>
              <Input
                id="searchinput"
                placeholder="Search here..."
                type="text"
                className="form-control form-control-sm"
                onChange={(e) => { 
                  handleOnSearchInputChanged();
                  setSearch({ ...search,patientName: e.target.value });
                }}
                  />
            </Col>
            <Col md="8" lg="8">
              <Label>Date Range</Label>
              <Container className="d-flex pl-0 pr-0">
                <Row>
                  <Col md="6" lg="6">
                    <Label style={{fontSize: '.75rem', position: 'absolute', top: '-1rem'}}>from</Label>
                    <Input
                      type="date"
                      placeholder="From"
                      className="form-control form-control-sm"
                      onChange={(e) => { 
                        handleOnSearchInputChanged();
                        setSearch({...search, from: e.target.valueAsDate||undefined });
                      }}
                      style={{fontSize:'0.75rem'}}
                    />
                  </Col>
                  <Col md="6" lg="6">
                    <Label style={{fontSize: '.75rem', position: 'absolute', top: '-1rem'}}>to</Label>
                    <Input
                      type="date"
                      placeholder="To"
                      className="form-control form-control-sm "
                      onChange={(e) => { 
                        handleOnSearchInputChanged();
                        setSearch({ ...search,to: e.target.valueAsDate||undefined });
                      }}
                      style={{fontSize:'0.75rem'}}
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md="4" lg="4" >
              <Label className="pt-1">Patient ID</Label>
              <Input
                id="patientId"
                placeholder="Patient ID..."
                type="text"
                className="form-control form-control-sm"
                onChange={(e) => { 
                  handleOnSearchInputChanged();
                  setSearch({ ...search,patientCode: e.target.value });
                }}
                  />
            </Col>
            <Col md="4" lg="4">
              <Label className="pt-1">State</Label>
            <Input
              type="select"
              className="form-control form-control-sm"
              onChange={(e) => { 
                handleOnSearchInputChanged();
                setSearch({...search, state: e.target.value });
              }}
            >
                <option value={undefined}>All</option>
                {uSStates.map(state => (
                  <option value={state.id}>{state.name}</option>
                ))}
              </Input>
            </Col>
            <Col md="4" lg="4">
              <Label className="pt-1">Status</Label>
              <Input
                type="select"
                className="form-control form-control-sm"
                onChange={(e) => { 
                  handleOnSearchInputChanged();
                  if(e.target.value==='Completed')
                    setSearch({...search, status: 'Completed' });
                  else if (e.target.value === 'Archived')
                    setSearch({ ...search,status: 'Archived' });
                  else setSearch({status:undefined})
                  
                }}
              >
                <option>All</option>
                <option>Completed</option>
                <option>Archived</option>
              </Input>
            </Col>  
          </Row>
        </FormGroup>
      )
    }
  }
  const handleOnSearchInputChanged=()=>{
    setPageIndex(0);
  }
  const handlePageClick = (selectedItem: { selected: number }) => {
    let selected = selectedItem.selected;
    setPageIndex(selected);
    getSearchData(selected);
  }
  const renderPagination = () => {
    return (
      <FormGroup className="mb-0" style={{marginLeft:'20%',marginRight:'20%',display: 'flex', cursor: 'pointer'}}>
      <ReactPaginate 
        previousLabel={'« Prev'}
        nextLabel={'Next »'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
       // pageCount={2}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={(selectedItem) => handlePageClick(selectedItem)}
        containerClassName={'pagination'}
        pageClassName={'pagination'}
          activeClassName={'active'}
          disabledClassName={'disabled'}
        />
        </FormGroup>
    )
  }

  useEffect(() => getSearchData(pageIndex), [search])

  useEffect(() => fetchData_States(), [])
  

  return (
    <>
    {renderHeader()}
    {renderSearchBar()}
    {searchPastAppointments.length > 0 ?
    <ListGroup className="timeline pre-appt-scheduled">
      {searchPastAppointments.map((item) => (
        <ListGroupItem style={{borderTopWidth: "thin"}} key={item.appointmentId}>
          <AppointmentListingItemCard  appointment={item} onChatOpened={props.onChatOpened}  onMessageReceived={props.onMessageReceived} addedMessage={props.addedMessage}/>
        </ListGroupItem>
      ))}
    </ListGroup>
    :
    <CardTitle className="mb-3 pl-3">There are no recent/archived appointments.</CardTitle>
    }
    {pageCount > 0 && renderPagination()}
    </>
)
}
