import React from "react";
import { Link } from "@reach/router";

interface IProps {
  label: string;
  to: string;
  className: string;
  onClick?: any;
}

const PatientSettingsButton = (props: IProps) => {
  return (
    <Link to={props.to} className={props.className} onClick={props.onClick}>
      {props.label}
    </Link>
  );
};
export default PatientSettingsButton;
