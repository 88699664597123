import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody, Table } from "reactstrap";
import { getBillingLogs } from "../../../core/service/services";
import { CustomedPagination } from "../admin/Pagination";
import { IAppointmentBiller } from "./HomeBiller";

interface IBillingModal {
    appointement?: IAppointmentBiller;
    isOpen: boolean
    toggleModal: any
}

export interface IBillingLogs {

    action: string,
    timestamp: string,
    actor: {
        billerName: string
        billerId: number
    }

}

export const BillingLogsModal = (props: IBillingModal) => {
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(20);
    const [pageCount, setPageCount] = useState<number>(0);
    const [billingLogs, setBillingLogs] = useState<IBillingLogs[]>([]);


    useEffect(() => {
        if (props.isOpen) {
            if (props.appointement?.appointmentNumber) {
                fetchAllBillingLogs(0, 20)
            }
        }

    }, [props])

    const fetchAllBillingLogs = (pageIndex: number, pageSize: number) => {
        if (props.appointement?.appointmentNumber) {


            getBillingLogs(props.appointement?.appointmentNumber, pageIndex, pageSize).then(res => {
                console.log(res)
                setPageIndex(res.pageIndex)
                setPageSize(res.pageSize)

                res.pageSize != 0 && setPageCount(Math.ceil(res.count / res.pageSize))
                if (res.logs && res.logs.length !== 0) {
                    setBillingLogs(res.logs);

                } else {
                    setBillingLogs([]);
                }
            }).catch(err => console.log(err))
        }
    }
    const renderBillingLogs = () => {
        if (billingLogs.length > 0) {
            return (
                <Table responsive bordered size="sm" hover variant="dark"  >
                    <thead>
                        <th>Biller ID</th>
                        <th>Biller Name</th>
                        <th>Action </th>
                        <th>TimeStamp</th>


                    </thead>

                    <tbody>
                        {billingLogs.map(billingLog => (
                            <tr>
                                <td>{billingLog.actor.billerId}</td>

                                <td>{billingLog.actor.billerName}</td>
                                <td>{billingLog.action}</td>
                                <td>{moment(billingLog.timestamp).format("h:mm a  MM/DD/YYYY ")}</td>


                            </tr>
                        ))}
                    </tbody>
                </Table>
            )


        }
        else {
            return (
                <>
                    <h4>No Available Logs</h4>
                </>)
        }
    }

    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={props.toggleModal} >
                <ModalHeader toggle={props.toggleModal}>Billing Details</ModalHeader>
                <ModalBody >
                    {renderBillingLogs()}
                    {billingLogs.length > 0 && pageCount &&
                        <CustomedPagination
                            pageCount={pageCount}
                            pageNum={pageIndex}
                            onPaginate={(idx: any) => { setPageIndex(idx); fetchAllBillingLogs(idx, 20) }}
                        />}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {


                        props.toggleModal()
                        setBillingLogs([])
                    }
                    }>Okay</Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
    );
}