import React, { useState, useEffect } from "react";
import { MessagingList } from "./MessagingList";
import { IMessage } from "./MessageModel";
import { SendMessageModule } from "./SendMessageModule";
import { UserConfig } from "../../config/userConfig";
import { CardBody } from "reactstrap";

interface IProps {
  appointmentId?: string,
  addedMessage?: IMessage,
  onMessageReceived?: (msg: IMessage) => void,
  receiverName: string
}

export const ChatCard: React.FC<IProps> = props => {
  const [addedMessage, setNewMessage] = useState<IMessage>();
  const [appointmentId, setAppointmentId] = useState<string>();

  useEffect(
    () => {
      setNewMessage(props.addedMessage)
      setAppointmentId(props.appointmentId)
    }, [props.addedMessage, props.appointmentId]
  )

  return (
    <CardBody style={{ maxHeight:'calc(100% - 80px)'}}> 
      <h5 className="mb-0">{(UserConfig.getUserType() == "patient" ? "Doctor Chat: " : "Patient Chat: ") + props.receiverName}</h5>
      {UserConfig.getUserType() === "patient" && <p>If it is an emergency call 911 or go the nearest hospital. Provider will get back to you within 24 hrs.</p>}
      <hr className="mb-3"/>
      <div style={{ maxHeight:'calc(100vh - 125px)',  overflowY: "auto"}}>
        <MessagingList appointementId={appointmentId} addedMessage={addedMessage} ></MessagingList>
      </div>
      <SendMessageModule messageSent={props.onMessageReceived} appointementId={appointmentId} showClosed={false}></SendMessageModule>
    </CardBody>
  );
};
