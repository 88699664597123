import React, { useState } from 'react';
import {
  Row,
  Form,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { IPatientInfoSettings } from './PatientSettings';
import {
  ICreditCardListingItem,
  getPatientCreditCards,
  IPatientPaymentMethod,
  addNewPaymentMethod,
  deleteCreditCard,
} from '../../core/service/services';
import { TextField } from '../commons/TextField';
import { toastSuccess, toastError } from '../../App';
import Asterisk from '../commons/Asterisk';
import { IUSState } from '../../page/settings/PhysicianSettings';

interface IProps {
  info?: IPatientInfoSettings;
  usStates: IUSState[]
}
export interface IPatientInfoAddress {
  address?: {
    description?: string;
    city?: string;
    state?: string;
    zipCode?: string;
  };
}

const PaymentMethods = (props: IProps) => {
  const [cards, setCards] = useState<ICreditCardListingItem[]>();
  const [cardsModal, setCardsModal] = useState(false);

  const getData = () => {
    getPatientCreditCards()
      .then((res) => {
        setCards(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => getData(), []);

  const toggleCardsModal = () => {
    setCardsModal(!cardsModal);
  };

  const getValue = (
    arr: [string, FormDataEntryValue][],
    field: string,
    errMsgs?: string[],
    regex?: string | RegExp
  ): any => {
    let value = arr.filter(function (record) {
      return record[0] === field;
    });
    if (errMsgs && (value[0][1] === undefined || value[0][1] === '')) {
      errMsgs.push('Field ' + field + ' is mandatory');
    } else if (errMsgs && regex) {
      let re = new RegExp(regex);
      if (!re.test(value[0][1].toString())) {
        errMsgs.push('Field ' + field + ' is invalid');
      }
    }
    return value[0][1];
  };

  const removeCard = (cardId: string) => {
    deleteCreditCard(cardId).then((res) => {
      getData();
      toastSuccess('Card Deleted!');
    });
  };

  const getState = (id: number) => {
    for (let index = 0; index < props.usStates.length; index++) {
      const state = props.usStates[index];
      if (state.id === id) {
        return state.name
      }
    }
  }

  const renderTableRows = () =>
    cards?.map((card:any) => (
      <tr>
        <td>{card.cardNumber}</td>
        <td>{card.expiration}</td>
        <td>
          {card.address.description +
            ' - ' +
            getState(card.address.stateId) +
            ' - ' +
            card.address.country}
        </td>
        <td>
          <Button
            data-toggle="modal"
            onClick={() => removeCard(card.id)}
            size="sm"
            color="link"
          >
            <span className="sr-only">Remove</span>
            <i className="fa fa-trash"></i>
          </Button>
        </td>
      </tr>
    ));

  const createSelectItems = () => {
    return props.usStates.map((state, idx) => {
      return (
        <option key={idx} value={state.id} selected={state.id === props.info?.address?.stateId}>
          {state.name}
        </option>
      );
    });
  };

  const savePaymentInfo = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data: FormData = new FormData(e.currentTarget);
    const formData: [string, FormDataEntryValue][] = Array.from(data.entries());
    let paymentMethod: IPatientPaymentMethod = {};
    let errMsgs: string[] = [];
    paymentMethod.cardNumber = getValue(
      formData,
      'cardNumber',
      errMsgs,
      /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/
    );
    paymentMethod.expiration = getValue(
      formData,
      'expiration',
      errMsgs,
      '^[0-9]{2}/[0-9]{2}$'
    );
    paymentMethod.cvv = getValue(formData, 'cvv', errMsgs, '^[0-9]{3,4}$');
    paymentMethod.nameOnCard = getValue(formData, 'nameOnCard', errMsgs);
    let address:any = {};
    address.firstname = getValue(formData, 'firstname', errMsgs);
    address.lastname = getValue(formData, 'lastname', errMsgs);
    address.email = getValue(
      formData,
      'email',
      errMsgs,
      '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'
    );
    address.description = getValue(
      formData,
      'description',
      errMsgs
    );
    address.city = getValue(formData, 'city', errMsgs);
    address.stateId = getValue(formData, 'state', errMsgs);
    address.country = getValue(formData, 'country', errMsgs);
    address.zipCode = getValue(formData, 'zipCode', errMsgs);
    if (errMsgs.length > 0) {
      errMsgs.forEach((msg) => {
        toastError(msg);
      });
      return;
    }
    address = {...address, stateId: parseInt(address.stateId)};
    paymentMethod.address = {...address}
    addNewPaymentMethod(paymentMethod).then((res) => {
      getData();
      toggleCardsModal();
    });
  };

  const getModal = () => (
    <Modal isOpen={cardsModal} toggle={toggleCardsModal}>
      <ModalHeader toggle={toggleCardsModal}>
        <h5 id="templatesModal" className="modal-title">
          Add new payment data
        </h5>
      </ModalHeader>
      <ModalBody>
        <Form id="payment-method" onSubmit={(e) => savePaymentInfo(e)}>
          <h6>Card Info</h6>
          <Row form>
            <Col md="4" lg="12">
              <TextField
                type="text"
                name="nameOnCard"
                labelText="Name on Card"
                required
              ></TextField>
            </Col>
            <Col md="4" lg="12">
              <TextField
                type="text"
                name="cardNumber"
                labelText="Card Number"
                required
              ></TextField>
            </Col>
            <Col md="4" lg="4">
              <TextField
                type="text"
                name="expiration"
                labelText="Expiration"
                placeholder="MM/YY"
                required
              ></TextField>
            </Col>
            <Col md="4" lg="4">
              <TextField type="text" name="cvv" labelText="CVV" placeholder="123" required></TextField>
            </Col>
          </Row>
          <h6>Billing Info</h6>
          <Row>
            <Col md="4" lg="6">
              <TextField
                type="text"
                name="firstname"
                labelText="First Name"
                defaultValue={props.info?.contact?.firstname}
                required
              ></TextField>
            </Col>
            <Col md="4" lg="6">
              <TextField
                type="text"
                name="lastname"
                labelText="Last Name"
                defaultValue={props.info?.contact?.lastname}
                required
              ></TextField>
            </Col>
            <Col md="4" lg="12">
              <TextField
                type="text"
                name="email"
                labelText="Email"
                defaultValue={props.info?.contact?.email}
                required
              ></TextField>
            </Col>
            <Col md="6" lg="12">
              <TextField
                type="text"
                name="description"
                labelText="Address"
                defaultValue={props.info?.address?.description}
                required
              ></TextField>
            </Col>

            <Col md="4" lg="6">
              <TextField
                type="text"
                name="city"
                labelText="City"
                defaultValue={props.info?.address?.city}
                required
              ></TextField>
            </Col>

            <Col md="4" lg="6">
              <FormGroup>
                <Label for="state">State</Label>
                <Input
                  type="select"
                  id="state"
                  name="state"
                  defaultValue={props.info?.address?.stateId}
                  required
                >
                  <option value="">Select state</option>
                  {createSelectItems()}
                </Input>
                <FormFeedback>Please choose a state.</FormFeedback>
              </FormGroup>
            </Col>

            <Col md="4" lg="6">
              <TextField
                type="text"
                name="country"
                labelText="Country"
                defaultValue={props.info?.address?.country || 'US'}
                required
              ></TextField>
            </Col>

            <Col md="4" lg="4">
              <TextField
                type="text"
                name="zipCode"
                labelText="Zip Code"
                defaultValue={props.info?.address?.zipCode}
                required
              ></TextField>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button size="lg" color="link" onClick={toggleCardsModal}>
          Cancel
        </Button>
        <Button
          size="lg"
          color="success"
          form="payment-method"
          type="submit"
          className="ml-auto"
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );

  return (
    <div>
      {getModal()}
      <h6 className="mb-3">
        <Label>Payment Methods</Label>
        <Asterisk />
      </h6>
      {cards?.length === 0 ? (
        <span>None</span>
      ) : (
        <Table responsive className="table-sm">
          <thead>
            <tr>
              <th>Card Number</th>
              <th>Expiration</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </Table>
      )}
      <Button
        data-toggle="modal"
        onClick={toggleCardsModal}
        size="sm"
        outline
        color="primary"
        className="btn-block w-50 m-auto"
      >
        Add New Payment Method
      </Button>
    </div>
  );
};
export default PaymentMethods;
