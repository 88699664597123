import React from 'react';

import {
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import { localizeTimezone } from '../../../../core/service/TimeZone';
import Asterisk from '../../../commons/Asterisk';
import { IConfElement } from './ConfigurationsInsuranceModal';
interface IProps {
    confElement: IConfElement;
    errorMessage: string | undefined

}


const DateConfType = (props: IProps) => {


    const getDefaultValue = (): string => {
        if (props.confElement.defaultValue) {
            const date = localizeTimezone(props.confElement.defaultValue, 'YYYY-MM-DD')
            return date
        } else if (props.confElement.defaultSystemValue) {
            const date = localizeTimezone(props.confElement.defaultSystemValue, 'YYYY-MM-DD')

            return date
        } else {
            return "not right"
        }

    }
    const getMinDate = (): string => {
        if (props.confElement.min) {
            const date = localizeTimezone(props.confElement.min, 'YYYY-MM-DD')
            return date
        } else {
            return ""
        }

    }
    const getMaxDate = (): string => {
        if (props.confElement.max) {
            const date = localizeTimezone(props.confElement.max, 'YYYY-MM-DD')
            return date
        } else {
            return ""
        }

    }
    const renderError = () => {
        if (props.errorMessage) {
            return (
                <>
                    <div className="text-danger">
                        {props.errorMessage}
                    </div>
                </>

            )
        }
    }
    return (
        <>

            <FormGroup>
                <Label>{props.confElement.label}</Label>
                {props.confElement.required && <Asterisk />}
                <Input
                    type="date"
                    defaultValue={getDefaultValue()}
                    required={props.confElement.required}
                    id={props.confElement.keyToBeSent}
                    name={props.confElement.keyToBeSent}
                    min={getMinDate()}
                    max={getMaxDate()}

                ></Input>
                {
                    renderError()
                }
            </FormGroup>
        </>
    );
};
export default DateConfType;
